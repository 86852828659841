import Permissions, { usePermissions } from '@app/src/auth/permissions'
import CompanyCell from '@app/src/components/Table/Cells/CompanyCell'
import DateCell from '@app/src/components/Table/Cells/DateCell'
import ExternalVerificationStatusCell from '@app/src/components/Table/Cells/ExternalVerificationStatusCell'
import PeriodNameCell from '@app/src/components/Table/Cells/PeriodNameCell'
import RequestCell from '@app/src/components/Table/Cells/RequestCell'
import StatusCell from '@app/src/components/Table/Cells/StatusCell'
import ValueCell from '@app/src/components/Table/Cells/ValueCell'
import { useAccount } from '@app/src/context/AccountContext'
import { ExternalVerificationStatus, Response } from '@app/src/types/resourceExplorer'
import { reporting } from '@app/src/wf-constants/paths'
import { TableRow } from '@mui/material'
import markdownToTxt from 'markdown-to-txt'
import React from 'react'
import { useHistory } from 'react-router-dom'

interface AccessorResponseRowProps {
  row: Response
}

export const AccessorResponseRow = ({ row: response }: AccessorResponseRowProps): JSX.Element => {
  const { hasPermission } = usePermissions()
  const history = useHistory()
  const verificationPermitted = hasPermission(Permissions.WRITE_VERIFICATION)
  const { account } = useAccount()

  const inquiry = response?.request?.inquiries?.find(
    inquiry => inquiry.creatorOrganizationId === account?.organization?.id,
  )

  return (
    <TableRow>
      <ExternalVerificationStatusCell
        responseStatus={response?.verifications?.[0]?.externalVerificationStatus ?? ExternalVerificationStatus.NotSet}
        draftStatus={response?.draftStatus}
        inquiryStatus={inquiry?.status}
      />
      <RequestCell
        title={response.request.title}
        imageUrl={response.request?.template?.image?.url}
        onClick={() =>
          history.push({ pathname: reporting, search: `requestId=${response.request.id}&responseId=${response.id}` })
        }
      />
      <PeriodNameCell periodName={response.request?.periodName} />
      <CompanyCell
        company={{ ...response?.request?.targetAliasObject, organization: response?.request?.targetObject }}
        drawer
      />
      <DateCell value={response.createdAt} />
      <DateCell value={inquiry?.deadline} />
      <DateCell value={response?.submittedAt} />

      {verificationPermitted && (
        <>
          <ValueCell
            value={
              response?.verifications?.[0]?.externalVerificationComment &&
              markdownToTxt(response?.verifications?.[0]?.externalVerificationComment)
            }
          />
          <StatusCell verification value={response?.verifications?.[0]?.internalVerificationStatus} />
          <ValueCell
            value={
              response?.verifications?.[0]?.internalVerificationComment &&
              markdownToTxt(response?.verifications?.[0]?.internalVerificationComment)
            }
          />
        </>
      )}
    </TableRow>
  )
}

export default AccessorResponseRow
