import Tabs from '@app/src/components/Tabs'
import ReviewAllButtons from '@app/src/pages/Questionnaire/Accessor/Actions/ReviewAllButtons'
import HistoryLog from '@app/src/pages/Questionnaire/HistoryLog'
import { useReport } from '@app/src/pages/Questionnaire/ReportContext'
import { Box, Skeleton, Stack } from '@mui/material'
import React from 'react'
import { useIntl } from 'react-intl'
import Reporter from './Reporter'
import VerificationMessages from './VerificationMessages'

enum RightPanelTab {
  REPORTER = 'reporter',
  HISTORY_LOG = 'history-log',
  MESSAGES = 'messages',
}

const ReportActions: React.FC = () => {
  const { response, isResponseLoading } = useReport()
  const { formatMessage } = useIntl()

  const [activeTab, setActiveTab] = React.useState<RightPanelTab>(RightPanelTab.REPORTER)

  const switchToMessagesTab = () => {
    setActiveTab(RightPanelTab.MESSAGES)
  }

  const tabs = [
    {
      type: RightPanelTab.REPORTER,
      label: formatMessage({ id: 'reporting.reporter' }),
      url: '',
    },
    {
      type: RightPanelTab.HISTORY_LOG,
      label: formatMessage({ id: 'reporting.historyLog' }),
      url: '',
    },
    {
      type: RightPanelTab.MESSAGES,
      label: formatMessage({ id: 'reporting.messages' }),
      url: '',
      badgeText: response?.verifications?.length ?? 0,
      badgeSx: { '& .MuiBadge-badge': { right: -10 } },
    },
  ]

  if (isResponseLoading) {
    return (
      <Box position="sticky" top={80} display="flex">
        <Skeleton width="33%" height={48} sx={{ mr: 1 }} />
        <Skeleton width="33%" height={48} sx={{ mr: 1 }} />
        <Skeleton width="33%" height={48} />
      </Box>
    )
  }

  return (
    <Stack position="sticky" top={80} spacing={2}>
      <ReviewAllButtons onVerificationSubmitted={switchToMessagesTab} />
      <Box>
        <Tabs
          variant="fullWidth"
          tabs={tabs}
          activeTab={activeTab}
          handleChange={(_, value) => setActiveTab(value as RightPanelTab)}
        />
        {activeTab === RightPanelTab.REPORTER && <Reporter />}
        {activeTab === RightPanelTab.HISTORY_LOG && <HistoryLog />}
        {activeTab === RightPanelTab.MESSAGES && <VerificationMessages />}
      </Box>
    </Stack>
  )
}

export default ReportActions
