import endpoints from '@app/src/api/endpoints'
import { FetchKey, useFetchResource } from '@app/src/api/fetchHooks'
import { useUpdateResource } from '@app/src/api/updateHooks'
import { ActionButtonsProps } from '@app/src/components/ActionButtons'
import Table from '@app/src/components/Table'
import { useSnackbar } from '@app/src/context/SnackbarContext'
import useErrorNotification from '@app/src/hooks/errorNotification'
import usePagination from '@app/src/hooks/pagination'
import { useSelectableRows } from '@app/src/hooks/useSelectableRows/useSelectableRows'
import ConfigurationCollection from '@app/src/pages/Configurations/ConfigurationCollection'
import { Claim, ClaimUpdate } from '@app/src/types/organizations'
import { NotificationSeverity } from '@app/src/wf-constants'
import { useTheme } from '@mui/styles'
import React from 'react'
import { useIntl } from 'react-intl'
import { useQueryClient } from 'react-query'
import ClaimsReviewHeader from './ClaimsReviewHeader'
import ClaimsReviewRow from './ClaimsReviewRow'

export enum ReviewClaimAction {
  approved = 'Approved',
  denied = 'Denied',
}

const ClaimsReviewConfig: React.FC = () => {
  const { formatMessage } = useIntl()
  const { palette } = useTheme()
  const { showSnackbar } = useSnackbar()
  const queryClient = useQueryClient()
  const { showErrorNotification } = useErrorNotification()
  const [page, pageSize, setPage, setPageSize] = usePagination()

  const {
    data: response,
    isLoading,
    isFetching,
    isError,
  } = useFetchResource<{ claims: Claim[]; totalCount: number }>({
    key: [FetchKey.PendingClaimsCollection, page, pageSize],
    endpoint: `${endpoints.pendingClaimsCollection}?page=${page}&pagesize=${pageSize}`,
  })

  const items = response?.claims
  const totalClaimsCount = response?.totalCount

  const { selectedRowsIds, setSelectedRowsIds, handleCheckboxChange, isHeaderChecked, handleHeaderCheckboxChange } =
    useSelectableRows<Claim>({
      rowsInPage: items ?? [],
    })

  const { mutate: reviewClaim, isLoading: isReviewingClaim } = useUpdateResource<Partial<ClaimUpdate>, ClaimUpdate>({
    options: {
      onSuccess: () => {
        showSnackbar({
          message: formatMessage({ id: 'claim.update' }),
          severity: NotificationSeverity.success,
        })
        setSelectedRowsIds([])
        queryClient.invalidateQueries(FetchKey.PendingClaimsCollection)
      },

      onError: error => {
        showErrorNotification({ requestError: error, disableAutoClose: true })
      },
    },
  })

  const reviewClaims = (action: ReviewClaimAction) => {
    reviewClaim({
      url: endpoints.reviewClaims,
      body: { claimIds: selectedRowsIds, claimStatus: action },
    })
  }

  const isUpdating = isReviewingClaim || isLoading || isFetching

  const actionButtons: ActionButtonsProps['buttons'] | undefined = selectedRowsIds.length
    ? [
        {
          label: formatMessage(
            { id: 'resourceCollections.update.reviewClaim' },
            {
              claim: formatMessage({ id: `general.Claim` }, { count: selectedRowsIds.length }).toLowerCase(),
              action: formatMessage({ id: `claim.action.approve` }),
              count: selectedRowsIds.length,
            },
          ),
          variant: 'contained',
          onClick: () => reviewClaims(ReviewClaimAction.approved),
          disabled: isUpdating,
          sx: {
            backgroundColor: palette.primary.main,
            '&:hover': { backgroundColor: palette.success.main },
          },
        },
        {
          label: formatMessage(
            { id: 'resourceCollections.update.reviewClaim' },
            {
              claim: formatMessage({ id: `general.Claim` }, { count: selectedRowsIds.length }).toLowerCase(),
              action: formatMessage({ id: `claim.action.deny` }),
              count: selectedRowsIds.length,
            },
          ),
          variant: 'contained',
          onClick: () => reviewClaims(ReviewClaimAction.denied),
          disabled: isUpdating,
          sx: { backgroundColor: palette.error.main, '&:hover': { backgroundColor: palette.warning.main } },
        },
      ]
    : undefined

  return (
    <ConfigurationCollection actionButtons={actionButtons} enablePadding={{ top: false, bottom: false }}>
      <Table<Claim>
        RowComponent={({ row }): JSX.Element => (
          <ClaimsReviewRow row={row} onCheckboxChange={handleCheckboxChange} selectedClaimIds={selectedRowsIds} />
        )}
        HeaderComponent={(): JSX.Element => (
          <ClaimsReviewHeader
            isHeaderChecked={isHeaderChecked}
            handleHeaderCheckboxChange={handleHeaderCheckboxChange}
          />
        )}
        data={items || []}
        isLoading={isUpdating}
        count={totalClaimsCount ?? 0}
        isError={isError}
        page={page}
        pageSize={pageSize}
        setPage={setPage}
        setPageSize={setPageSize}
      />
    </ConfigurationCollection>
  )
}
export default ClaimsReviewConfig
