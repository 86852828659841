import Permissions, { usePermissions } from '@app/src/auth/permissions'
import { useDrawer } from '@app/src/components/Drawer/DrawerContext'
import DrawerViewResponseInternalVerification from '@app/src/components/Drawer/Views/Verification/DrawerViewResponseInternalVerification'
import DrawerViewVerifyAllQuestionsInResponse from '@app/src/components/Drawer/Views/Verification/DrawerViewVerifyAllQuestionsInResponse'
import LoadingButton from '@app/src/components/LoadingButton'
import { useReport } from '@app/src/pages/Questionnaire/ReportContext'
import { ResponseDraftStatus } from '@app/src/types/resourceExplorer'
import { Stack } from '@mui/material'
import { useFlags } from 'launchdarkly-react-client-sdk'
import React from 'react'
import { useIntl } from 'react-intl'

interface ReviewAllButtonsProps {
  onVerificationSubmitted: () => void
}

const ReviewAllButtons: React.FC<ReviewAllButtonsProps> = ({ onVerificationSubmitted }) => {
  const { response, request, latestHistory } = useReport()
  const { hasPermission } = usePermissions()
  const { formatMessage } = useIntl()
  const { openDrawer } = useDrawer()
  const { stpResponseResponseItemVerificationFilterLogic } = useFlags()

  const isPrimarySubscriber = request?.isPrimarySubscriber
  const isLatestResponse = latestHistory?.responseId === response?.id

  const isReviewEnabled =
    (!stpResponseResponseItemVerificationFilterLogic || isPrimarySubscriber) &&
    hasPermission(Permissions.WRITE_VERIFICATION) &&
    isLatestResponse &&
    response?.draftStatus === ResponseDraftStatus.Submitted

  if (!isReviewEnabled) {
    return null
  }

  return (
    <Stack spacing={2}>
      <LoadingButton
        fullWidth
        variant="outlined"
        onClick={() =>
          openDrawer(
            <DrawerViewVerifyAllQuestionsInResponse
              response={response}
              onVerificationSubmitted={onVerificationSubmitted}
            />,
          )
        }
      >
        {formatMessage({ id: 'questionnaire.actions.review' })}
      </LoadingButton>
      <LoadingButton
        fullWidth
        variant="outlined"
        onClick={() =>
          openDrawer(
            <DrawerViewResponseInternalVerification
              response={response}
              onVerificationSubmitted={onVerificationSubmitted}
            />,
          )
        }
      >
        {formatMessage({ id: 'questionnaire.actions.internalReview' })}
      </LoadingButton>
    </Stack>
  )
}

export default ReviewAllButtons
