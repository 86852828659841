import { useDrawer } from '@app/src/components/Drawer/DrawerContext'
import DrawerView, { DrawerViewProps } from '@app/src/components/Drawer/DrawerView'
import { useAmplitude } from '@app/src/context/AmplitudeContext'
import useAccessorQuestionnaire from '@app/src/pages/Questionnaire/hooks/useAccessorQuestionnaire'
import { ExternalVerificationStatus, Response, ResponseItem } from '@app/src/types/resourceExplorer'
import { AmplitudeTrackingEvents } from '@app/src/wf-constants'
import { Alert, List, ListItem, ListItemIcon, ListItemText, Radio, RadioGroup, Stack } from '@mui/material'
import React, { useState } from 'react'
import { useIntl } from 'react-intl'

type DrawerViewApproveAllWhenFlagsProps = {
  response: Response
  responseComment?: string
  itemsThatDontRequireAction: ResponseItem[]
  onVerificationSubmitted: () => void
} & Omit<DrawerViewProps, 'title'>

export enum FlagHandling {
  ApproveWithoutFlags = 'ApproveWithoutFlags',
  ApproveAll = 'ApproveAll',
}
const DrawerViewApproveAllWhenFlags: React.FC<DrawerViewApproveAllWhenFlagsProps> = ({
  response,
  responseComment,
  itemsThatDontRequireAction,
  onVerificationSubmitted,
  ...props
}) => {
  const { formatMessage } = useIntl()
  const { closeDrawer } = useDrawer()
  const [flagHandling, setFlagHandling] = useState<FlagHandling>(FlagHandling.ApproveWithoutFlags)
  const { externalReviewAllItems, isLoadingCreateVerification } = useAccessorQuestionnaire()
  const { trackEvent } = useAmplitude()

  const onClickSubmitInApproveAllWhenFlags = async () => {
    const itemsToPost = flagHandling === FlagHandling.ApproveWithoutFlags ? itemsThatDontRequireAction : response.items
    await externalReviewAllItems({
      responseId: response.id,
      responseItemIds: itemsToPost.map(item => item.id),
      externalVerificationStatus: ExternalVerificationStatus.Approved,
      responseExternalVerificationComment: responseComment,
    })
    trackEvent({
      name: AmplitudeTrackingEvents.Accessor.ResponseVerification.ExternalVerification.Submitted,
      eventProps: {
        verificationStatus: ExternalVerificationStatus.Approved,
        hasComment: Boolean(responseComment),
      },
    })
    onVerificationSubmitted()
    closeDrawer()
  }

  return (
    <DrawerView
      title={formatMessage({ id: 'reporting.verification.review' })}
      stackButtons
      buttons={[
        {
          label: formatMessage({
            id: 'general.cancel',
          }),
          variant: 'text',
          color: 'primary',
          disabled: isLoadingCreateVerification,
          onClick: closeDrawer,
        },
        {
          label: formatMessage({ id: 'general.submit' }),
          variant: 'contained',
          loading: isLoadingCreateVerification,
          onClick: onClickSubmitInApproveAllWhenFlags,
        },
      ]}
      {...props}
    >
      <Stack px={2}>
        <Alert severity="warning">{formatMessage({ id: 'reporting.verification.answersWithFlagWarning.alert' })}</Alert>
        <List>
          <RadioGroup value={flagHandling} onChange={(_, v) => setFlagHandling(v as FlagHandling)}>
            {itemsThatDontRequireAction.length ? (
              <ListItem>
                <ListItemIcon>
                  <Radio value={FlagHandling.ApproveWithoutFlags} />
                </ListItemIcon>
                <ListItemText
                  primary={formatMessage(
                    {
                      id: 'reporting.verification.answersWithFlagWarning.approveWithoutFlagsTitle',
                    },
                    { answerCount: itemsThatDontRequireAction.length },
                  )}
                  secondary={formatMessage({
                    id: 'reporting.verification.answersWithFlagWarning.approveWithoutFlagsDescription',
                  })}
                />
              </ListItem>
            ) : (
              <></>
            )}
            <ListItem>
              <ListItemIcon>
                <Radio value={FlagHandling.ApproveAll} />
              </ListItemIcon>
              <ListItemText
                primary={formatMessage(
                  {
                    id: 'reporting.verification.answersWithFlagWarning.approveAllTitle',
                  },
                  { answerCount: response.items.length },
                )}
                secondary={formatMessage({
                  id: 'reporting.verification.answersWithFlagWarning.approveAllDescription',
                })}
              />
            </ListItem>
          </RadioGroup>
        </List>
      </Stack>
    </DrawerView>
  )
}

export default DrawerViewApproveAllWhenFlags
