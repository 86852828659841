import { toSnakeCase } from '@app/src/utils/helpers'
import ClearIcon from '@mui/icons-material/Clear'
import { InputLabel, TextField as MuiTextField, TextFieldProps as MuiTextFieldProps, Stack } from '@mui/material'
import IconButton from '@mui/material/IconButton'
import React, { useState } from 'react'

export type TextFieldProps = {
  onClear?: () => void
  hoveringLabel?: boolean
} & MuiTextFieldProps

const TextField = ({ variant = 'filled', onClear, hoveringLabel, required, ...rest }: TextFieldProps): JSX.Element => {
  const { type, InputProps, label, name, error, disabled } = rest
  const [hasValue, setHasValue] = useState(false) //known limitation : we don't show the clear button on first render with a default value

  const inputFieldId = `${name}-input-field`
  const inputLabelId = `${name}-input-label`

  const shouldShowHovering = variant === 'filled' || hoveringLabel

  return (
    <Stack>
      {shouldShowHovering && (
        <InputLabel variant={variant} htmlFor={inputFieldId} id={inputLabelId} error={error} disabled={disabled}>
          {label} {required && '*'}
        </InputLabel>
      )}
      <MuiTextField
        id={inputFieldId}
        aria-labelledby={inputLabelId}
        InputProps={{
          ...InputProps,
          onKeyDown: (e): void =>
            type === 'number' && (e.key === 'e' || e.key === 'E') ? e.preventDefault() : undefined,
          onChange: (e): void => {
            setHasValue(e.target.value !== '')
          },
          endAdornment: (
            <>
              {hasValue && onClear && (
                <IconButton size="small" onClick={(): void => onClear()}>
                  <ClearIcon />
                </IconButton>
              )}
              {InputProps?.endAdornment}
            </>
          ),
        }}
        variant={hoveringLabel ? 'filled' : variant}
        required={required}
        hiddenLabel={shouldShowHovering}
        name={name}
        error={error}
        disabled={disabled}
        {...rest}
        label={!shouldShowHovering ? label : undefined}
        data-testid={toSnakeCase(label?.toString()) + '-textfield'}
      />
    </Stack>
  )
}

export default TextField
