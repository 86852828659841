import endpoints from '@app/src/api/endpoints'
import { FetchKey, useFetchCollectionWithPost, useFetchFacets } from '@app/src/api/fetchHooks'
import { ActionButton } from '@app/src/components/ActionButtons'
import { useDrawer } from '@app/src/components/Drawer/DrawerContext'
import DrawerViewImportSpend from '@app/src/components/Drawer/Views/DrawerViewImportSpend'
import EmptyState from '@app/src/components/EmptyState'
import { EmptyStateVariant } from '@app/src/components/EmptyState/EmptyState'
import Select from '@app/src/components/Form/Select/ControlledSelect'
import LoadingButton from '@app/src/components/LoadingButton'
import TextField from '@app/src/components/Ui/TextField'
import useCurrentProviderType from '@app/src/hooks/currentProviderType'
import useCurrentProviderTypeName from '@app/src/hooks/currentProviderTypeName'
import useOrganizationSettings from '@app/src/hooks/useOrganizationSettings'
import ConfigurationCollection from '@app/src/pages/Configurations/ConfigurationCollection'
import ConfigurationSkeleton from '@app/src/pages/Configurations/ConfigurationsSkeleton'
import { Operators } from '@app/src/pages/ResourceCollection/Filters/useFilters'
import { Unit } from '@app/src/types/resourceExplorer'
import { OrganizationSettings, SpendIntervalType } from '@app/src/wf-constants'
import { Add, AssistantSharp, EuroOutlined } from '@mui/icons-material'
import { Box, Button, Grid, IconButton, InputAdornment, Stack, Tooltip, Typography } from '@mui/material'
import { useConfirm } from 'material-ui-confirm'
import React, { useEffect, useMemo, useState } from 'react'
import { Controller, FormProvider, SubmitHandler, useForm, useWatch } from 'react-hook-form'
import { useIntl } from 'react-intl'
import { useQueryClient } from 'react-query'

type SettingsChanged = Pick<
  OrganizationSettings,
  'currencyUnitId' | 'mediumSpendMinValue' | 'mediumSpendMaxValue' | 'spendIntervalType'
>

const UNITS_TO_SHOW = ['USD', 'GBP', 'SEK', 'EUR']
const UNIT_CACHE_TIME = 12 * 60 * 60 * 1000
const staleTime = 60000

const isNotPositiveWholeNumber = (value: number) => !Number.isNaN(value) && (!Number.isInteger(value) || value < 1)

const OrganizationPreferencesConfig: React.FC = () => {
  const { isFetching, get, setMultiple, isSaving } = useOrganizationSettings()
  const confirm = useConfirm()
  const { openDrawer } = useDrawer()
  const providerType = useCurrentProviderType()
  const queryClient = useQueryClient()
  const resourceType = useCurrentProviderTypeName({ isPlural: true })
  const { formatMessage } = useIntl()
  const minimumMediumSpend = get('mediumSpendMinValue')
  const maximumMediumSpend = get('mediumSpendMaxValue')
  const spendIntervalType = get('spendIntervalType')
  const isAutomaticSpendIntervalType = spendIntervalType === SpendIntervalType.Automatic

  const [isEditing, setIsEditing] = useState(false)
  const currencyUnitId = get('currencyUnitId') as number
  const formMethods = useForm<SettingsChanged>()
  const {
    control,
    handleSubmit,
    setError,
    errors,
    clearErrors,
    reset,
    formState: { isDirty },
  } = formMethods

  const { items: units, isLoading: isLoadingUnits } = useFetchCollectionWithPost<Unit>({
    key: FetchKey.Unit,
    endpoint: endpoints.units,
    payload: {
      filter: [{ name: 'symbol', filters: [{ value: UNITS_TO_SHOW, operator: Operators.In }] }],
      include: [],
    },
    options: { refetchOnMount: false, cacheTime: UNIT_CACHE_TIME, staleTime: UNIT_CACHE_TIME },
  })

  useEffect(() => {
    if (!isFetching && !isLoadingUnits) {
      reset({
        currencyUnitId,
        mediumSpendMinValue: isAutomaticSpendIntervalType ? NaN : Number(minimumMediumSpend),
        mediumSpendMaxValue: isAutomaticSpendIntervalType ? NaN : Number(maximumMediumSpend),
      })
    }
  }, [
    isFetching,
    isLoadingUnits,
    reset,
    currencyUnitId,
    minimumMediumSpend,
    maximumMediumSpend,
    isAutomaticSpendIntervalType,
  ])

  const selectedCurrencyUnitId = useWatch({ control, name: 'currencyUnitId', defaultValue: currencyUnitId })
  const selectedCurrency = units.find(u => u.id === selectedCurrencyUnitId)?.symbol || ''

  const {
    facets: [providersCount = []],
  } = useFetchFacets({
    key: FetchKey.ProviderCount,
    endpoint: endpoints.providersWithFacets,
    facetsParam: [{ name: 'id' }],
    options: {
      enabled: true,
      staleTime: staleTime,
    },
  })

  const {
    facets: [providerSpends = []],
  } = useFetchFacets({
    key: FetchKey.ProviderFacets,
    endpoint: endpoints.providersWithFacets,
    facetsParam: [{ name: 'spends.amount' }],
    options: {
      enabled: true,
      staleTime: staleTime,
    },
  })

  const maximumProviderSpend = useMemo(() => {
    if (!providerSpends.length) return null
    return Math.max(...providerSpends.map(x => Number(x.label)))
  }, [providerSpends])

  const isProviderSpendAdded = Boolean(providerSpends.length)
  const showAutomaticSpendIntervalIndicatorIcon = !isEditing && isAutomaticSpendIntervalType && isProviderSpendAdded

  const noProvidersEmptyState = !providersCount.length

  const cancelEdit = () => {
    if (isDirty) {
      confirm({
        hideCancelButton: false,
        confirmationText: formatMessage({ id: 'general.confirm' }),
        title: formatMessage({ id: 'general.youHaveUnsavedChanges' }),
        content: formatMessage({ id: 'general.exitWithoutSaving' }),
      }).then(() => setIsEditing(false))
    } else {
      setIsEditing(false)
    }
  }

  const getMinErrorMessage = (minValue: number, maxValue: number, maximumProviderSpend: number | null) => {
    if (Number.isNaN(minValue) && maxValue) {
      return formatMessage({ id: 'error.requiredFields' })
    } else if (isNotPositiveWholeNumber(minValue)) {
      return formatMessage({ id: 'error.mustBePositiveWholeNumber' })
    } else if (minValue && maximumProviderSpend && minValue >= maximumProviderSpend) {
      return formatMessage({ id: 'error.valueHigherThanProviderSpend' }, { providerSpend: maximumProviderSpend })
    }
    return null
  }

  const getMaxErrorMessage = (minValue: number, maxValue: number, maximumProviderSpend: number | null) => {
    if (Number.isNaN(maxValue) && minValue) {
      return formatMessage({ id: 'error.requiredFields' })
    } else if (minValue && maxValue && minValue >= maxValue) {
      return formatMessage({ id: 'error.maxLowerThanMin' })
    } else if (isNotPositiveWholeNumber(maxValue)) {
      return formatMessage({ id: 'error.mustBePositiveWholeNumber' })
    } else if (maxValue && maximumProviderSpend && maxValue >= maximumProviderSpend) {
      return formatMessage({ id: 'error.valueHigherThanProviderSpend' }, { providerSpend: maximumProviderSpend })
    }
    return null
  }

  const submitHandler = async (values: SettingsChanged, skipValidation = false) => {
    const { mediumSpendMinValue, mediumSpendMaxValue } = values
    const minValue = mediumSpendMinValue === null ? NaN : Number(mediumSpendMinValue) ?? NaN
    const maxValue = mediumSpendMaxValue === null ? NaN : Number(mediumSpendMaxValue) ?? NaN
    clearErrors()

    if (!skipValidation) {
      const minValueIsFalsyButNotZero = [NaN, null, undefined].includes(mediumSpendMinValue)
      const maxValueIsFalsyButNotZero = [NaN, null, undefined].includes(mediumSpendMaxValue)

      if (!(minValueIsFalsyButNotZero && maxValueIsFalsyButNotZero)) {
        const minErrorMessage = getMinErrorMessage(minValue, maxValue, maximumProviderSpend)
        const maxErrorMessage = getMaxErrorMessage(minValue, maxValue, maximumProviderSpend)

        if (minErrorMessage) {
          setError('mediumSpendMinValue', { message: minErrorMessage })
        }
        if (maxErrorMessage) {
          setError('mediumSpendMaxValue', { message: maxErrorMessage })
        }

        if (minErrorMessage || maxErrorMessage) return
      }
    }

    await setMultiple({
      ...values,
      mediumSpendMinValue: skipValidation ? null : values.mediumSpendMinValue,
      mediumSpendMaxValue: skipValidation ? null : values.mediumSpendMaxValue,
    })
    setIsEditing(false)
  }

  const onSubmit: SubmitHandler<SettingsChanged> = async values => {
    await submitHandler(values, false)
  }

  const onSetAutomaticInterval = async () => {
    const currentValues = formMethods.getValues()
    await submitHandler(currentValues, true)
  }

  const getButtons = (): ActionButton[] =>
    isEditing
      ? [
          {
            label: formatMessage({ id: 'general.cancel' }),
            variant: 'text',
            size: 'large',
            onClick: cancelEdit,
            loading: isSaving,
          },
          {
            label: formatMessage({ id: 'general.save' }),
            variant: 'contained',
            size: 'large',
            onClick: handleSubmit(onSubmit),
            loading: isSaving,
          },
        ]
      : [
          {
            label: formatMessage({ id: 'general.edit' }),
            variant: 'contained',
            size: 'large',
            onClick: () => setIsEditing(true),
          },
        ]

  if (isFetching || isLoadingUnits) return <ConfigurationSkeleton />

  const onSpendImportSuccess = () => {
    queryClient.invalidateQueries(FetchKey.OrganizationSettings)
    queryClient.invalidateQueries(FetchKey.ProviderFacets)
  }

  return (
    <ConfigurationCollection actionButtons={getButtons()}>
      <FormProvider {...formMethods}>
        <form>
          <Box bgcolor="common.white" mr={4} p={2} borderRadius={1}>
            <Grid container>
              <Grid item xs={12} lg={6}>
                <Stack spacing={3}>
                  <Stack>
                    <Typography variant="body2">{formatMessage({ id: 'general.currency' })}</Typography>
                    {isEditing ? (
                      <Box pb={1}>
                        <Select<number>
                          fullWidth
                          hoveringLabel
                          name="currencyUnitId"
                          options={units.map(u => ({ value: u.id, label: u.symbol ?? u.name }))}
                          error={errors?.currencyUnitId?.message}
                          fieldLabel=""
                          control={control}
                        />
                      </Box>
                    ) : (
                      <Typography variant="body2" color="textSecondary">
                        {units.find(u => u.id === currencyUnitId)?.symbol}
                      </Typography>
                    )}
                  </Stack>
                </Stack>
              </Grid>
            </Grid>
          </Box>

          <Stack
            direction="row"
            sx={{
              alignItems: 'center',
              mt: isEditing || !isAutomaticSpendIntervalType ? 4 : 3,
              justifyContent: 'space-between',
              mr: 4,
            }}
          >
            <Stack direction="row" sx={{ alignItems: 'center' }}>
              {showAutomaticSpendIntervalIndicatorIcon ? (
                <Tooltip
                  arrow
                  title={formatMessage(
                    { id: 'organizationPreferences.groupBySpendTooltip' },
                    { b: (chunks: React.ReactNode) => <b>{chunks}</b> },
                  )}
                >
                  <span>
                    <IconButton sx={{ color: 'communication.dark', cursor: 'default', ml: '-10px' }}>
                      <AssistantSharp />
                    </IconButton>
                  </span>
                </Tooltip>
              ) : null}
              <Typography variant="subtitle1">
                {formatMessage({ id: 'organizationPreferences.groupBySpend' })}
              </Typography>
            </Stack>

            {isProviderSpendAdded && !isAutomaticSpendIntervalType && (
              <LoadingButton
                startIcon={<AssistantSharp color="primary" />}
                size="small"
                onClick={onSetAutomaticInterval}
                loading={isSaving}
                variant="text"
              >
                {formatMessage({ id: 'organizationPreferences.setAutomaticInterval' })}
              </LoadingButton>
            )}
          </Stack>

          {isProviderSpendAdded ? (
            <Box mt={isEditing || !isAutomaticSpendIntervalType ? 2 : 1} mr={4}>
              <Grid container spacing={2}>
                <Grid item xs={12} md={3} lg={3.5}>
                  <Box p={2} bgcolor="common.white" height="100%">
                    <Typography color={isEditing ? 'text.disabled' : 'text.primary'}>
                      {formatMessage({ id: 'organizationPreferences.lowSpend' })}
                    </Typography>
                    <Typography
                      variant="body2"
                      color={isEditing ? 'text.disabled' : 'text.secondary'}
                      lineHeight="1.4em"
                      pt="3px"
                    >
                      {minimumMediumSpend
                        ? `0 → ${minimumMediumSpend} ${selectedCurrency}`
                        : `0 → ${formatMessage({ id: 'organizationPreferences.noSpendInfo' })}`}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={12} md={6} lg={5}>
                  <Box p={2} bgcolor="common.white" height="100%">
                    <Typography>{formatMessage({ id: 'organizationPreferences.mediumSpend' })}</Typography>
                    <Stack direction="row" spacing={4}>
                      {isEditing ? (
                        <>
                          <Box width="50%" pb={1}>
                            <Controller
                              name="mediumSpendMinValue"
                              control={control}
                              render={({ onChange, onBlur, value, name }) => (
                                <TextField
                                  fullWidth
                                  label={formatMessage({ id: 'organizationPreferences.min' })}
                                  type="number"
                                  placeholder={formatMessage({ id: 'organizationPreferences.setValue' })}
                                  onChange={e => {
                                    const inputValue = e.target.value
                                    onChange(inputValue ? Number(inputValue) : null)
                                  }}
                                  onBlur={onBlur}
                                  value={value}
                                  name={name}
                                  error={Boolean(errors?.mediumSpendMinValue)}
                                  helperText={errors?.mediumSpendMinValue?.message}
                                  InputProps={{
                                    endAdornment: <InputAdornment position="end">{selectedCurrency}</InputAdornment>,
                                  }}
                                />
                              )}
                            />
                          </Box>

                          <Box width="50%" pb={1}>
                            <Controller
                              name="mediumSpendMaxValue"
                              control={control}
                              render={({ onChange, onBlur, value, name }) => (
                                <TextField
                                  fullWidth
                                  label={formatMessage({ id: 'organizationPreferences.max' })}
                                  type="number"
                                  placeholder={formatMessage({ id: 'organizationPreferences.setValue' })}
                                  onChange={e => {
                                    const inputValue = e.target.value
                                    onChange(inputValue ? Number(inputValue) : null)
                                  }}
                                  onBlur={onBlur}
                                  value={value}
                                  name={name}
                                  error={Boolean(errors?.mediumSpendMaxValue)}
                                  helperText={errors?.mediumSpendMaxValue?.message}
                                  InputProps={{
                                    endAdornment: <InputAdornment position="end">{selectedCurrency}</InputAdornment>,
                                  }}
                                />
                              )}
                            />
                          </Box>
                        </>
                      ) : (
                        <Typography color="text.secondary" variant="body2">
                          {minimumMediumSpend && maximumMediumSpend
                            ? `${minimumMediumSpend} ${selectedCurrency} → ${maximumMediumSpend} ${selectedCurrency}`
                            : '-'}
                        </Typography>
                      )}
                    </Stack>
                  </Box>
                </Grid>
                <Grid item xs={12} md={3} lg={3.5}>
                  <Box p={2} bgcolor="common.white" height="100%">
                    <Typography color={isEditing ? 'text.disabled' : 'text.primary'}>
                      {formatMessage({ id: 'organizationPreferences.highSpend' })}
                    </Typography>
                    <Typography
                      color={isEditing ? 'text.disabled' : 'text.secondary'}
                      variant="body2"
                      lineHeight="1.4em"
                      pt="3px"
                    >
                      {(maximumMediumSpend
                        ? `${maximumMediumSpend} ${selectedCurrency}`
                        : `${formatMessage({ id: 'organizationPreferences.noSpendInfo' })}`) +
                        (maximumMediumSpend || maximumProviderSpend ? ' → ' : '') +
                        (maximumProviderSpend ? `${maximumProviderSpend} ${selectedCurrency}` : '')}
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          ) : (
            <Box px={4} py={5} mt={1} mr={4} borderRadius={1} sx={{ backgroundColor: 'grey.200' }}>
              <EmptyState
                iconComponent={EuroOutlined}
                title={formatMessage({ id: 'organizationPreferences.groupBySpendEmptyState.description' })}
                variant={EmptyStateVariant.Small}
              >
                <Tooltip
                  arrow
                  title={
                    noProvidersEmptyState
                      ? formatMessage({ id: 'import.noProvidersEmptyState' }, { provider: resourceType })
                      : ''
                  }
                >
                  <Box>
                    <Button
                      disabled={noProvidersEmptyState}
                      startIcon={<Add />}
                      variant="contained"
                      size="small"
                      sx={{ mb: 1.5 }}
                      onClick={() =>
                        openDrawer(
                          <DrawerViewImportSpend
                            providerType={providerType}
                            allowedFilters={[]}
                            onImportSuccess={onSpendImportSuccess}
                          />,
                        )
                      }
                    >
                      {formatMessage({ id: 'organizationPreferences.groupBySpendEmptyState.button' })}
                    </Button>
                  </Box>
                </Tooltip>
              </EmptyState>
            </Box>
          )}
        </form>
      </FormProvider>
    </ConfigurationCollection>
  )
}

export default OrganizationPreferencesConfig
