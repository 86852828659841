import { useGetFormattedDate } from '@app/src/components/DateDisplay'
import Markdown from '@app/src/components/Markdown'
import StatusCell from '@app/src/components/Table/Cells/StatusCell'
import { AvatarSize } from '@app/src/components/Ui/Avatar'
import CompanyAvatar from '@app/src/pages/ResourceCollection/Collections/CellComponents/CompanyAvatar'
import { VerificationResponseItem } from '@app/src/types/resourceExplorer'
import { ListItem, ListItemAvatar, ListItemText, Stack, Typography } from '@mui/material'
import React from 'react'
import { useIntl } from 'react-intl'

interface Props {
  verification: VerificationResponseItem
}

const InternalVerificationStatusItem = ({ verification }: Props): JSX.Element | null => {
  const { formatMessage } = useIntl()
  const { formatValueToDate } = useGetFormattedDate()

  return (
    <Stack spacing={2}>
      <Stack direction="row" alignItems="center" justifyContent="space-between">
        <StatusCell verification value={verification.internalVerificationStatus} disableCell />
        <Typography variant="caption" color="textSecondary">
          {formatMessage({ id: 'reporting.verification.internalVerification' })}
        </Typography>
      </Stack>
      <ListItem alignItems={verification?.internalVerificationComment ? 'flex-start' : 'center'} disablePadding>
        <ListItemAvatar sx={({ spacing }) => ({ minWidth: spacing(4) })}>
          <CompanyAvatar
            imageUrl={verification.creatorUser?.image?.url}
            organizationName={verification.creatorUser?.name ?? ''}
            size={AvatarSize.Small}
            variant="circular"
          />
        </ListItemAvatar>
        <ListItemText
          primary={
            <Stack direction="row" alignItems="center">
              <Typography>{verification.creatorUser?.name}</Typography>
              <Typography variant="caption" color="textSecondary" ml={1}>
                {formatMessage(
                  { id: 'general.valueWithDotSeparatorBefore' },
                  { value: formatValueToDate({ value: verification.createdAt }) },
                )}
              </Typography>
            </Stack>
          }
          secondary={
            verification?.internalVerificationComment && (
              <Typography mt={1} variant="body1">
                <Markdown>{verification?.internalVerificationComment}</Markdown>
              </Typography>
            )
          }
        />
      </ListItem>
    </Stack>
  )
}

export default InternalVerificationStatusItem
