import { useDrawer } from '@app/src/components/Drawer/DrawerContext'
import DrawerView, { DrawerViewProps } from '@app/src/components/Drawer/DrawerView'
import Select from '@app/src/components/Form/Select/ControlledSelect'
import ControlledTextFieldWithFormatting from '@app/src/components/TextFieldWithFormatting/ControlledTextFieldWithFormatting'
import { useAmplitude } from '@app/src/context/AmplitudeContext'
import useAccessorQuestionnaire from '@app/src/pages/Questionnaire/hooks/useAccessorQuestionnaire'
import {
  ExternalVerificationStatus,
  ResponseItem,
  ResponseWithPreviousCorrectionNeededItem,
} from '@app/src/types/resourceExplorer'
import { AmplitudeTrackingEvents } from '@app/src/wf-constants'
import { EditOutlined } from '@mui/icons-material'
import { Avatar, List, ListItem, ListItemAvatar, ListItemText, Stack } from '@mui/material'
import React, { useEffect } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useIntl } from 'react-intl'

type DrawerViewVerificationProps = {
  response: ResponseWithPreviousCorrectionNeededItem
  item: ResponseItem
  questionDescription: string
} & Omit<DrawerViewProps, 'title'>

interface FormData {
  externalVerificationStatus: ExternalVerificationStatus
  externalVerificationComment: string
}

const DrawerViewResponseItemExternalVerification: React.FC<DrawerViewVerificationProps> = ({
  response,
  item,
  questionDescription,
  ...props
}) => {
  const { formatMessage } = useIntl()
  const { closeDrawer } = useDrawer()
  const { trackEvent } = useAmplitude()
  const { createVerificationResponseItem, isLoadingCreateVerification } = useAccessorQuestionnaire()

  const formMethods = useForm<FormData>()

  const { reset, handleSubmit, register, watch, control } = formMethods

  const externalVerificationStatus: ExternalVerificationStatus = watch('externalVerificationStatus')
  const externalVerificationComment = item.verifications?.[0]?.externalVerificationComment ?? ''

  const onSubmit = async (submittedVerification: FormData) => {
    await createVerificationResponseItem({
      responseItemIds: [item.id],
      externalVerificationStatus: submittedVerification.externalVerificationStatus,
      externalVerificationComment: submittedVerification.externalVerificationComment,
    })
    trackEvent({
      name: AmplitudeTrackingEvents.Accessor.ResponseItemVerification.ExternalVerification.Submitted,
      eventProps: {
        verificationStatus: submittedVerification.externalVerificationStatus,
        hasComment: Boolean(submittedVerification.externalVerificationComment),
      },
    })
    closeDrawer()
  }

  useEffect(() => {
    trackEvent({
      name: AmplitudeTrackingEvents.Accessor.ResponseItemVerification.ExternalVerification.Opened,
    })
    reset({
      externalVerificationComment,
      externalVerificationStatus:
        item.verifications?.[0]?.externalVerificationStatus ?? ExternalVerificationStatus.NotSet,
    })
  }, [response, item])

  return (
    <FormProvider {...formMethods}>
      <DrawerView
        stackButtons
        title={formatMessage({
          id: 'questionnaire.actions.review',
        })}
        subTitle={questionDescription}
        buttons={[
          {
            label: formatMessage({ id: 'general.cancel' }),
            variant: 'outlined',
            disabled: isLoadingCreateVerification,
            onClick: closeDrawer,
          },
          {
            label: formatMessage({ id: 'general.submit' }),
            variant: 'contained',
            loading: isLoadingCreateVerification,
            type: 'submit',
          },
        ]}
        onFormSubmit={handleSubmit(onSubmit)}
        {...props}
      >
        <Stack px={3} flexGrow={1} spacing={2}>
          <input type="hidden" name="id" ref={register()} />

          <Select<ExternalVerificationStatus>
            name="externalVerificationStatus"
            control={control}
            fieldLabel={formatMessage({ id: 'reporting.verification.verification' })}
            variant="outlined"
            options={[
              {
                value: ExternalVerificationStatus.NotSet,
                label: formatMessage({ id: 'reporting.statuses.ToBeVerified' }),
              },
              {
                value: ExternalVerificationStatus.Approved,
                label: formatMessage({ id: 'reporting.statuses.Approved' }),
              },
              {
                value: ExternalVerificationStatus.CorrectionNeeded,
                label: formatMessage({ id: 'reporting.statuses.CorrectionNeeded' }),
              },
            ]}
          />
          {externalVerificationStatus !== ExternalVerificationStatus.NotSet && (
            <ControlledTextFieldWithFormatting
              name="externalVerificationComment"
              control={control}
              defaultValue={externalVerificationComment}
              placeholder={
                externalVerificationStatus === ExternalVerificationStatus.CorrectionNeeded
                  ? formatMessage({ id: 'reporting.verification.addGuidanceForCorrection' })
                  : formatMessage({ id: 'reporting.verification.addOptionalMessage' })
              }
              multiline
              size="medium"
              hideUndo
              fillHeight
              maxLength={1000}
            />
          )}
          {externalVerificationStatus === ExternalVerificationStatus.CorrectionNeeded && (
            <List sx={{ mt: 2 }}>
              <ListItem disablePadding alignItems="flex-start">
                <ListItemAvatar>
                  <Avatar
                    sx={({ palette }) => ({
                      color: palette.warning.contrastText,
                      bgcolor: palette.warning.light,
                    })}
                  >
                    <EditOutlined />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText
                  primaryTypographyProps={{ variant: 'subtitle1' }}
                  primary={formatMessage({
                    id: 'reporting.verification.correctionNeededChangedExplanation.nowOnQuestion.header',
                  })}
                  secondary={formatMessage({
                    id: 'reporting.verification.correctionNeededChangedExplanation.nowOnQuestion.textOnQuestionVerification',
                  })}
                  sx={{ m: 0 }}
                />
              </ListItem>
            </List>
          )}
        </Stack>
      </DrawerView>
    </FormProvider>
  )
}

export default DrawerViewResponseItemExternalVerification
