import { FetchKey } from '@app/src/api/fetchHooks'
import { NO_PERIOD_PERIOD_NAME } from '@app/src/components/CreateNewRequestModal/PeriodSelector/PeriodSelector'
import { useGroupBySelector } from '@app/src/context/GroupBySelectorContext'
import { useGetApiQueryFilters } from '@app/src/hooks/queryFilters'
import { Grid } from '@mui/material'
import React, { useEffect } from 'react'
import { useIntl } from 'react-intl'
import { FacetItem } from '..'
import { RESPONSE_ITEM_PERIOD_SMART_FILTER_SETTING } from './Common/SmartFilterSettings'
import Filter from './Filter'
import { FilterInterface, useFiltersContext } from './Filters'
import SmartFilterFacetSelect from './SmartFilterFacetSelect'
import { AnswersGroupingType, RESPONSE_REQUEST_PERIOD_NAME } from './StatisticsConstants'
import { Operators } from './useFilters'

interface PeriodFilterProps {
  preSelectedValues?: Array<FacetItem['value']>
  allowedFilters: string[]
  showGroupBy?: boolean
}

const PERIOD_FILTER_NAME = 'response.request.periodName'

export const PeriodFilter: React.FC<PeriodFilterProps> = ({ preSelectedValues, showGroupBy, allowedFilters }) => {
  const { formatMessage } = useIntl()
  const { setQueryFilter } = useFiltersContext()
  const { groupBy } = useGroupBySelector()
  const userFilters = useGetApiQueryFilters(allowedFilters)

  const periodFilterValue = userFilters.find(group => group.name === PERIOD_FILTER_NAME)?.filters[0]?.value

  const periodFilterWasCleared = !periodFilterValue

  const selectedPeriodsWithoutNoPeriod = preSelectedValues?.filter(value => value !== NO_PERIOD_PERIOD_NAME)

  const getSelectProps = (
    onChange: (value: FilterInterface['value'] | null, replace?: boolean) => void,
    value: string | string[],
  ) => {
    if (groupBy === AnswersGroupingType.PeriodName) {
      return {
        key: 'period-name-select',
        multiple: true as const,
        onChange: (value: string[]) => onChange(value),
        value: [value].flat(),
      }
    } else {
      return {
        key: 'spend-classification-select',
        multiple: false as const,
        onChange: (value: string | null) => onChange(value),
        value: value as string,
      }
    }
  }

  useEffect(() => {
    if (showGroupBy && selectedPeriodsWithoutNoPeriod?.length) {
      if (periodFilterWasCleared || groupBy === AnswersGroupingType.PeriodName) {
        setQueryFilter(RESPONSE_REQUEST_PERIOD_NAME, selectedPeriodsWithoutNoPeriod as string[], Operators.In)
      }
      if (groupBy === AnswersGroupingType.SpendClassification) {
        setQueryFilter(RESPONSE_REQUEST_PERIOD_NAME, selectedPeriodsWithoutNoPeriod[0] as string, Operators.EqualTo)
      }
    }
  }, [groupBy, periodFilterWasCleared])

  return (
    <Grid item xs={2}>
      <Filter
        name={PERIOD_FILTER_NAME}
        operator={groupBy === AnswersGroupingType.PeriodName ? Operators.In : Operators.EqualTo}
      >
        {({ value, onChange, filterName }) => (
          <SmartFilterFacetSelect
            {...getSelectProps(onChange, value)}
            size="small"
            facetSettings={{
              key: [FetchKey.ResponseItemsFacets, filterName],
              ...RESPONSE_ITEM_PERIOD_SMART_FILTER_SETTING,
            }}
            filterName={filterName}
            fieldLabel={formatMessage({ id: 'schemas.request.period' })}
          />
        )}
      </Filter>
    </Grid>
  )
}
