import EmptyState from '@app/src/components/EmptyState/EmptyState'
import { useAuthentication } from '@app/src/context/AuthenticationContext'
import { Security } from '@mui/icons-material'
import { Box, Button, Paper } from '@mui/material'
import React, { ReactNode } from 'react'
import { useIntl } from 'react-intl'
import VerifyEmail from './VerifyEmail'

export type Error = {
  code: string
  description?: string
}

type AuthenticationErrorProps = {
  children: ReactNode
  error?: Error
}

const AuthenticationError: React.FC<AuthenticationErrorProps> = ({ children, error }) => {
  const { logout } = useAuthentication()
  const { formatMessage } = useIntl()

  if (error?.description?.startsWith('email_not_verified')) {
    const userEmail = error.description.split(' ')[1]
    return <VerifyEmail userEmail={userEmail} />
  }

  if (error) {
    return (
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          position: 'fixed',
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          bgcolor: 'secondary.main',
        }}
      >
        <Paper elevation={1}>
          <EmptyState
            sx={{
              paddingX: 4,
              paddingY: 6,
              minWidth: 300,
              maxWidth: 400,
            }}
            title={formatMessage({ id: `authentication.${error.description}.title`, defaultMessage: error.code })}
            description={formatMessage({
              id: `authentication.${error.description}.description`,
              defaultMessage: error.description,
            })}
            iconComponent={Security}
          >
            <Button variant="contained" onClick={logout}>
              {formatMessage({ id: 'authentication.tryToLogInAgain' })}
            </Button>
          </EmptyState>
        </Paper>
      </Box>
    )
  }

  return <>{children}</>
}

export default AuthenticationError
