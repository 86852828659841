import ResponseValue from '@app/src/pages/Questionnaire/Accessor/Report/ResponseValue'
import EditResponse from '@app/src/pages/Questionnaire/Provider/Report/EditResponse'
import { RequestItem, ResponseItem } from '@app/src/types/resourceExplorer'
import { Box, Typography } from '@mui/material'
import React from 'react'
import { useIntl } from 'react-intl'

interface EditResponseWithPreviousCorrectionNeededAnswerProps {
  item: ResponseItem
  previousCorrectionNeededAnswer: ResponseItem
  requestItem: RequestItem
  questionType: string
  unitSymbol: string | null
  sectionNumber: number
  itemNumber: number
}

const EditResponseWithPreviousCorrectionNeededAnswer: React.FC<EditResponseWithPreviousCorrectionNeededAnswerProps> = ({
  item,
  previousCorrectionNeededAnswer,
  questionType,
  requestItem,
  unitSymbol,
  sectionNumber,
  itemNumber,
}) => {
  const { formatMessage } = useIntl()
  const questionDescription = requestItem?.description || ''

  return (
    <Box>
      <Box ml={4}>
        <Typography variant="subtitle2" color="textSecondary">
          {formatMessage({ id: 'reporting.verification.previousAnswer' })}
        </Typography>
        <ResponseValue
          item={previousCorrectionNeededAnswer}
          questionType={questionType}
          unitSymbol={unitSymbol}
          questionDescription={questionDescription}
        />
        <Box mt={2}>
          <Typography variant="subtitle2">{formatMessage({ id: 'reporting.verification.reviewedAnswer' })}</Typography>
        </Box>
      </Box>
      <EditResponse
        questionType={questionType}
        requestItem={requestItem}
        responseItemId={item.id}
        sectionNumber={sectionNumber}
        itemNumber={itemNumber}
      />
    </Box>
  )
}

export default EditResponseWithPreviousCorrectionNeededAnswer
