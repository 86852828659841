import endpoints from '@app/src/api/endpoints'
import { FetchKey, useFetchCollectionWithPost } from '@app/src/api/fetchHooks'
import { ActionButtonsProps } from '@app/src/components/ActionButtons'
import { useDrawer } from '@app/src/components/Drawer/DrawerContext'
import { useDrawer_DEPRECATED } from '@app/src/components/Drawer_DEPRECATED'
import UpdateProvidersDrawer from '@app/src/components/ManageProviderDrawer/UpdateProvidersDrawer'
import Table from '@app/src/components/Table'
import usePagination from '@app/src/hooks/pagination'
import useSort from '@app/src/hooks/sorting'
import useAssessmentQuestionnaireLink from '@app/src/hooks/useAssessmentQuestionnaireLink'
import useCategorizeSelectionButtons from '@app/src/hooks/useCategorizeSelectionButtons'
import { useSelectableRowsWithPostSelectAll } from '@app/src/hooks/useSelectableRows/useSelectableRowsWithPostSelectAll'
import { FilterGroup, Operators } from '@app/src/pages/ResourceCollection/Filters/useFilters'
import Assessment from '@app/src/types/assessment'
import { Provider } from '@app/src/types/organizations'
import { Question, ResponseItem } from '@app/src/types/resourceExplorer'
import { AmplitudeTrackingPages } from '@app/src/wf-constants'
import { Box } from '@mui/material'
import { maxBy } from 'lodash'
import React, { useEffect, useMemo, useState } from 'react'
import AssessmentsDetailsHeader from '../../ResourceCollection/Collections/Assessments/AssessmentsDetailsHeader'
import AssessmentsDetailsRow from '../../ResourceCollection/Collections/Assessments/AssessmentsDetailsRow'

type AssessmentInsightsDetailsProps = {
  assessmentTemplateId: string
  userFilters?: FilterGroup[]
  periodName?: string
  questions?: Question[]
  onButtonRowChange?: (buttons: ActionButtonsProps['buttons']) => void
}

type AssessmentWithResponses = Assessment & {
  responseItems: Array<ResponseItem | undefined>
}

const staleTime = 60000

const AssessmentInsightsDetails: React.FC<AssessmentInsightsDetailsProps> = ({
  assessmentTemplateId,
  userFilters,
  periodName,
  questions,
  onButtonRowChange,
}) => {
  const { sorting, toggleSorting } = useSort()
  const [page, pageSize, setPage, setPageSize] = usePagination({ page: 1, pageSize: 50 })
  const [combinedData, setCombinedData] = useState<AssessmentWithResponses[] | undefined>(undefined)
  const questionIds = useMemo(() => questions?.map(question => question.id), [questions])
  const [isUpdateDrawerOpen, openUpdateDrawer, closeUpdateDrawer] = useDrawer_DEPRECATED(false)
  const { openDrawer } = useDrawer()

  const {
    items: assessments,
    count,
    isFetched: isAssessmentsFetched,
  } = useFetchCollectionWithPost<Assessment>({
    key: FetchKey.AssessmentCollection,
    endpoint: endpoints.assessmentCollection,
    payload: {
      filter: [
        ...(userFilters ?? []),
        {
          name: 'assessmentTemplateId',
          filters: [{ operator: Operators.EqualTo, value: assessmentTemplateId }],
        },
        {
          name: 'periodName',
          filters: [{ operator: Operators.EqualTo, value: periodName }],
        },
      ],
      sort: sorting,
      include: ['provider.organization', 'assessmentTemplate'],
      pagination: {
        itemsPerPage: pageSize,
        pageNumber: page,
      },
    },
    options: { enabled: questions && !!periodName, staleTime: staleTime },
  })

  const providerIds = assessments.map(assessment => assessment.providerId)
  const providerOrganizationIds = assessments.map(assessment => assessment.provider.organizationId)

  const { assessmentQuestionnaireLinks, isLoadingAssessmentQuestionnaireLink } = useAssessmentQuestionnaireLink({
    assessmentTemplateId: Number(assessmentTemplateId),
    includeAssessment: false,
  })

  const linkedQuestionnaireTemplateIds = assessmentQuestionnaireLinks.map(x => x.questionnaireTemplateId) as number[]

  const {
    items: responseItems,
    isFetched: isResponseItemsFetched,
    isError,
  } = useFetchCollectionWithPost<ResponseItem>({
    endpoint: endpoints.responseItemsCollection,
    key: FetchKey.ResponseItemsCollection,
    payload: {
      filter: [
        {
          name: 'response.request.responderOrganizationId',
          filters: [{ operator: Operators.In, value: providerOrganizationIds }],
        },
        {
          name: 'creatorOrganizationId',
          filters: [{ operator: Operators.In, value: providerOrganizationIds }],
        },
        {
          name: 'response.isLatestSubmitted',
          filters: [{ operator: Operators.EqualTo, value: true }],
        },
        {
          name: 'response.request.periodName',
          filters: [{ operator: Operators.EqualTo, value: periodName }],
        },
        {
          name: 'requestItem.questionId',
          filters: [{ operator: Operators.In, value: questionIds }],
        },
        {
          name: 'response.request.subscriptions.target.id',
          filters: [{ operator: Operators.In, value: providerIds }],
        },
        {
          name: 'response.request.questionnaireTemplateId',
          filters: [{ operator: Operators.In, value: linkedQuestionnaireTemplateIds }],
        },
      ],
      include: ['requestItem.unit.symbol', 'creatorOrganization', 'questionAnswerClassificationResults'],
    },
    options: {
      enabled: Boolean(
        !!periodName && providerIds?.length && questionIds?.length && !isLoadingAssessmentQuestionnaireLink,
      ),
      staleTime: staleTime,
    },
  })

  useEffect(() => {
    if (!isResponseItemsFetched) {
      setCombinedData(undefined)
    }
  }, [isAssessmentsFetched, isResponseItemsFetched])

  const responseItemsMap = useMemo(() => {
    // Group responseItems by organization and question
    const responseItemsGroupedByOrgAndQuestion = Object.groupBy(
      responseItems,
      ri => `${ri.creatorOrganizationId}|${ri.requestItem.questionId}`,
    )

    // Get only the latest submitted responseItems per group
    const latestResponseItemGroupEntries = Object.entries(responseItemsGroupedByOrgAndQuestion).map(
      ([key, responseItems]) => {
        return {
          key,
          responseItem: maxBy(responseItems ?? [], ri => new Date(ri.response.submittedAt).getTime()),
        }
      },
    )

    return new Map(latestResponseItemGroupEntries.map(g => [g.key, g.responseItem]))
  }, [responseItems])

  useEffect(() => {
    if (isResponseItemsFetched && !isError) {
      const assessmentsWithResponseItems = assessments.map(assessment => {
        const matchingResponseItems =
          questionIds?.map(questionId => responseItemsMap.get(`${assessment.provider.organizationId}|${questionId}`)) ??
          []

        return {
          ...assessment,
          responseItems: matchingResponseItems,
        }
      })
      setCombinedData(assessmentsWithResponseItems)
    }
  }, [assessments, questionIds, responseItemsMap, isResponseItemsFetched, isError])

  const uniqueProviders = useMemo(() => {
    if (!isResponseItemsFetched || isError) return []
    return assessments?.flatMap(p => p.provider) ?? []
  }, [isResponseItemsFetched, isError])

  const {
    selectedRowsIds,
    setSelectedRowsIds,
    isLoadingAllRows,
    isAllSelected,
    handleSelectAll,
    handleCheckboxChange,
    isHeaderChecked,
    handleHeaderCheckboxChange,
  } = useSelectableRowsWithPostSelectAll<Provider, Assessment>({
    rowsInPage: uniqueProviders,
    basePayload: {
      filter: [
        ...(userFilters ?? []),
        {
          name: 'assessmentTemplateId',
          filters: [{ operator: Operators.EqualTo, value: assessmentTemplateId }],
        },
        {
          name: 'periodName',
          filters: [{ operator: Operators.EqualTo, value: periodName }],
        },
      ],
      sort: sorting,
      include: ['provider.organization', 'assessmentTemplate'],
    },
    fetchKey: FetchKey.AssessmentCollection,
    endpoint: endpoints.assessmentCollection,
    count: count,
    idSelector: row => row.provider.id,
  })

  useCategorizeSelectionButtons({
    selectedRowsIds,
    openUpdateDrawer,
    openDrawer,
    onButtonChange: onButtonRowChange,
    handleSelectAll,
    isAllSelected,
    isLoadingAllRows,
    pageSize,
    count,
  })

  const { items: providers } = useFetchCollectionWithPost<Provider>({
    key: FetchKey.ProviderCollection,
    endpoint: endpoints.providersCollection,
    payload: {
      filter: [
        {
          name: 'id',
          filters: [{ operator: Operators.In, value: selectedRowsIds }],
        },
      ],
      sort: sorting,
      include: [],
      pagination: {
        itemsPerPage: selectedRowsIds?.length ?? 0,
        pageNumber: page,
      },
    },
    options: { enabled: isAllSelected(), staleTime: staleTime },
  })

  const selectedProvidersData = useMemo(() => {
    const sourceProviders = isAllSelected() ? providers : uniqueProviders

    return sourceProviders
      .filter(provider => selectedRowsIds.includes(provider.id))
      .map(provider => ({
        providerId: provider.id,
        name: provider.name,
        activityStatus: provider.activityStatus,
        finalRiskRating: provider.finalRiskRating,
        priority: provider.priority,
        providerApprovalStatus: provider.providerApprovalStatus,
        categoryOptionIds: provider.categoryOptions?.map(catOpt => catOpt.id),
        ownerUserId: provider.ownerUserId,
        tier: provider.tier,
        supplierUsage: provider.supplierUsage,
      }))
  }, [uniqueProviders, selectedRowsIds, isAllSelected, providers])

  return (
    <>
      <Box ml={4}>
        <Table<AssessmentWithResponses>
          RowComponent={({ row }): JSX.Element => (
            <AssessmentsDetailsRow
              assessment={row}
              onCheckboxChange={handleCheckboxChange}
              selectedProviders={selectedRowsIds}
              responseItems={row.responseItems}
            />
          )}
          HeaderComponent={() => (
            <AssessmentsDetailsHeader
              questions={questions}
              toggleSorting={toggleSorting}
              activeSorting={sorting}
              handleHeaderCheckboxChange={handleHeaderCheckboxChange}
              isHeaderChecked={isHeaderChecked}
            />
          )}
          count={count}
          data={combinedData ?? []}
          isLoading={!(Boolean(combinedData) && isAssessmentsFetched && isResponseItemsFetched)}
          isError={isError}
          page={page}
          pageSize={pageSize}
          setPage={setPage}
          setPageSize={setPageSize}
          stickyColumnIndex={1}
        />
      </Box>

      <UpdateProvidersDrawer
        selectedProviders={selectedProvidersData}
        setSelectedProviders={setSelectedRowsIds}
        isDrawerOpen={isUpdateDrawerOpen}
        closeDrawer={closeUpdateDrawer}
        pageOpenedFromName={AmplitudeTrackingPages.AssessmentInsightsDetails}
      />
    </>
  )
}

export default AssessmentInsightsDetails
