import endpoints from '@app/src/api/endpoints'
import { FetchKey } from '@app/src/api/fetchHooks'
import { useUpdateResource } from '@app/src/api/updateHooks'
import CreateNewRequestModal from '@app/src/components/CreateNewRequestModal'
import Dialog from '@app/src/components/Dialog'
import Table from '@app/src/components/Table'
import { useAmplitude } from '@app/src/context/AmplitudeContext'
import { ReportType } from '@app/src/context/CreationModalProgressContext'
import CreationModalProgressContextProvider from '@app/src/context/CreationModalProgressContextProvider'
import { useSnackbar } from '@app/src/context/SnackbarContext'
import useCurrentProviderTypeName from '@app/src/hooks/currentProviderTypeName'
import { useDialogState } from '@app/src/hooks/mui-hooks'
import useAssessmentQuestionnaireInquiriesCount from '@app/src/hooks/useAssessmentQuestionnaireInquiriesCount'
import { useFetchAssessmentSettings } from '@app/src/pages/Assessments/useFetchAssessmentSettings'
import ConfigurationCollection from '@app/src/pages/Configurations/ConfigurationCollection'
import CreateAutomationModal from '@app/src/pages/Configurations/ConfigurationsPages/Automation/CreateAutomationModal'
import { OrganizationAssessmentSettings } from '@app/src/types/resourceExplorer'
import { AmplitudeTrackingEvents, DEFAULT_TABLE_PAGE_SIZE, NotificationSeverity } from '@app/src/wf-constants'
import { useFlags } from 'launchdarkly-react-client-sdk'
import React, { useCallback, useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import { useQueryClient } from 'react-query'
import AssessmentSettingsHeader from './AssessmentSettingsHeader'
import AssessmentSettingsRow from './AssessmentSettingsRow'

interface OrganizationAssessmentSettingSaveModel {
  assessmentTemplateId: number
  isActivated: boolean
}

const AssessmentConfig: React.FC = () => {
  const { formatMessage } = useIntl()
  const { assessments } = useFlags()
  const { mutateAsync } = useUpdateResource<OrganizationAssessmentSettingSaveModel>()
  const { showSnackbar } = useSnackbar()
  const { trackEvent } = useAmplitude()
  const queryClient = useQueryClient()
  const providerTypeNamePlural = useCurrentProviderTypeName({ isPlural: true })
  const [toggledOrganizationAssessmentSetting, setToggledOrganizationAssessmentSetting] = useState<
    OrganizationAssessmentSettings | undefined
  >()
  const [isCreateRequestDialogOpen, openCreateRequestDialog, closeCreateRequestDialog] = useDialogState()
  const [isActivationSuccessDialogOpen, openActivationSuccessDialog, closeActivationSuccessDialog] = useDialogState()
  const [isCreateAutomationDialogOpen, openCreateAutomationDialog, closeCreateAutomationDialog] = useDialogState()
  const { assessmentSettings, isLoading, isError } = useFetchAssessmentSettings()

  const [page, setPage] = useState<number>(1)
  const [pageSize, setPageSize] = useState<number>(DEFAULT_TABLE_PAGE_SIZE)
  const pageCount = assessments ? assessmentSettings?.length ?? 0 : Math.min(1, assessmentSettings?.length ?? 0)

  const { inquiriesCount, isLoadingInquiriesCount, questionnaireTemplates } = useAssessmentQuestionnaireInquiriesCount(
    toggledOrganizationAssessmentSetting?.assessmentTemplate,
  )
  useEffect(() => {
    const isAssessmentActivatedAndThereAreNoInquiriesForLinkedQuestionnaires = Boolean(
      toggledOrganizationAssessmentSetting?.assessmentTemplate?.id &&
        !toggledOrganizationAssessmentSetting?.isActivated &&
        inquiriesCount === 0 &&
        !isLoadingInquiriesCount &&
        questionnaireTemplates.length,
    )
    if (isAssessmentActivatedAndThereAreNoInquiriesForLinkedQuestionnaires) {
      openActivationSuccessDialog()
    }
  }, [
    toggledOrganizationAssessmentSetting?.assessmentTemplate?.id,
    toggledOrganizationAssessmentSetting?.isActivated,
    inquiriesCount,
    isLoadingInquiriesCount,
    questionnaireTemplates.length,
  ])

  const onToggleActivation = useCallback(async (organizationAssessmentSettings: OrganizationAssessmentSettings) => {
    const newActivatedState = !organizationAssessmentSettings.isActivated

    await mutateAsync(
      {
        url: endpoints.organizationAssessmentSettings,
        body: {
          assessmentTemplateId: organizationAssessmentSettings.assessmentTemplate.id,
          isActivated: newActivatedState,
        },
      },
      {
        onSuccess: () => {
          setTimeout(() => setToggledOrganizationAssessmentSetting(organizationAssessmentSettings), 300)

          if (newActivatedState) {
            showSnackbar({
              message: formatMessage(
                { id: 'notifications.successfullyUpdatedAssessment' },
                { name: organizationAssessmentSettings.assessmentTemplate.name },
              ),
              severity: NotificationSeverity.success,
            })
          }
          trackEvent({
            name: AmplitudeTrackingEvents.Analyze.Assessment.Toggled,
            eventProps: {
              assessment_name: organizationAssessmentSettings.assessmentTemplate.name,
              assessment_id: organizationAssessmentSettings.assessmentTemplate.id,
              enabled: newActivatedState,
            },
          })

          queryClient.invalidateQueries(FetchKey.OrganizationAssessmentSettings)
          queryClient.invalidateQueries(FetchKey.Account)
        },
        onError: () => {
          showSnackbar({
            message: formatMessage({ id: 'notifications.toggleAssessmentSettingsFailed' }),
            severity: NotificationSeverity.error,
          })
        },
      },
    )
  }, [])

  return (
    <>
      <ConfigurationCollection enablePadding={{ top: false, right: true }}>
        <Table<OrganizationAssessmentSettings>
          HeaderComponent={AssessmentSettingsHeader}
          RowComponent={AssessmentSettingsRow}
          rowComponentProps={{ onToggleActivation: onToggleActivation }}
          count={pageCount}
          data={assessmentSettings}
          isLoading={isLoading}
          isError={isError}
          page={page}
          pageSize={pageSize}
          setPage={setPage}
          setPageSize={setPageSize}
        />
      </ConfigurationCollection>

      <Dialog
        open={isActivationSuccessDialogOpen}
        onClose={() => setToggledOrganizationAssessmentSetting(undefined)}
        title={formatMessage(
          { id: 'assessmentConfig.activationSuccessDialog.title' },
          { assessmentTitle: toggledOrganizationAssessmentSetting?.assessmentTemplate.name },
        )}
        content={formatMessage(
          { id: 'assessmentConfig.activationSuccessDialog.description' },
          { providerTypePlural: providerTypeNamePlural },
        )}
        buttons={[
          {
            label: formatMessage({ id: 'general.skip' }),
            variant: 'text',
            onClick: closeActivationSuccessDialog,
          },
          {
            label: formatMessage({ id: 'resourceCollections.general.createRequest' }),
            variant: 'outlined',
            onClick: () => openCreateRequestDialog(),
          },
          {
            label: formatMessage({ id: 'automatedSendout.create' }),
            variant: 'contained',
            onClick: () => openCreateAutomationDialog(),
          },
        ]}
      />
      <CreationModalProgressContextProvider>
        <CreateNewRequestModal
          open={isCreateRequestDialogOpen}
          onClose={closeCreateRequestDialog}
          reportType={ReportType.WF}
          disableInitialBack
          selectedQuestionnaireTemplates={questionnaireTemplates.filter(x => x.isStandardReport)}
        />
      </CreationModalProgressContextProvider>
      <CreationModalProgressContextProvider>
        <CreateAutomationModal
          open={isCreateAutomationDialogOpen}
          onClose={closeCreateAutomationDialog}
          selectedQuestionnaireTemplates={questionnaireTemplates.filter(x => x.isStandardReport)}
        />
      </CreationModalProgressContextProvider>
    </>
  )
}

export default AssessmentConfig
