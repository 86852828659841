import { TableCellWidth } from '@app/src/components/Table'
import TableHeaderCell from '@app/src/components/Table/Cells/TableHeaderCell'
import { TableRow } from '@mui/material'
import React from 'react'
import { useIntl } from 'react-intl'

interface ClaimsReviewHeaderProps {
  isHeaderChecked: boolean
  handleHeaderCheckboxChange: (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => void
}

const ClaimsReviewHeader: React.FC<ClaimsReviewHeaderProps> = ({ isHeaderChecked, handleHeaderCheckboxChange }) => {
  const { formatMessage } = useIntl()

  return (
    <TableRow>
      <TableHeaderCell
        label=""
        checkbox={{ checked: isHeaderChecked, onChange: handleHeaderCheckboxChange, color: 'primary' }}
        minWidth={TableCellWidth.EMPTY}
      />
      <TableHeaderCell label={formatMessage({ id: 'claim.claimId' })} />
      <TableHeaderCell label={formatMessage({ id: 'schemas.supplier.name' })} minWidth={TableCellWidth.LARGE} />
      <TableHeaderCell label={formatMessage({ id: 'claim.reviewReason' })} />
      <TableHeaderCell label={formatMessage({ id: 'schemas.supplier.country' })} />
      <TableHeaderCell label={formatMessage({ id: 'schemas.supplier.websiteAddress' })} />
      <TableHeaderCell label={formatMessage({ id: 'schemas.supplier.vatNumber' })} />
      <TableHeaderCell label={formatMessage({ id: 'schemas.organization.registrationNumber' })} />
      <TableHeaderCell label={formatMessage({ id: 'claim.referralCompanyName' })} />
      <TableHeaderCell label={formatMessage({ id: 'claim.creatorName' })} />
      <TableHeaderCell label={formatMessage({ id: 'claim.creatorEmail' })} />
      <TableHeaderCell label={formatMessage({ id: 'schemas.supplier.createdAt' })} />
    </TableRow>
  )
}

export default ClaimsReviewHeader
