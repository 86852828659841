import { useDrawer } from '@app/src/components/Drawer/DrawerContext'
import DrawerView from '@app/src/components/Drawer/DrawerView'
import Select from '@app/src/components/Form/Select/ControlledSelect'
import ControlledTextFieldWithFormatting from '@app/src/components/TextFieldWithFormatting/ControlledTextFieldWithFormatting'
import { useAmplitude } from '@app/src/context/AmplitudeContext'
import { BaseVerification, InternalVerificationStatus } from '@app/src/types/resourceExplorer'
import { AmplitudeTrackingEvents } from '@app/src/wf-constants'
import { VisibilityOffOutlined } from '@mui/icons-material'
import { Box, ListItem, ListItemAvatar, ListItemText, Stack } from '@mui/material'
import React, { useEffect } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useIntl } from 'react-intl'

interface DrawerViewContentProps {
  onFormSubmit: (formData: InternalVerificationFormData) => Promise<void>
  verification?: BaseVerification
  isLoading: boolean
  subtitle: string
  objectType: InternalVerificationObjectType
}

export enum InternalVerificationObjectType {
  ResponseItem = 'ResponseItem',
  Response = 'Response',
}

export interface InternalVerificationFormData {
  internalVerificationStatus: InternalVerificationStatus
  internalVerificationComment?: string
}

const DrawerViewInternalVerification: React.FC<DrawerViewContentProps> = ({
  onFormSubmit,
  verification,
  isLoading,
  subtitle,
  objectType,
}) => {
  const formMethods = useForm<InternalVerificationFormData>()
  const { control, watch, reset, handleSubmit } = formMethods
  const { formatMessage } = useIntl()
  const internalVerificationStatus: InternalVerificationStatus = watch('internalVerificationStatus')
  const { closeDrawer } = useDrawer()
  const { trackEvent } = useAmplitude()

  const internalVerificationComment = verification?.internalVerificationComment ?? ''

  useEffect(() => {
    trackEvent({
      name:
        objectType === InternalVerificationObjectType.ResponseItem
          ? AmplitudeTrackingEvents.Accessor.ResponseItemVerification.InternalVerification.Opened
          : AmplitudeTrackingEvents.Accessor.ResponseVerification.InternalVerification.Opened,
    })
    reset({
      internalVerificationComment: internalVerificationComment,
      internalVerificationStatus: verification?.internalVerificationStatus ?? InternalVerificationStatus.NotSet,
    })
  }, [verification])

  return (
    <FormProvider {...formMethods}>
      <DrawerView
        title={formatMessage({
          id: 'reporting.verification.internalReview',
        })}
        subTitle={subtitle}
        onFormSubmit={handleSubmit(onFormSubmit)}
        stackButtons
        buttons={[
          {
            label: formatMessage({
              id: 'general.cancel',
            }),
            variant: 'text',
            color: 'primary',
            onClick: closeDrawer,
            disabled: isLoading,
          },
          {
            label: formatMessage({ id: 'general.submit' }),
            variant: 'contained',
            color: 'primary',
            type: 'submit',
            loading: isLoading,
          },
        ]}
      >
        <Stack pt={2} px={2} flexGrow={1} justifyContent="space-between">
          <Box sx={{ mb: 2 }}>
            <Select<InternalVerificationStatus>
              name="internalVerificationStatus"
              control={control}
              fieldLabel={formatMessage({ id: 'general.selectStatus' })}
              variant="outlined"
              options={[
                {
                  value: InternalVerificationStatus.NotSet,
                  label: formatMessage({ id: 'reporting.internalVerificationStatuses.NotSet' }),
                },
                {
                  value: InternalVerificationStatus.Approved,
                  label: formatMessage({ id: 'reporting.internalVerificationStatuses.Approved' }),
                },
                {
                  value: InternalVerificationStatus.NotApproved,
                  label: formatMessage({ id: 'reporting.internalVerificationStatuses.NotApproved' }),
                },
              ]}
            />
          </Box>
          {internalVerificationStatus !== InternalVerificationStatus.NotSet && (
            <ControlledTextFieldWithFormatting
              name="internalVerificationComment"
              defaultValue={internalVerificationComment}
              control={control}
              placeholder={formatMessage({ id: 'reporting.verification.addOptionalMessage' })}
              multiline
              size="medium"
              hideUndo
              fillHeight
              maxLength={1000}
            />
          )}
          <ListItem disablePadding alignItems="flex-start">
            <ListItemAvatar sx={{ minWidth: 30 }}>
              <VisibilityOffOutlined fontSize="small" sx={{ color: 'text.secondary' }} />
            </ListItemAvatar>
            <ListItemText
              primaryTypographyProps={{ variant: 'body2', mt: 0.5, color: 'text.secondary' }}
              primary={formatMessage({ id: 'reporting.verification.internalVerificationDescription' })}
              sx={{ m: 0 }}
            />
          </ListItem>
        </Stack>
      </DrawerView>
    </FormProvider>
  )
}

export default DrawerViewInternalVerification
