import endpoints from '@app/src/api/endpoints'
import { usePatchUpdateResource } from '@app/src/api/updateHooks'
import Permissions, { usePermissions } from '@app/src/auth/permissions'
import { Option, OptionIcon } from '@app/src/components/Form/Select'
import Select from '@app/src/components/Form/Select/ControlledSelect'
import { FinalRiskRatingIcon } from '@app/src/components/Table/Cells/FinalRiskRatingCell'
import TextField from '@app/src/components/Ui/TextField'
import { useSnackbar } from '@app/src/context/SnackbarContext'
import useErrorNotification from '@app/src/hooks/errorNotification'
import { RequestAutomationType } from '@app/src/pages/Configurations/ConfigurationsPages/Automation/RequestAutomationType'
import RequestAutomationWarningAlert from '@app/src/pages/Configurations/ConfigurationsPages/Automation/Warning/RequestAutomationWarningAlert'
import { StandardCategoryOptions } from '@app/src/types/categories'
import { SortOrder } from '@app/src/types/filter'
import { GenericOrganization, Provider } from '@app/src/types/organizations'
import { Country, User } from '@app/src/types/resourceExplorer'
import { flatten, isValidVatOrRegistrationNumber } from '@app/src/utils/helpers'
import { NotificationSeverity } from '@app/src/wf-constants'
import { Alert, Stack } from '@mui/material'
import React, { Fragment } from 'react'
import { useForm } from 'react-hook-form'
import { useIntl } from 'react-intl'
import { useQueryClient } from 'react-query'
import { getOrganizationDisplayName } from '../../Table/Cells/CompanyCell'
import { useDrawer } from '../DrawerContext'
import DrawerView from '../DrawerView'

type DrawerViewOrganizationFormProps = {
  provider: Provider
}

const DrawerViewOrganizationForm: React.FC<DrawerViewOrganizationFormProps> = ({ provider }) => {
  const { showSnackbar } = useSnackbar()
  const { showErrorNotification } = useErrorNotification()
  const { closeDrawer } = useDrawer()

  const defaultValues = flatten(provider) as unknown as { [key: string]: string | number | Array<unknown> } //todo typing create a type for GenerigOrganizationForm?
  const { handleSubmit, control, formState, setError, register, setValue, errors } = useForm({
    mode: 'onChange',
    defaultValues,
  })

  const queryClient = useQueryClient()
  const { formatMessage } = useIntl()
  const { renderWithPermission, hasPermission } = usePermissions()

  const { mutate, isLoading } = usePatchUpdateResource<Partial<GenericOrganization>>()

  //todo typing: type useForm instead of unknown as ...
  const onSubmit = async (values: Partial<GenericOrganization>): Promise<void> => {
    mutate(
      {
        body: { ...values, name: values.name?.trim() },
        url: endpoints.provider(provider.id),
      },
      {
        onSuccess: () => {
          queryClient.invalidateQueries()
          showSnackbar({
            message: formatMessage({ id: 'notifications.successfulResourceSave' }),
            severity: NotificationSeverity.success,
            disableAutoClose: true,
          })
          closeDrawer()
        },
        onError: error => {
          if (error.isValidationError) {
            error.setFormValidationErrors(setError)
            return
          }
          showErrorNotification({ requestError: error, disableAutoClose: true })
        },
      },
    )
  }

  return (
    <DrawerView
      title={formatMessage({ id: 'general.categorize' })}
      subTitle={getOrganizationDisplayName(provider)}
      buttons={[
        {
          label: formatMessage({ id: 'reporting.submit' }),
          variant: 'contained',
          loading: isLoading,
          type: 'submit',
        },
      ]}
      onFormSubmit={handleSubmit(onSubmit)}
      disableScrolling
    >
      <RequestAutomationWarningAlert
        automationType={RequestAutomationType.FOR_PROVIDERS_IN_CATEGORY}
        sx={{ borderRadius: 0 }}
      />

      <Stack p={2} spacing={3} overflow="auto">
        {Object.keys(formState.errors)?.length > 0 && (
          <Alert elevation={1} variant="filled" severity="error">
            {formatMessage({ id: 'form.validation.error' })}
          </Alert>
        )}
        {!provider.organizationId && (
          <>
            <TextField
              hoveringLabel
              inputRef={register({
                required: formatMessage({ id: 'form.validation.required' }),
                minLength: {
                  value: 2,
                  message: formatMessage({ id: 'errorMessages.general.minLength' }, { count: 2 }),
                },
              })}
              fullWidth
              required
              name="name"
              label={formatMessage({ id: 'schemas.provider.name' })}
              error={Boolean(errors?.name)}
              type="text"
              helperText={errors?.name?.message}
              onClear={(): void => setValue('name', '')}
            />
            <Select<number, Country>
              hoveringLabel
              name="countryId"
              rules={{ required: 'This field is required' }}
              fieldLabel={formatMessage({ id: 'schemas.provider.country' })}
              control={control}
              required
              forceFetch
              enableAutoSelect
              navigation={{
                url: endpoints.countries,
                type: 'post',
                postObject: {
                  include: [],
                  filter: [],
                  sort: { target: 'name', order: SortOrder.ASCENDING },
                },
              }}
              objectToOption={(country: Country): Option<number> => {
                return {
                  label: country.name,
                  value: country.id,
                }
              }}
              error={errors?.countryId?.message}
            />
          </>
        )}
        <Select<number, User>
          hoveringLabel
          name="ownerUserId"
          fieldLabel={formatMessage({ id: 'schemas.organization.ownerUserId' })}
          control={control}
          forceFetch
          enableAutoSelect
          navigation={{
            url: endpoints.colleagues,
            type: 'post',
            postObject: {
              pagination: {
                itemsPerPage: 9999,
                pageNumber: 1,
              },
              include: [],
              filter: [],
            },
          }}
          objectToOption={(owner: User): Option<number> => ({
            label: owner.name,
            value: owner.id,
          })}
          error={errors?.ownerUserId}
        />
        {!hasPermission(Permissions.FINANCE_USER) && (
          <>
            <Select
              hoveringLabel
              name="activityStatus"
              fieldLabel={formatMessage({ id: 'schemas.provider.activityStatus' })}
              control={control}
              enableAutoSelect
              options={StandardCategoryOptions.providerActivityStatus.map(option => ({
                value: option,
                label: formatMessage({ id: `schemas.provider.activityStatusValues.${option}` }),
              }))}
              error={errors?.activityStatus}
            />

            <Select
              hoveringLabel
              name="providerApprovalStatus"
              fieldLabel={formatMessage({ id: 'schemas.provider.providerApprovalStatus' })}
              control={control}
              enableAutoSelect
              options={StandardCategoryOptions.providerApprovalStatus.map(option => ({
                value: option,
                label: formatMessage({ id: `schemas.provider.providerApprovalStatusValues.${option}` }),
              }))}
              error={errors?.providerApprovalStatus}
            />
          </>
        )}
        <Select
          hoveringLabel
          name="finalRiskRating"
          fieldLabel={formatMessage({ id: 'schemas.provider.finalRiskRating' })}
          control={control}
          enableAutoSelect
          options={StandardCategoryOptions.finalRiskRating.map(option => ({
            value: option,
            label: formatMessage({ id: `schemas.provider.finalRiskRatingValues.${option}` }),
          }))}
          error={errors?.finalRiskRating}
          renderOption={(props, option, { selected }): JSX.Element => (
            <li {...props}>
              <OptionIcon selected={selected} multiple />
              <FinalRiskRatingIcon value={option?.value} />
              {option?.label !== undefined
                ? formatMessage({ id: `schemas.provider.finalRiskRatingValues.${option.value}` })
                : ''}
            </li>
          )}
        />
        <Select
          hoveringLabel
          name="priority"
          fieldLabel={formatMessage({ id: 'schemas.provider.priority' })}
          control={control}
          enableAutoSelect
          options={StandardCategoryOptions.providerPriority.map(option => ({
            value: option,
            label: formatMessage({ id: `schemas.provider.priorityValues.${option}` }),
          }))}
          error={errors?.priority}
        />
        <TextField
          hoveringLabel
          inputRef={register({
            validate: (value: string) =>
              value === '' ||
              isValidVatOrRegistrationNumber(value) ||
              formatMessage({ id: 'notifications.errorInvalidVatNumber' }),
          })}
          fullWidth
          name="vatNumber"
          label={formatMessage({ id: 'schemas.organization.vatNumber' })}
          error={Boolean(errors?.vatNumber)}
          type="text"
          helperText={errors?.vatNumber?.message}
          onClear={(): void => setValue('vatNumber', '')}
        />
        <TextField
          hoveringLabel
          inputRef={register({
            validate: (value: string) =>
              value === '' ||
              isValidVatOrRegistrationNumber(value) ||
              formatMessage({ id: 'notifications.errorInvalidRegistrationNumber' }),
          })}
          fullWidth
          name="registrationNumber"
          label={formatMessage({ id: 'schemas.organization.registrationNumber' })}
          error={Boolean(errors?.registrationNumber)}
          type="text"
          helperText={errors?.registrationNumber?.message}
          onClear={(): void => setValue('registrationNumber', '')}
        />
        {renderWithPermission(
          {
            [Permissions.SOURCING_USER]: (
              <Fragment key="sourcing">
                <Select
                  hoveringLabel
                  name="tier"
                  fieldLabel={formatMessage({ id: 'schemas.supplier.tier' })}
                  control={control}
                  enableAutoSelect
                  options={Array.from(Array(9).keys()).map(i => ({
                    value: i,
                    label: formatMessage({ id: 'schemas.provider.tierValues' }, { tier: i }),
                  }))}
                  error={errors?.tier}
                />
                <TextField
                  hoveringLabel
                  inputRef={register}
                  fullWidth
                  name="duns"
                  label={formatMessage({ id: 'schemas.supplier.duns' })}
                  error={Boolean(errors?.duns)}
                  type="text"
                  helperText={errors?.duns?.message}
                  onClear={(): void => setValue('duns', '')}
                />
                <Select
                  hoveringLabel
                  name="supplierUsage"
                  fieldLabel={formatMessage({ id: 'schemas.supplier.supplierUsage' })}
                  control={control}
                  enableAutoSelect
                  options={[
                    { value: 'Direct', label: 'Direct' },
                    { value: 'Indirect', label: 'Indirect' },
                  ]}
                  error={errors?.supplierUsage}
                />
              </Fragment>
            ),
          },
          { multiple: true },
        )}
        <TextField
          hoveringLabel
          inputRef={register}
          fullWidth
          name="customId"
          label={formatMessage({ id: 'schemas.organization.customId' })}
          error={Boolean(errors?.customId)}
          type="text"
          helperText={errors?.customId?.message}
          onClear={(): void => setValue('customId', '')}
        />
      </Stack>
    </DrawerView>
  )
}

export default DrawerViewOrganizationForm
