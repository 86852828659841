import endpoints from '@app/src/api/endpoints'
import { FetchKey } from '@app/src/api/fetchHooks'
import { useUpdateResourceWithoutBody } from '@app/src/api/updateHooks'
import { useQueryClient } from 'react-query'
import { useHistory } from 'react-router-dom'

export const useUnsetReferral = (): (() => Promise<void>) => {
  const { mutateAsync: mutateUserReferralCode } = useUpdateResourceWithoutBody<{}>({ method: 'put' })
  const queryClient = useQueryClient()
  const history = useHistory()

  return async () => {
    await mutateUserReferralCode(
      { url: endpoints.unsetReferralCodeForUser },
      {
        onSuccess: async () => {
          await queryClient.invalidateQueries(FetchKey.Account)
        },
      },
    )

    //Redirect to homepage unset the referral parameter
    history.push('/')
  }
}

export const useSetReferral = (referralCode: string | null): (() => void) => {
  const { mutate: mutateUserReferralCode } = useUpdateResourceWithoutBody<{}>({ method: 'put' })
  const cleanedReferralCode = referralCode?.split(' ')[0]
  const queryClient = useQueryClient()
  const history = useHistory()
  return () => {
    mutateUserReferralCode(
      { url: endpoints.setReferralCodeForUser(cleanedReferralCode) },
      {
        onSuccess: async () => {
          await queryClient.invalidateQueries(FetchKey.Account)
        },
      },
    )
    history.push('/')
  }
}
