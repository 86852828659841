import { useDrawer } from '@app/src/components/Drawer/DrawerContext'
import DrawerViewImportSpend from '@app/src/components/Drawer/Views/DrawerViewImportSpend'
import useCurrentProviderTypeName from '@app/src/hooks/currentProviderTypeName'
import { ArrowForwardIosOutlined, EuroOutlined, UploadFileOutlined } from '@mui/icons-material'
import { Box, Divider, ListItemAvatar, ListItemButton, ListItemText, Tooltip } from '@mui/material'
import React from 'react'
import { useIntl } from 'react-intl'
import DrawerView, { DrawerViewProps } from '../DrawerView'

type DrawerViewImportInitialStepProps = {
  solution?: string
  providerType: string
  allowedFilters: string[]
  openImportProviderModal: () => void
  count: number
} & Omit<DrawerViewProps, 'title'>

const AVATAR_STYLING = {
  minWidth: 'unset',
  mr: 2,
  bgcolor: 'secondary.main',
  width: 40,
  height: 40,
  color: 'common.white',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
}

const DrawerViewImportInitialStep: React.FC<DrawerViewImportInitialStepProps> = ({
  solution,
  providerType,
  allowedFilters,
  openImportProviderModal,
  count,
  ...props
}) => {
  const { formatMessage } = useIntl()
  const resourceType = useCurrentProviderTypeName({ isPlural: true })
  const { openDrawer, closeDrawer } = useDrawer()

  return (
    <DrawerView title={formatMessage({ id: 'import.import' })} {...props}>
      <Box px={2} mt={2}>
        <Box>
          <ListItemButton
            onClick={() => {
              closeDrawer()
              openImportProviderModal()
            }}
          >
            <ListItemAvatar sx={{ ...AVATAR_STYLING }}>
              <UploadFileOutlined color="inherit" />
            </ListItemAvatar>
            <ListItemText
              primary={formatMessage(
                { id: 'import.providerExcel' },
                {
                  provider: resourceType,
                },
              )}
              secondary={formatMessage(
                { id: 'import.providerDescription' },
                {
                  provider: resourceType,
                },
              )}
            />
            <ArrowForwardIosOutlined />
          </ListItemButton>
        </Box>
        <Divider />
        <Box>
          <Tooltip
            arrow
            title={
              !count &&
              formatMessage(
                { id: 'import.noProvidersEmptyState' },
                {
                  provider: resourceType,
                },
              )
            }
            PopperProps={{
              modifiers: [
                {
                  name: 'offset',
                  options: {
                    offset: [0, -12],
                  },
                },
              ],
            }}
          >
            <Box>
              <ListItemButton
                onClick={() =>
                  openDrawer(<DrawerViewImportSpend providerType={providerType} allowedFilters={allowedFilters} />)
                }
                disabled={count === 0}
              >
                <ListItemAvatar sx={{ ...AVATAR_STYLING }}>
                  <EuroOutlined />
                </ListItemAvatar>
                <ListItemText
                  primary={formatMessage({ id: 'import.primaryText' })}
                  secondary={formatMessage({ id: 'import.secondaryText' })}
                />
                <ArrowForwardIosOutlined />
              </ListItemButton>
            </Box>
          </Tooltip>
        </Box>
      </Box>
    </DrawerView>
  )
}

export default DrawerViewImportInitialStep
