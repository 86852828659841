import { ActionButton, ActionButtonsProps } from '@app/src/components/ActionButtons'
import DrawerViewRiskAssessment from '@app/src/components/Drawer/Views/DrawerViewRiskAssessment'
import { insertIf } from '@app/src/utils/helpersTs'
import React, { useEffect, useMemo } from 'react'
import { useIntl } from 'react-intl'
import { AmplitudeTrackingPages } from '../wf-constants'

type UseCategorizeSelectionButtonsProps = {
  selectedRowsIds: number[]
  openUpdateDrawer: () => void
  openDrawer: (content: React.ReactNode) => void
  onButtonChange?: (buttons: ActionButtonsProps['buttons']) => void
  handleSelectAll: () => Promise<void>
  isAllSelected: () => boolean
  isLoadingAllRows: boolean
  pageSize: number
  count: number
}

const useCategorizeSelectionButtons = ({
  selectedRowsIds,
  openUpdateDrawer,
  openDrawer,
  onButtonChange,
  handleSelectAll,
  isAllSelected,
  isLoadingAllRows,
  pageSize,
  count,
}: UseCategorizeSelectionButtonsProps) => {
  const { formatMessage } = useIntl()

  const buttons = useMemo(
    () =>
      selectedRowsIds.length
        ? [
            {
              label: formatMessage({ id: 'resourceCollections.general.categorize' }, { count: selectedRowsIds.length }),
              onClick: openUpdateDrawer,
            },
            {
              label: formatMessage({ id: 'assessments.setYourRiskAssessment' }, { count: selectedRowsIds.length }),
              onClick: () =>
                openDrawer(
                  React.createElement(DrawerViewRiskAssessment, {
                    providerIds: selectedRowsIds,
                    pageOpenedFromName: AmplitudeTrackingPages.AssessmentComparePage,
                  }),
                ),
            },
            ...insertIf<ActionButton>(count > pageSize, {
              label: formatMessage({ id: 'resourceCollections.general.selectAll' }, { count }),
              variant: 'text',
              onClick: handleSelectAll,
              disabled: isAllSelected(),
              loading: isLoadingAllRows,
            }),
          ]
        : [],
    [selectedRowsIds.length, count, pageSize],
  )

  useEffect(() => {
    onButtonChange?.(buttons)
  }, [buttons])
}

export default useCategorizeSelectionButtons
