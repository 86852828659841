import Permissions from '@app/src/auth/permissions'
import { usePermissions } from '@app/src/auth/permissions/usePermissions'
import { ResourceTypes } from '@app/src/wf-constants'

type CurrentProviderType =
  | typeof ResourceTypes.Supplier
  | typeof ResourceTypes.Investee
  | typeof ResourceTypes.Company
  | typeof ResourceTypes.Provider

const useCurrentProviderType = (): CurrentProviderType => {
  const { getWithPermission } = usePermissions()

  const types: Record<string, CurrentProviderType> = {
    [Permissions.SOURCING_USER]: ResourceTypes.Supplier,
    [Permissions.INVESTMENT_USER]: ResourceTypes.Investee,
    [Permissions.FINANCE_USER]: ResourceTypes.Company,
    fallback: ResourceTypes.Provider,
  }

  return getWithPermission(types) ?? types.fallback
}

export default useCurrentProviderType
