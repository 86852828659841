// TODO: type this file
/* eslint-disable @typescript-eslint/explicit-function-return-type */
//@flow
/* eslint-disable @typescript-eslint/ban-ts-comment */
// @ts-nocheck
import _ from 'lodash'

export function capitalize(string) {
  if (typeof string !== 'string') {
    console.warn('capitalize(string) expects a string argument.')
    return string
  }
  return string.charAt(0).toUpperCase() + string.slice(1)
}

/**
 * Creates a function that can be passed into Array.map()
 * that will perform a search on every object in the array.
 *
 * Arguments:
 * filterKeys - array of strings with the keys representing the props to search in
 * searchText - search string
 * minSearchLength - minimum search length, defaults to 2
 *
 * The function passed into Array.map() returns:
 * - null for any item not matching the search string
 * - an object for any item matching the search string
 *  {
 *     item - the item searched
 *     match: {
 *        search - the search string
 *        keys - array of strings, which keys that matched the search string
 *     }
 *  }
 *
 * If searchText is falsy or too short the return value
 * for the map function will be { item, match: { } } for every item
 */

export const escapeRegExp = text => {
  return text.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&')
}

export const regExpSearchMapper = (filterKeys, searchText, minSearchLength = 2) => {
  const escapedText = escapeRegExp(searchText)

  function onArrayMap(item) {
    const searchRegExp = new RegExp(escapedText, 'i')
    if (!searchText || (minSearchLength && minSearchLength > 0 && searchText?.length < minSearchLength)) {
      return { item, match: {} }
    }
    const matchingKeys = filterKeys.filter(key => {
      let value
      if (typeof key === 'function') {
        value = key(item) || ''
      } else {
        value = _.get(item, key) || ''
      }
      if (!value) {
        return false
      }
      return (value + '').match(searchRegExp)
    })
    if (matchingKeys?.length === 0) {
      return null
    }
    return {
      item,
      match: {
        search: searchText,
        keys: matchingKeys,
      },
    }
  }
  return onArrayMap
}

export const uniq = array => {
  const uniqueSet = new Set(array)
  return [...uniqueSet]
}

export const addSpaceBetweenWords = text => {
  return `${text
    .replace(/([A-Z])/g, ' $1')
    .trim()
    .replace('_', ' ')}` // adds space before capital letters (in case of camelCase) and replaces _ with a space (in case of snake_case)
}

export const sortAlphabetically = (array, key) => {
  if (!array || !array?.length) {
    return []
  }

  if (typeof array[0] === 'object') {
    if (!key) {
      key = 'title'
    }
    return array.sort((a, b) => (a[key] > b[key] ? 1 : -1))
  } else {
    return array.sort((a, b) => (a > b ? 1 : -1))
  }
}

// adapted from http://jsfiddle.net/brandonscript/tfjH3/
export const flatten = obj => {
  const flattenedValues = {}
  obj &&
    Object.keys(obj).forEach(key => {
      if (obj[key] && typeof obj[key] !== 'undefined')
        flattenedValues[key] = typeof obj[key].id !== 'undefined' ? obj[key].id : obj[key]
    })
  return flattenedValues
}

export const formattedStringify = obj => {
  return JSON.stringify(obj, null, 2)
}

export const shuffleArray = array => {
  let currentIndex = array?.length,
    temporaryValue,
    randomIndex
  while (0 !== currentIndex) {
    randomIndex = Math.floor(Math.random() * currentIndex)
    currentIndex -= 1
    temporaryValue = array[currentIndex]
    array[currentIndex] = array[randomIndex]
    array[randomIndex] = temporaryValue
  }

  return array
}

export const validURL = str => {
  const pattern = new RegExp(
    '^(https?:\\/\\/)?' + // protocol
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
      '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
      '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
      '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
      '(\\#[-a-z\\d_]*)?$', // fragment locator
    'i',
  )
  return pattern.test(str)
}

export const isValidVatOrRegistrationNumber = str => {
  const pattern = /^(?=.*[0-9])[0-9a-zA-Z -_/]{5,30}$/
  return pattern.test(str)
}

export const numberIsSet = (value: number | null | undefined): value is number => {
  return value !== null && value !== undefined && !Number.isNaN(value)
}

/**
 * Replace spaces of a given text by a character.
 * @param textToFormat The text in which the spaces will be replaced
 * @param characterReplacingSpace The character replacing the spaces
 * @returns The formatted text
 */
export const toSnakeCase = (textToFormat: string | undefined) => {
  return textToFormat?.replace(/\s/g, '_')
}
