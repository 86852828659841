import endpoints from '@app/src/api/endpoints'
import { FetchKey, useFetchCollectionWithPost, useFetchResource } from '@app/src/api/fetchHooks'
import Card from '@app/src/components/Card'
import InvitationCard from '@app/src/components/Invitation/InvitationCard'
import ClaimDialog from '@app/src/components/Organization/ClaimDialog'
import WfLoader from '@app/src/components/WfLoader'
import { useAccessibleOrganizations } from '@app/src/context/AccessibleOrganizationsContext'
import { useAccount } from '@app/src/context/AccountContext'
import { useAuthentication } from '@app/src/context/AuthenticationContext'
import useLogin from '@app/src/hooks/login'
import { useDialogState } from '@app/src/hooks/mui-hooks'
import { useQueryParams } from '@app/src/hooks/queryParams'
import { useSetReferral } from '@app/src/hooks/referral'
import { Operators } from '@app/src/pages/ResourceCollection/Filters/useFilters'
import AddOrganizationCard from '@app/src/pages/SolutionSelector/AddOrganizationCard'
import NameAndTAndCDialog from '@app/src/pages/SolutionSelector/NameAndTAndCDialog'
import OrganizationCard from '@app/src/pages/SolutionSelector/OrganizationCard'
import { Claim } from '@app/src/types/organizations'
import { Invitation } from '@app/src/types/resourceExplorer'
import { shuffleArray } from '@app/src/utils/helpers'
import { br } from '@app/src/utils/translationMarkup'
import { NetworkImages } from '@app/src/wf-constants/networkImages'
import paths from '@app/src/wf-constants/paths'
import { Box, Button, Dialog, Grid, Paper, Skeleton, Theme, Typography } from '@mui/material'
import { makeStyles } from '@mui/styles'
import classNames from 'classnames'
import { default as React, useCallback, useEffect, useMemo, useState } from 'react'
import { useIntl } from 'react-intl'
import { generatePath, useHistory } from 'react-router-dom'
import OnboardingForm from './OnboardingForm'
import AcceptReferralDialog from './Referral/AcceptReferralDialog'

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    padding: theme.spacing(2),
    margin: 0,
    maxWidth: '100vw',
  },
  organizationsSection: {
    padding: theme.spacing(4),
  },
  card: {
    padding: theme.spacing(1, 2),
  },
  welcomeCard: {
    background: theme.palette.warning.dark,
  },
}))

export const pathToRedirect = (solution: string): string => {
  // TODO Add constants instead of strings when pascal/lowercase situation is more stable in the backend
  switch (solution?.toLowerCase()) {
    case 'transparency':
      return paths.transparencyDashboard
    case 'sourcing':
      return paths.sourcingDashboard
    case 'investing':
      return paths.investmentDashboard
    case 'finance':
      return paths.financeDashboard
    default:
      return generatePath(paths.configurations, { configurationsPage: 'users' })
  }
}

const SolutionSelectorScene = (): JSX.Element => {
  const { account } = useAccount()
  const { scope } = useAuthentication()
  const classes = useStyles()
  const history = useHistory()
  const logIn = useLogin()
  const [isLoadingState, setIsLoadingState] = useState(true)
  const { accessibleOrganizations, isLoading } = useAccessibleOrganizations()
  const [isClaimDialogOpen, openClaimDialog, closeClaimDialog] = useDialogState(false)
  const currentOrganizationId = Number(account?.organization?.id)
  const { formatMessage } = useIntl()
  const [isReferralDialogOpen, openReferralDialog, closeReferralDialog] = useDialogState(true)
  const shuffledNetworkImages = useMemo(() => shuffleArray(NetworkImages), [])
  const params = useQueryParams()
  const redirectTo = params.get('redirectTo')
  const solutionParam = params.get('solution')
  const organizationIdParam = parseInt(params.get('organizationId') ?? '')

  const paramReferral = params.get('referral')
  const setReferral = useSetReferral(paramReferral)
  const referralCode = (account?.referralCode || paramReferral) ?? undefined

  const { data: pendingClaims, isLoading: isPendingClaimsLoading } = useFetchResource<Claim[]>({
    endpoint: endpoints.claims,
    key: FetchKey.Claim,
    showSnackbarOn404: false,
  })

  const {
    items: pendingInvites,
    count: pendingInvitesCount,
    isLoading: isPendingInvitesLoading,
  } = useFetchCollectionWithPost<Invitation>({
    endpoint: endpoints.invitations,
    key: FetchKey.Invitation,
    showSnackbarOn404: false,
    payload: {
      filter: [
        {
          name: 'forMe',
          filters: [
            {
              value: true,
              operator: Operators.EqualTo,
            },
          ],
        },
        {
          name: 'status',
          filters: [
            {
              value: ['Opened', 'Sent'],
              operator: Operators.In,
            },
          ],
        },
      ],
      include: ['creatorOrganization.image'],
    },
  })

  useEffect(() => {
    const noAutoLoginParameters =
      !solutionParam ||
      !organizationIdParam ||
      (organizationIdParam === currentOrganizationId && scope.solution === solutionParam)

    if (noAutoLoginParameters) return

    const organization = accessibleOrganizations.find(
      accessibleOrganization => accessibleOrganization.organization.id === organizationIdParam,
    )

    if (!organization) return

    const solution = organization.solution.find(
      solution => solution.solution.toLowerCase() === solutionParam.toLowerCase(),
    )

    if (!solution) return

    logIn(solution.role, solutionParam, organizationIdParam, redirect)
  }, [solutionParam, organizationIdParam, scope, accessibleOrganizations])

  const redirect: (solution: string) => void = useCallback(
    solution => {
      if (redirectTo) {
        history.push(redirectTo)
      } else {
        history.push(pathToRedirect(solution))
      }
    },
    [history, redirectTo],
  )

  useEffect(() => {
    if (!account?.referralCode && paramReferral !== null) {
      setReferral()
    }
  }, [account?.referralCode, paramReferral])

  useEffect(() => {
    if (currentOrganizationId) {
      setIsLoadingState(false)
      return
    }
    if (isLoading || isPendingClaimsLoading || isPendingInvitesLoading) return

    const referralCodeIsSet = account?.referralCode || Boolean(paramReferral)
    if (referralCodeIsSet) {
      setIsLoadingState(false)
      return
    }

    const singleAccessibleSolution =
      accessibleOrganizations?.length === 1 && accessibleOrganizations[0].solution.length === 1

    const hasPendingInvitesOrClaims = Boolean(pendingInvitesCount) || Boolean(pendingClaims?.length)

    if (!singleAccessibleSolution || hasPendingInvitesOrClaims) {
      setIsLoadingState(false)
      return
    }

    logIn(
      accessibleOrganizations[0].solution[0].role,
      accessibleOrganizations[0].solution[0].solution,
      accessibleOrganizations[0].organization.id,
      redirect,
    )
  }, [
    isLoading,
    accessibleOrganizations,
    isPendingInvitesLoading,
    isPendingClaimsLoading,
    pendingInvitesCount,
    pendingClaims,
    currentOrganizationId,
    account?.referralCode,
    paramReferral,
    setIsLoadingState,
  ])

  if (!account || isLoadingState) {
    return <WfLoader />
  }

  const showPlaceholder =
    Boolean(isLoading) ||
    (!isLoading && !accessibleOrganizations?.length && !pendingInvites?.length && isPendingClaimsLoading) // shows the loader for pending claims only if there is no accessible organizatio nor pending invites

  return (
    <Grid container spacing={5} justifyContent="center" data-testid="solution-selector" className={classes.container}>
      <Grid item md={3} xs={12}>
        <Grid container spacing={5}>
          <Grid item xs={12}>
            <Card
              title={formatMessage(
                { id: 'solutionSelector.headerName' },
                { name: account?.user?.givenName || account?.user?.name },
              )}
              description={formatMessage({ id: 'solutionSelector.description' }, { br })}
              extraClasses={classNames([classes.welcomeCard, classes.card])}
            />
          </Grid>
          <Grid item xs={12}>
            <Card
              title={formatMessage({ id: 'solutionSelector.callToAction.title' })}
              titleVariant="h5"
              description={formatMessage(
                { id: 'solutionSelector.callToAction.description' },
                { b: (chunks: React.ReactNode) => <b>{chunks}</b>, br: br },
              )}
              extraClasses={classes.card}
              closeable
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item md={9} xs={12}>
        <Paper elevation={1} className={classes.organizationsSection}>
          <Grid container spacing={5}>
            <Grid item xs={12}>
              <Box display="flex" justifyContent="space-between" mb={3}>
                <Typography variant="h2" data-intercom-target="select-your-solutions">
                  {formatMessage({ id: 'solutionSelector.accessYourSolutions' })}
                </Typography>
                <Button onClick={openClaimDialog} variant="outlined">
                  {formatMessage({ id: 'claim.addOrganization' })}
                </Button>
              </Box>
            </Grid>
            {showPlaceholder ? (
              <Grid item xl={3} lg={4} sm={6} xs={12}>
                <Box height={530}>
                  <Skeleton height={530} sx={{ transform: 'unset' }} />
                </Box>
              </Grid>
            ) : (
              <>
                {accessibleOrganizations?.map(({ organization, solution }, index) =>
                  !organization ? null : (
                    <Grid item xl={3} lg={4} sm={6} xs={12} key={organization.id}>
                      <OrganizationCard
                        backgroundImage={shuffledNetworkImages[index + 5]}
                        organization={organization}
                        solutions={solution}
                        currentSolution={organization.id === currentOrganizationId ? scope.solution : undefined}
                        redirect={redirect}
                      />
                    </Grid>
                  ),
                )}
                {Boolean(pendingInvitesCount) &&
                  pendingInvites?.map((invitation: Invitation, index) => (
                    <Grid item xl={3} lg={4} sm={6} xs={12} key={invitation.id}>
                      <InvitationCard invitation={invitation} backgroundImage={shuffledNetworkImages[index + 10]} />
                    </Grid>
                  ))}
                {Boolean(pendingClaims?.length) && (
                  <>
                    {pendingClaims?.map((claim, index) => (
                      <Grid item xl={3} lg={4} sm={6} xs={12} key={claim.id}>
                        <OrganizationCard
                          backgroundImage={shuffledNetworkImages[index + 15]}
                          organization={{
                            name: claim.details.name,
                            id: 0,
                            image: { name: claim.details.name, url: claim.details.logo },
                          }}
                        />
                      </Grid>
                    ))}
                  </>
                )}
                <AddOrganizationCard openDrawer={openClaimDialog} />
              </>
            )}
          </Grid>
        </Paper>
      </Grid>
      {account?.user?.hasAcceptedTAndC && referralCode && (
        <AcceptReferralDialog
          open={isReferralDialogOpen}
          referralCode={referralCode.split(' ')[0]}
          closeDialog={closeReferralDialog}
          openClaimDialog={openClaimDialog}
        />
      )}

      <Dialog
        open={!account?.user?.hasAcceptedTAndC}
        fullScreen
        transitionDuration={0}
        PaperProps={{ sx: theme => ({ background: theme.palette.grey[50] }) }}
      >
        <OnboardingForm
          header={formatMessage({ id: 'TAndCDialog.title' })}
          subheader={formatMessage({ id: 'TAndCDialog.description' })}
        >
          <NameAndTAndCDialog account={account} />
        </OnboardingForm>
      </Dialog>
      <ClaimDialog
        closeDialog={closeClaimDialog}
        openReferralDialog={openReferralDialog}
        isDialogOpen={isClaimDialogOpen}
      />
    </Grid>
  )
}

export default SolutionSelectorScene
