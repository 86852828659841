import Permissions, { usePermissions } from '@app/src/auth/permissions'
import ParentRowOrFragment from '@app/src/components/Skeleton/ParentRowOrFragment'
import AnswerWithClassificationCell from '@app/src/components/Table/Cells/AnswerWithClassificationCell'
import AnswerWithFlagCell from '@app/src/components/Table/Cells/AnswerWithFlagCell'
import DateCell from '@app/src/components/Table/Cells/DateCell'
import ExternalVerificationStatusCell from '@app/src/components/Table/Cells/ExternalVerificationStatusCell'
import PeriodNameCell from '@app/src/components/Table/Cells/PeriodNameCell'
import RequestCell from '@app/src/components/Table/Cells/RequestCell'
import StatusCell from '@app/src/components/Table/Cells/StatusCell'
import ValueCell from '@app/src/components/Table/Cells/ValueCell'
import { ExternalVerificationStatus, ResponseItem } from '@app/src/types/resourceExplorer'
import { reporting } from '@app/src/wf-constants/paths'
import { alpha } from '@mui/material'
import markdownToTxt from 'markdown-to-txt'
import qs from 'qs'
import React from 'react'
import { useIntl } from 'react-intl'
import { useHistory } from 'react-router'

export interface ResponseItemRowProps {
  row: ResponseItem
  disableTableRow?: boolean
  showAnswerClassificationResults?: boolean
}

export const ResponseItemRow = ({
  row: responseItem,
  disableTableRow,
  showAnswerClassificationResults,
}: ResponseItemRowProps): JSX.Element => {
  const { hasPermission } = usePermissions()
  const history = useHistory()
  const verificationPermitted = hasPermission(Permissions.WRITE_VERIFICATION)
  const { formatMessage } = useIntl()

  return (
    <ParentRowOrFragment disableTableRow={disableTableRow}>
      <ValueCell value={responseItem.requestItem.description} />
      {showAnswerClassificationResults ? (
        <AnswerWithClassificationCell item={responseItem} />
      ) : (
        <AnswerWithFlagCell item={responseItem} />
      )}
      {responseItem.comment ? (
        <ValueCell value={responseItem.comment} />
      ) : (
        <ValueCell
          color={({ palette }) => alpha(palette.common.black, 0.38)}
          value={formatMessage({ id: 'reporting.skipQuestion.noComment' })}
        />
      )}
      <PeriodNameCell periodName={responseItem.response.request.periodName} />
      <RequestCell
        title={responseItem.response.request.title}
        imageUrl={responseItem.response.request.template?.image?.url}
        onClick={() =>
          history.push({
            pathname: reporting,
            search: qs.stringify({ requestId: responseItem.response.request.id, responseId: responseItem.response.id }),
          })
        }
      />
      {verificationPermitted && (
        <>
          <ExternalVerificationStatusCell
            responseStatus={
              responseItem?.verifications?.[0]?.externalVerificationStatus ?? ExternalVerificationStatus.NotSet
            }
            draftStatus={responseItem?.response?.draftStatus}
          />
          <ValueCell value={markdownToTxt(responseItem?.verifications?.[0]?.externalVerificationComment || '')} />
          <StatusCell verification value={responseItem?.verifications?.[0]?.internalVerificationStatus} />
          <ValueCell value={markdownToTxt(responseItem?.verifications?.[0]?.internalVerificationComment || '')} />
        </>
      )}
      <DateCell value={responseItem.response.submittedAt} />
    </ParentRowOrFragment>
  )
}

export default ResponseItemRow
