import React, { createContext, useContext } from 'react'
import { AnswersGroupingType } from '../pages/ResourceCollection/Filters/StatisticsConstants'

export interface GroupBySelectorContextProps {
  groupBy: AnswersGroupingType
  setGroupBy: React.Dispatch<React.SetStateAction<AnswersGroupingType>>
}

const GroupBySelectorContext = createContext<undefined | GroupBySelectorContextProps>(undefined)

export const useGroupBySelector = (): GroupBySelectorContextProps => {
  const context = useContext(GroupBySelectorContext)
  if (context === undefined) {
    throw new Error('useGroupBySelector must be used within CreationModalProgressContextProvider')
  }
  return context
}

export default GroupBySelectorContext
