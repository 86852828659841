import { InputLabel, Stack, Typography } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { Editor } from '@tiptap/core'
import classNames from 'classnames'
import { marked } from 'marked'
import { LinkBubbleMenu, RichTextEditor } from 'mui-tiptap'
import React, { useEffect, useRef, useState } from 'react'
import { useIntl } from 'react-intl'
import TurndownService from 'turndown'
import CustomToolbar from './CustomToolbar'
import useExtensions from './useExtensions'

const turndownService = new TurndownService()

turndownService.addRule('strikethrough', {
  filter: ['s', 'del'],
  replacement: content => `~~${content}~~`,
})

const useStyles = makeStyles(({ spacing, palette }) => ({
  // Using makeStyles because the RichTextEditor component doesn't allow for a sx prop
  input: {
    '& .ProseMirror': {
      paddingLeft: spacing(2),
      '& a': {
        color: `${palette.visualization[3]} !important`,
      },
    },
  },
  inputFillHeight: {
    height: '100%',
  },
}))

export type TextFieldWithFormattingProps = {
  value?: string
  onChange?: (value: string) => void
  placeholder?: string
  label?: string
  error?: boolean
  helperText?: string
  multiline?: boolean
  maxLength?: number
  size?: 'small' | 'medium'
  hideUndo?: boolean
  fillHeight?: boolean
}

function TextFieldWithFormatting({
  value = '',
  onChange,
  placeholder,
  label,
  error: errorProp = false,
  helperText: helperTextProp = '',
  multiline,
  maxLength,
  size = 'medium',
  hideUndo,
  fillHeight,
}: TextFieldWithFormattingProps) {
  const classes = useStyles()
  const { formatMessage } = useIntl()
  const editorRef = useRef<HTMLDivElement | null>(null)
  const [error, setError] = useState(false)
  const [content, setContent] = useState(value)
  const [helperText, setHelperText] = useState('')
  const [charCount, setCharCount] = useState(value.length)
  const extensions = useExtensions({
    placeholder,
  })

  const id = 'rich-text-input'

  const handleUpdate = (editor: Editor) => {
    const text = editor.getText()
    const length = text.length

    if (maxLength && length > maxLength) {
      setError(true)
      setHelperText(formatMessage({ id: 'form.validation.charLimit' }, { maxLength }))
      editor.commands.setContent(content)
    } else {
      setCharCount(length)
      setContent(editor.getHTML())
      setHelperText('')
      setError(false)
      onChange?.(turndownService.turndown(editor.getHTML()))
    }
  }

  useEffect(() => {
    setError(errorProp)
  }, [errorProp])

  useEffect(() => {
    setHelperText(helperTextProp)
  }, [helperTextProp])

  useEffect(() => {
    // Add the id to the ProseMirror div for accessibility since the RichTextEditor component doesn't allow for an id prop
    if (editorRef.current) {
      const proseMirror = editorRef.current.querySelector('.ProseMirror')
      if (proseMirror) {
        proseMirror.setAttribute('id', id)
      }
    }
  }, [])

  return (
    <Stack sx={fillHeight ? { height: '100%' } : {}}>
      {label && (
        <InputLabel
          htmlFor={id}
          sx={({ typography }) => ({
            mb: size === 'small' ? 0.5 : 1,
            color: error ? 'error.dark' : 'text.secondary',
            fontSize: size === 'small' ? typography.body2.fontSize : typography.body1.fontSize,
          })}
        >
          {label}
        </InputLabel>
      )}
      <Stack
        ref={editorRef}
        bgcolor="grey.200"
        justifyContent="space-between"
        sx={{
          // TipTap only supports outlined variant textfield so we need to add this styling to make it look like a standard variant
          minHeight: multiline ? 160 : 0,
          borderRadius: '4px 4px 0 0',
          borderBottom: ({ palette }) => `1px solid ${error ? palette.error.main : palette.grey[500]}`,
          transition: 'background-color 0.3s, border-bottom 0.1s',
          '&:hover': {
            backgroundColor: 'grey.300',
            borderBottom: ({ palette }) => `1px solid ${error ? palette.error.dark : palette.grey[900]}`,
          },
          '&:focus-within': {
            backgroundColor: 'grey.200',
            borderBottom: ({ palette }) => `2px solid ${error ? palette.error.dark : palette.grey[900]}`,
          },
          '& .MuiTiptap-MenuBar-root': {
            background: 'transparent',
            border: 'none',
          },
          '& .MuiTiptap-RichTextField-content': {
            padding: '0px 0px 8px 0px !important',
          },
          height: fillHeight ? '100%' : undefined,
        }}
        onClick={e => {
          const editorElement = e.currentTarget.querySelector('.ProseMirror')
          if (editorElement) (editorElement as HTMLElement).focus()
        }}
      >
        <RichTextEditor
          content={marked(value)}
          onUpdate={({ editor }) => handleUpdate(editor)}
          extensions={extensions}
          renderControls={() => <CustomToolbar hideUndo={hideUndo} />}
          RichTextFieldProps={{
            variant: 'standard',
            className: classNames(classes.input, { [classes.inputFillHeight]: fillHeight }),
          }}
        >
          {() => <LinkBubbleMenu placement="bottom-start" PaperProps={{ onClick: e => e.stopPropagation() }} />}
        </RichTextEditor>
      </Stack>
      {maxLength && !helperText && (
        <Typography variant="body2" color="text.secondary">
          {charCount} / {maxLength}
        </Typography>
      )}
      {helperText && (
        <Typography variant="body2" sx={{ p: 1, color: error ? 'error.dark' : 'text.secondary' }}>
          {helperText}
        </Typography>
      )}
    </Stack>
  )
}

export default TextFieldWithFormatting
