import { FileCopyOutlined } from '@mui/icons-material'
import { Alert, Box, Stack, Typography } from '@mui/material'
import endpoints from '@app/src/api/endpoints'
import { useUpdateResourceWithoutBody } from '@app/src/api/updateHooks'
import { startCase } from 'lodash'
import React from 'react'
import { useIntl } from 'react-intl'
import { Referral } from '@app/src/types/resourceExplorer'
import { plural } from '@app/src/utils/translationMarkup'
import DrawerView, { DrawerViewProps } from '../DrawerView'

type DrawerViewAddProviderReferralProps = {
  providerType: string
} & Omit<DrawerViewProps, 'title'>

export function getReferralUrl(code: string): string {
  return `${window.location.origin}?referral=${code}`
}

const DrawerViewAddProviderReferral: React.FC<DrawerViewAddProviderReferralProps> = ({ providerType, ...props }) => {
  const { formatMessage } = useIntl()
  const {
    mutateAsync,
    isLoading,
    data: referralData,
  } = useUpdateResourceWithoutBody<Referral>({
    method: 'post',
  })

  const handleCreateReferralLink = async () => {
    const data = await mutateAsync({
      url: endpoints.generateReferralCode,
    })

    await navigator.clipboard.writeText(getReferralUrl(data.code))
  }

  const providerTypeNameSingular = formatMessage(
    { id: `general.${startCase(providerType)}` },
    { count: 1 },
  ).toLowerCase()
  const providerTypeNamePlural = formatMessage({ id: `general.${startCase(providerType)}` }, plural).toLowerCase()

  return (
    <DrawerView
      title={formatMessage({ id: `resourceCollections.invite.${providerType.toLowerCase()}` })}
      buttons={[
        {
          label: formatMessage({ id: 'resourceCollections.invite.dialog.buttonInvite' }),
          variant: 'contained',
          endIcon: <FileCopyOutlined />,
          onClick: handleCreateReferralLink,
          loading: isLoading,
        },
      ]}
      {...props}
    >
      <Stack px={2}>
        <Typography variant="body1" color="textSecondary" paragraph>
          {formatMessage(
            { id: 'resourceCollections.invite.dialog.description1' },
            { providerType: providerTypeNamePlural },
          )}
        </Typography>

        <Typography variant="body1" color="textSecondary" paragraph>
          {formatMessage(
            { id: 'resourceCollections.invite.dialog.description3' },
            { providerType: providerTypeNameSingular, providerTypePlural: providerTypeNamePlural },
          )}
        </Typography>

        {referralData && (
          <Box mb={2} data-testid="message-box" alignSelf="flex-end">
            <Alert severity="success">
              {formatMessage(
                { id: 'resourceCollections.invite.dialog.successNotification' },
                { linkUrl: getReferralUrl(referralData.code) },
              )}
            </Alert>
          </Box>
        )}
      </Stack>
    </DrawerView>
  )
}

export default DrawerViewAddProviderReferral
