import { Periods } from '@app/src/components/CreateNewRequestModal/PeriodSelector/PeriodSelector'
import { makeStyles } from '@mui/styles'
import React, { useEffect, useMemo } from 'react'

import endpoints from '@app/src/api/endpoints'
import { FetchKey, useFetchCollectionWithPost } from '@app/src/api/fetchHooks'
import { useCreateResource } from '@app/src/api/updateHooks'
import CreationModalContainer from '@app/src/components/CreationModal/CreationModalContainer'
import ControlledDateField from '@app/src/components/Form/ControlledDateField'
import { Option } from '@app/src/components/Form/Select'
import Select from '@app/src/components/Form/Select/ControlledSelect'
import { useCreationModalProgress } from '@app/src/context/CreationModalProgressContext'
import useErrorNotification from '@app/src/hooks/errorNotification'
import { Operators } from '@app/src/pages/ResourceCollection/Filters/useFilters'
import { SortOrder } from '@app/src/types/filter'
import { Provider } from '@app/src/types/organizations'
import { QuestionnaireTemplate, SendRequestResult } from '@app/src/types/resourceExplorer'
import { useYearsFromCurrentReportingPeriod } from '@app/src/utils'
import { Alert } from '@mui/material'
import { FormProvider, useForm } from 'react-hook-form'
import { useIntl } from 'react-intl'
import { BaseFormData } from '..'
import RequestQuestionStep from '../RequestQuestionStep'
import EdciStep from './EdciStep'
import ReviewTable from './ReviewTable'

const useStyles = makeStyles(({ spacing }) => ({
  infoAlert: {
    marginTop: spacing(2),
  },
}))

type EdciReportProps = {
  onClose: () => void
  onBack: () => void
}

export type QuestionStepData = {
  [key: string]: string
}

export enum EDCI_REPORT_QUESTION_KEYS {
  TEMPLATE_IDS = 'templateIds',
  PERIOD_NAME = 'dateYear',
  RESPONDERS = 'responders',
  DEADLINE = 'deadline',
  COMPANY_ID = 'companyId',
  FUND_ID = 'fundId',
  GP_OWNERSHIP = 'percentageGpOwnership',
  FUND_OWNERSHIP = 'percentageFundOwnership',
  INVESTMENT_YEAR = 'initialInvestmentYear',
  COMPANY_STRUCTURE = 'companyStructure',
  GROWTH_STAGE = 'growthStage',
  REVIEW = 'review',
  ACTIVATE_AT = 'activateAt',
}

type EdciTypes = {
  providerOrganizationId: number
  year: string
  companyId: string
  fundId: string
  percentageGpOwnership: number
  percentageFundOwnership: number
  initialInvestmentYear: string
  companyStructure: CompanyStructureType
  growthStage: GrowthStageType
}

export type CompanyStructureType = 'Private' | 'Public'
export type GrowthStageType = 'Venture' | 'Growth' | 'Buyout'

export type FormData = Omit<BaseFormData, 'responders'> & {
  responders: { id: number; name: string; organizationId: number }
} & EdciTypes

const EdciReport: React.FC<EdciReportProps> = ({ onClose, onBack }) => {
  const { formatMessage } = useIntl()
  const { activeStep, setTotalSteps } = useCreationModalProgress()
  const classes = useStyles()
  const { mutateAsync: createEdciConfiguration } = useCreateResource<SendRequestResult, unknown>()
  const { showErrorNotification } = useErrorNotification()

  const { items: edciTemplates } = useFetchCollectionWithPost<QuestionnaireTemplate>({
    endpoint: endpoints.requestTemplates,
    key: FetchKey.Request,
    payload: {
      filter: [
        {
          name: 'tags.tag',
          filters: [
            {
              value: ['edci'],
              operator: Operators.In,
            },
          ],
        },
      ],
      include: [],
      pagination: {
        pageNumber: 1,
        itemsPerPage: 1,
      },
      sort: { target: 'title', order: SortOrder.ASCENDING },
    },
  })

  const formMethods = useForm<FormData>({
    shouldUnregister: false,
    defaultValues: {
      dateType: Periods.Yearly,
    },
  })
  const { control, getValues, setValue } = formMethods

  const { years, currentReportingPeriod } = useYearsFromCurrentReportingPeriod()

  const getYearOptionLabel = (year: number) => {
    const isLastYear = year === currentReportingPeriod
    return `${String(year)}${isLastYear ? ` (${formatMessage({ id: `form.createRequest.lastReportingPeriod` })})` : ''}`
  }

  const DATE_YEAR_OPTIONS = useMemo(
    () =>
      years.map(y => ({
        value: y,
        label: getYearOptionLabel(y),
      })),
    [],
  )

  const EDCI_REPORT_QUESTIONS = [
    {
      title: formatMessage({ id: 'form.createRequest.selectPeriodTitle' }),
      description: formatMessage({ id: 'form.createRequest.selectPeriodBody' }),
      fieldnames: [EDCI_REPORT_QUESTION_KEYS.PERIOD_NAME],
      children: (
        <Select
          options={DATE_YEAR_OPTIONS}
          fieldLabel={formatMessage({ id: 'form.createRequest.selectPeriodLabel' })}
          onInputChange={(e, value) => {
            if (!value) setValue(EDCI_REPORT_QUESTION_KEYS.PERIOD_NAME, undefined)
          }}
          defaultValue={-1}
          required
          enableAutoSelect
          name={EDCI_REPORT_QUESTION_KEYS.PERIOD_NAME}
          control={control}
        />
      ),
    },
    {
      title: formatMessage({ id: 'form.createRequest.selectCompanyTitle' }),
      description: formatMessage({ id: 'form.createRequest.selectCompanyBody' }),
      fieldnames: [EDCI_REPORT_QUESTION_KEYS.RESPONDERS],
      children: (
        <Select<Partial<Provider>, Provider>
          name={EDCI_REPORT_QUESTION_KEYS.RESPONDERS}
          fieldLabel={formatMessage({ id: 'form.createRequest.selectCompanyLabel' })}
          control={control}
          required
          enableAutoSelect
          enableSelectAll
          forceFetch
          defaultValue={null}
          navigation={{
            url: endpoints.providersCollection,
            type: 'post',
            postObject: {
              include: [],
              filter: [
                {
                  name: 'linked',
                  filters: [
                    {
                      value: 'true',
                      operator: Operators.EqualTo,
                    },
                  ],
                },
              ],
            },
          }}
          objectToOption={(responderObject: Provider): Option<Partial<Provider>> => ({
            label: responderObject.name,
            value: {
              name: responderObject.name,
              id: responderObject.id,
              organizationId: responderObject.organizationId,
            },
          })}
          findSelectedValue={(value, option): boolean => value?.name === option?.value?.name}
        />
      ),
    },
    {
      title: formatMessage({ id: 'form.createRequest.selectDeadlineTitle' }),
      description: formatMessage({ id: 'form.createRequest.selectDeadlineBody' }),
      fieldnames: [EDCI_REPORT_QUESTION_KEYS.DEADLINE],
      hasSkipButton: true,
      children: (
        <ControlledDateField
          disablePast
          fieldLabel={formatMessage({ id: 'form.createRequest.selectDeadlineLabel' })}
          control={control}
          name={EDCI_REPORT_QUESTION_KEYS.DEADLINE}
          slotProps={{ textField: { fullWidth: true } }}
        />
      ),
    },
    {
      title: formatMessage({ id: 'form.createRequest.edci.title' }),
      description: formatMessage({ id: 'form.createRequest.edci.body' }),
      fieldnames: [
        EDCI_REPORT_QUESTION_KEYS.COMPANY_ID,
        EDCI_REPORT_QUESTION_KEYS.COMPANY_STRUCTURE,
        EDCI_REPORT_QUESTION_KEYS.FUND_ID,
        EDCI_REPORT_QUESTION_KEYS.FUND_OWNERSHIP,
        EDCI_REPORT_QUESTION_KEYS.GP_OWNERSHIP,
        EDCI_REPORT_QUESTION_KEYS.GROWTH_STAGE,
        EDCI_REPORT_QUESTION_KEYS.INVESTMENT_YEAR,
      ],
      hasSkipButton: false,
      children: <EdciStep />,
    },
    {
      title: formatMessage({ id: 'form.createRequest.reviewTitle' }),
      description: (
        <Alert severity="info" className={classes.infoAlert}>
          {formatMessage({ id: 'form.createRequest.reviewInfo' })}
        </Alert>
      ),
      fieldnames: [],
      children: <ReviewTable data={getValues()} />,
    },
  ]

  const activeQuestion = useMemo(() => EDCI_REPORT_QUESTIONS[activeStep - 1], [activeStep])

  const handleSubmitEdci = async (values: FormData) => {
    const body: EdciTypes = {
      providerOrganizationId: values.responders.organizationId,
      year: (values.dateYear ?? '').toString(),
      companyId: values.companyId,
      fundId: values.fundId,
      percentageGpOwnership: Number(values.percentageGpOwnership / 100),
      percentageFundOwnership: Number(values.percentageFundOwnership / 100),
      initialInvestmentYear: values.initialInvestmentYear.toString(),
      companyStructure: values.companyStructure,
      growthStage: values.growthStage,
    }

    await createEdciConfiguration(
      { url: endpoints.saveEdciProviderConfiguration, body },
      {
        onError: error => {
          showErrorNotification({ requestError: error })
        },
      },
    )
  }

  useEffect(() => {
    setTotalSteps(EDCI_REPORT_QUESTIONS.length)
  }, [])

  useEffect(() => {
    const edciRequest = edciTemplates?.[0]

    if (edciRequest) {
      setValue(EDCI_REPORT_QUESTION_KEYS.TEMPLATE_IDS, [{ id: edciRequest.id, title: edciRequest.title }])
    }
  }, [edciTemplates])

  return (
    <CreationModalContainer
      title={formatMessage({ id: 'form.createRequest.edci.modalTitle' })}
      onClose={onClose}
      overflow="visible"
    >
      <FormProvider {...formMethods}>
        {activeQuestion && (
          <RequestQuestionStep
            fieldnames={activeQuestion.fieldnames}
            title={activeQuestion.title}
            description={activeQuestion.description}
            key={activeQuestion.title}
            hasSkipButton={activeQuestion.hasSkipButton}
            onClose={onClose}
            onBack={onBack}
            onSubmitEdci={handleSubmitEdci}
          >
            {activeQuestion.children}
          </RequestQuestionStep>
        )}
      </FormProvider>
    </CreationModalContainer>
  )
}

export default EdciReport
