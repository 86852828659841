import endpoints from '@app/src/api/endpoints'
import { Config } from '@app/src/config'
import { useAmplitude } from '@app/src/context/AmplitudeContext'
import { useSnackbar } from '@app/src/context/SnackbarContext'
import RequestError from '@app/src/errors/RequestError'
import useErrorNotification from '@app/src/hooks/errorNotification'
import OnboardingForm from '@app/src/pages/SolutionSelector/OnboardingForm'
import { AmplitudeTrackingEvents } from '@app/src/wf-constants'
import { RefreshOutlined } from '@mui/icons-material'
import { Alert, Box, Divider, Stack } from '@mui/material'
import * as Sentry from '@sentry/react'
import React, { useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import LoadingButton from '../LoadingButton'

function VerifyEmail({ userEmail }: { userEmail: string }) {
  const { showSnackbar } = useSnackbar()
  const { showErrorNotification } = useErrorNotification()
  const { trackEvent } = useAmplitude()
  const { formatMessage } = useIntl()
  const [isLoading, setIsLoading] = useState(false)

  const handleSendVerificationEmail = async () => {
    setIsLoading(true)
    try {
      await fetch(`${Config.API_URL}${endpoints.sendVerificationEmail}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          email: userEmail,
        }),
      })
      showSnackbar({
        message: formatMessage({ id: 'authentication.email_not_verified.resendEmailSuccess' }),
        severity: 'success',
      })
      trackEvent({
        name: AmplitudeTrackingEvents.Onboarding.VerifyEmail.ResentVerificationEmail,
      })
    } catch (e) {
      const typedError = e as { requestError: RequestError }
      showErrorNotification({ requestError: typedError.requestError })
      Sentry.captureException(typedError, {
        tags: { event: 'Error resending verification email' },
        extra: { userEmail },
      })
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    trackEvent({
      name: AmplitudeTrackingEvents.Onboarding.VerifyEmail.VerifyEmailOpened,
    })
  }, [])

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        position: 'fixed',
        top: 0,
        left: 0,
        bottom: 0,
        right: 0,
      }}
    >
      <OnboardingForm
        header={formatMessage({ id: 'authentication.email_not_verified.title' })}
        subheader={formatMessage({ id: 'authentication.email_not_verified.description' })}
      >
        <Stack flexGrow={1} spacing={3} justifyContent="space-between">
          <Box>
            <LoadingButton
              onClick={handleSendVerificationEmail}
              loading={isLoading}
              startIcon={<RefreshOutlined />}
              variant="outlined"
            >
              {formatMessage({ id: 'authentication.email_not_verified.resendEmail' })}
            </LoadingButton>
          </Box>
          <Stack spacing={3} py={3}>
            <Divider />
            <Alert severity="info" sx={{ bgcolor: 'transparent' }}>
              {formatMessage({ id: 'authentication.email_not_verified.info' })}
            </Alert>
          </Stack>
        </Stack>
      </OnboardingForm>
    </Box>
  )
}

export default VerifyEmail
