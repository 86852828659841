// TODO: type this file
// es-lint-disable @typescript-eslint/ban-ts-comment
/* eslint-disable @typescript-eslint/ban-ts-comment */
//@ts-nocheck

import { DEFAULT_LANGUAGE } from '@app/src/wf-constants'

const TOTAL_TRANSLATIONS = [
  {
    jsonFileName: 'en',
    localeName: 'en-US',
  },
  {
    jsonFileName: 'sv',
    localeName: 'sv-SE',
  },
]

const getLanguagesWithTranslationFiles = async (): Record<string, unknown> => {
  const importTranslationFileJobs = TOTAL_TRANSLATIONS.map(async ({ jsonFileName, localeName }) => {
    const module = await import(`./locales/${jsonFileName}.json`)
    return { localeName: localeName, module: module }
  })
  const importedTranslations = await Promise.allSettled(importTranslationFileJobs)

  const languageData = importedTranslations.filter(r => r.status === 'fulfilled').map(f => f.value)
  return languageData.reduce((acc, { localeName, module }) => {
    acc[localeName] = module
    return acc
  }, {})
}

const languagesWithTranslationFiles = await getLanguagesWithTranslationFiles()
export const languagesWeHaveTranslationFilesFor = Object.keys(languagesWithTranslationFiles)

/**
 * Get the compatible locale based on a language.
 * If no locale is compatible, use the default language (en)
 * @param language The language to find compatible locale
 * @returns {*} A compatible locale based on the language or the default locale
 */
export const getLocale = (language = '') => {
  const languageWithoutRegionCode = language.toLowerCase().split(/[_-]+/)[0]
  if (
    languagesWeHaveTranslationFilesFor.includes(language) ||
    languagesWeHaveTranslationFilesFor.includes(languageWithoutRegionCode)
  ) {
    return language
  }
  return DEFAULT_LANGUAGE
}

/**
 * Get translation items for a specific language
 * Allow use of nested object by flattening when
 * returning the translations
 * @param language
 * @returns {*} The translation object with flatten values
 */

/**
 * Flatten and merge a list of translations.
 * This function is used to merge general translations
 * with region based translations
 * @param translations
 * @returns {any} The merged and flatten translation object
 */

const flattenTranslations = translations =>
  Object.keys(translations).reduce((acc, key) => {
    if (typeof translations[key] === 'object') {
      if (Object.keys(translations[key])?.length === 0) {
        return acc
      }

      const flattenObject = flattenTranslations(translations[key])
      return {
        ...acc,
        ...Object.keys(flattenObject).reduce(
          (acc2, key2) => ({
            ...acc2,
            [`${key}.${key2}`]: flattenObject[key2], // append key to beginning of flatten key
          }),
          {},
        ),
      }
    }
    return {
      ...acc,
      [key]: translations[key],
    }
  }, {})

export const getMessages = language => {
  const translations = languagesWithTranslationFiles[getLocale(language)]
  return flattenTranslations(translations)
}
