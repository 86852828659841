import { EditOutlined, NotificationsActiveOutlined } from '@mui/icons-material'
import { Avatar, List, ListItem, ListItemAvatar, ListItemText } from '@mui/material'
import React from 'react'
import { useIntl } from 'react-intl'

const sublistBulletPointTextIds = [
  'reporting.verification.correctionNeededChangedExplanation.changeReportingCompanies.bullet1',
  'reporting.verification.correctionNeededChangedExplanation.changeReportingCompanies.bullet2',
  'reporting.verification.correctionNeededChangedExplanation.changeReportingCompanies.bullet3',
]

const CorrectionNeededExplanationList: React.FC = () => {
  const { formatMessage } = useIntl()

  return (
    <List sx={{ mt: 2 }}>
      <ListItem disablePadding alignItems="flex-start" sx={{ mb: 2 }}>
        <ListItemAvatar>
          <Avatar
            sx={({ palette }) => ({
              color: palette.warning.contrastText,
              bgcolor: palette.warning.light,
            })}
          >
            <EditOutlined />
          </Avatar>
        </ListItemAvatar>
        <ListItemText
          primaryTypographyProps={{ variant: 'subtitle1' }}
          primary={formatMessage({
            id: 'reporting.verification.correctionNeededChangedExplanation.nowOnQuestion.header',
          })}
          secondary={formatMessage({
            id: 'reporting.verification.correctionNeededChangedExplanation.nowOnQuestion.text',
          })}
          sx={{ m: 0 }}
        />
      </ListItem>
      <ListItem disablePadding sx={{ alignItems: 'flex-start' }}>
        <ListItemAvatar>
          <Avatar
            sx={({ palette }) => ({
              color: palette.warning.contrastText,
              bgcolor: palette.warning.light,
            })}
          >
            <NotificationsActiveOutlined />
          </Avatar>
        </ListItemAvatar>
        <ListItemText
          primaryTypographyProps={{ variant: 'subtitle1' }}
          primary={formatMessage({
            id: 'reporting.verification.correctionNeededChangedExplanation.changeReportingCompanies.header',
          })}
          secondaryTypographyProps={{ component: 'span' }}
          secondary={
            <List sx={{ listStyleType: 'disc', px: 2 }}>
              {sublistBulletPointTextIds.map(textId => (
                <ListItem key={textId} sx={{ display: 'list-item', p: 0 }}>
                  {formatMessage(
                    {
                      id: textId,
                    },
                    { b: (chunks: React.ReactNode) => <b>{chunks}</b> },
                  )}
                </ListItem>
              ))}
            </List>
          }
          sx={{ m: 0 }}
        />
      </ListItem>
    </List>
  )
}

export default CorrectionNeededExplanationList
