import endpoints from '@app/src/api/endpoints'
import { FetchKey } from '@app/src/api/fetchHooks'
import { useUpdateResource } from '@app/src/api/updateHooks'
import { Option } from '@app/src/components/Form/Select'
import Select from '@app/src/components/Form/Select/ControlledSelect'
import { getOrganizationDisplayName } from '@app/src/components/Table/Cells/CompanyCell'
import { AvatarSize } from '@app/src/components/Ui/Avatar'
import TextField from '@app/src/components/Ui/TextField'
import { useSnackbar } from '@app/src/context/SnackbarContext'
import useErrorNotification from '@app/src/hooks/errorNotification'
import CompanyAvatar from '@app/src/pages/ResourceCollection/Collections/CellComponents/CompanyAvatar'
import { GenericOrganization, Provider } from '@app/src/types/organizations'
import { User } from '@app/src/types/resourceExplorer'
import { NotificationSeverity } from '@app/src/wf-constants'
import { BusinessOutlined, LanguageOutlined, NumbersOutlined, PlaceOutlined } from '@mui/icons-material'
import { Box, Divider, List, ListItem, ListItemAvatar, ListItemText, Stack, Typography } from '@mui/material'
import { capitalize } from 'lodash'
import React from 'react'
import { useForm } from 'react-hook-form'
import { useIntl } from 'react-intl'
import { useQueryClient } from 'react-query'
import { useDrawer } from '../DrawerContext'
import DrawerView, { DrawerViewProps } from '../DrawerView'

type DrawerViewAddOrganizationAsProviderProps = {
  provider: Provider
  providerType: string
  resource?: GenericOrganization
} & Omit<DrawerViewProps, 'title'>

const DrawerViewAddOrganizationAsProvider: React.FC<DrawerViewAddOrganizationAsProviderProps> = ({
  provider,
  providerType,
  resource,
  ...props
}) => {
  const { formatMessage } = useIntl()
  const { closeDrawer } = useDrawer()
  const { showSnackbar } = useSnackbar()
  const { showErrorNotification } = useErrorNotification()
  const { register, errors, setValue, control, handleSubmit, setError } = useForm<Partial<Provider>>()
  const queryClient = useQueryClient()
  const { mutateAsync, isLoading } = useUpdateResource<Partial<Provider>>()

  const onSubmit = async (values: Partial<Provider>): Promise<void> => {
    const org: Partial<Provider> = {
      ...resource,
      organizationId: provider?.id,
      ownerUserId: values?.ownerUserId,
      customId: values?.customId,
    }
    await mutateAsync(
      {
        body: resource ? org : { ...values, organizationId: provider.id },
        url: resource ? endpoints.linkOrganizationWithProvider : endpoints.saveProvider,
      },
      {
        onSuccess: () => {
          showSnackbar({
            message: formatMessage(
              { id: 'notifications.successfulLinkProvider' },
              { provider: formatMessage({ id: `general.${capitalize(providerType)}` }, { count: 1 }) },
            ),
            severity: NotificationSeverity.success,
            disableAutoClose: true,
          })
          queryClient.invalidateQueries(FetchKey.ProviderCollection)
          closeDrawer()
        },
        onError: error => {
          if (error.isValidationError) {
            error.setFormValidationErrors(setError)
            return
          }
          showErrorNotification({ requestError: error, disableAutoClose: true })
        },
      },
    )
  }

  return (
    <DrawerView
      title={formatMessage({ id: 'general.worldfavorNetwork' })}
      subTitle={provider.name}
      {...props}
      buttons={[
        {
          label: formatMessage({ id: 'general.connect' }),
          variant: 'contained',
          type: 'submit',
          loading: isLoading,
        },
      ]}
      onFormSubmit={handleSubmit(onSubmit)}
    >
      <Stack py={2} spacing={2} alignItems="center" textAlign="center">
        <CompanyAvatar size={AvatarSize.XXL} organizationName={getOrganizationDisplayName(provider)} />
        <Typography variant="subtitle1" noWrap>
          {provider.name}
        </Typography>
      </Stack>

      <List dense>
        <ListItem>
          <ListItemAvatar>
            <LanguageOutlined color="action" />
          </ListItemAvatar>
          <ListItemText
            primary={formatMessage({ id: 'schemas.organization.websiteAddress' })}
            secondary={provider.websiteAddress || formatMessage({ id: 'general.notAdded' })}
          />
        </ListItem>
        <ListItem>
          <ListItemAvatar>
            <BusinessOutlined color="action" />
          </ListItemAvatar>
          <ListItemText
            primary={formatMessage({ id: 'schemas.organization.industries' })}
            secondary={provider.industryNaceCode?.description || formatMessage({ id: 'general.notAdded' })}
          />
        </ListItem>
        <ListItem>
          <ListItemAvatar>
            <NumbersOutlined color="action" />
          </ListItemAvatar>
          <ListItemText
            primary={formatMessage({ id: 'schemas.organization.vatNumber' })}
            secondary={provider.vatNumber || formatMessage({ id: 'general.notAdded' })}
          />
        </ListItem>
        <ListItem>
          <ListItemAvatar>
            <NumbersOutlined color="action" />
          </ListItemAvatar>
          <ListItemText
            primary={formatMessage({ id: 'schemas.organization.registrationNumber' })}
            secondary={provider.registrationNumber || formatMessage({ id: 'general.notAdded' })}
          />
        </ListItem>
        <ListItem>
          <ListItemAvatar>
            <PlaceOutlined color="action" />
          </ListItemAvatar>
          <ListItemText
            primary={formatMessage({ id: 'schemas.organization.address' })}
            secondary={provider.locations?.[0]?.address || formatMessage({ id: 'general.notAdded' })}
          />
        </ListItem>
      </List>

      <Divider light />

      <Box px={2} mt={2}>
        <Typography variant="subtitle1" color="text.secondary">
          {formatMessage({ id: 'general.categorize' })}
        </Typography>
        <form noValidate>
          <Box mt={2}>
            <Select<number, User>
              name="ownerUserId"
              fieldLabel={formatMessage({ id: 'schemas.organization.ownerUserId' })}
              control={control}
              forceFetch
              navigation={{
                url: endpoints.colleagues,
                type: 'post',
                postObject: {
                  pagination: {
                    itemsPerPage: 9999,
                    pageNumber: 1,
                  },
                  include: [],
                  filter: [],
                },
              }}
              objectToOption={(owner: User): Option<number> => ({
                label: owner.name,
                value: owner.id,
              })}
              error={errors?.ownerUserId}
            />
          </Box>
          <Box mt={2}>
            <TextField
              inputRef={register}
              fullWidth
              name="customId"
              label={formatMessage({ id: 'schemas.organization.customId' })}
              error={Boolean(errors?.customId)}
              type="text"
              helperText={errors?.customId?.message}
              onClear={(): void => setValue('customId', '')}
            />
          </Box>
        </form>
      </Box>
    </DrawerView>
  )
}

export default DrawerViewAddOrganizationAsProvider
