import { CheckboxProps } from '@mui/material'
import { FetchKey, useFetchFacets } from '../../api/fetchHooks'
import { FacetResponse } from '../../pages/ResourceCollection'
import { FilterGroup } from '../../pages/ResourceCollection/Filters/useFilters'
import { useSelectableRows } from './useSelectableRows'

interface UseSelectableRowsArgs<T> {
  rowsInPage: T[]
  initialState?: number[]
  fetchKey: FetchKey
  endpoint: string
  facetsParam: string
  filters: FilterGroup[]
}

export const useSelectableRowsWithFacetsSelectAll = <T extends { id: number }>({
  rowsInPage,
  initialState = [],
  fetchKey,
  endpoint,
  facetsParam,
  filters,
}: UseSelectableRowsArgs<T>) => {
  const { selectedRowsIds, setSelectedRowsIds, handleCheckboxChange } = useSelectableRows({ rowsInPage, initialState })

  const {
    facets: [allRowsWithFacets],
    isLoading: isLoadingAllRowsWithFacets,
    refetch: fetchAllRowsWithFacets,
  } = useFetchFacets({
    key: [fetchKey],
    endpoint,
    options: { enabled: false },
    facetsParam: [{ name: facetsParam }],
    filter: filters,
  })

  const isAllSelected = () => {
    if (!allRowsWithFacets?.length) return false

    const rowIds = allRowsWithFacets?.map(item => Number(item.value))
    return rowIds?.every(id => selectedRowsIds.includes(id))
  }

  const handleSelectAll = async () => {
    if (allRowsWithFacets?.length) {
      const allIds = allRowsWithFacets.map(facet => Number(facet.value))
      setSelectedRowsIds(prev => Array.from(new Set([...prev, ...allIds])))
      return
    }
    const { data } = await fetchAllRowsWithFacets()
    const facetResponse = (data as FacetResponse)?.facets?.[0]?.items
    if (!facetResponse) return

    const rowIds = facetResponse.map(item => Number(item.value))
    setSelectedRowsIds(prev => Array.from(new Set([...prev, ...rowIds])))
  }

  const isHeaderChecked = rowsInPage?.every(row => selectedRowsIds?.includes(row.id))

  const handleHeaderCheckboxChange: CheckboxProps['onChange'] = (_e, checked) => {
    if (checked) {
      setSelectedRowsIds(prev => Array.from(new Set([...prev, ...rowsInPage.map(row => row.id)])))
    } else {
      setSelectedRowsIds([])
    }
  }

  return {
    selectedRowsIds,
    setSelectedRowsIds,
    handleCheckboxChange,
    allRowsWithFacets,
    isLoadingAllRows: isLoadingAllRowsWithFacets,
    isAllSelected,
    handleSelectAll,
    isHeaderChecked,
    handleHeaderCheckboxChange,
  }
}
