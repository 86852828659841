import { useDialogState } from '@app/src/hooks/mui-hooks'
import { usePeriodName } from '@app/src/hooks/usePeriodName'
import ReactEChartsCore from 'echarts-for-react/lib/core'
import React, { PropsWithChildren, useMemo, useState } from 'react'
import DataInsightsModal from './DataInsightsModal'
import {
  DataInsightsModalContext,
  DataInsightsModalContextProps,
  ModalStatistics,
  UserSelection,
} from './DataInsightsModalContext'

const DataInsightsModalContextProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const [isOpen, openModal, closeModal] = useDialogState(false)
  const [userSelection, setUserSelection] = useState<UserSelection>()
  const [statistics, setStatistics] = useState<ModalStatistics>()
  const [echartsRefs, setEchartRefs] = useState<Array<React.MutableRefObject<ReactEChartsCore | null>>>([])
  const { formatPeriodName } = usePeriodName()

  const openDataInsightsModal: DataInsightsModalContextProps['openDataInsightsModal'] = (statistics, userSelection) => {
    setStatistics(statistics)
    setUserSelection(userSelection)
    if (!isOpen) openModal()
  }

  const setDataInsightsRefForIndex = (ref: React.MutableRefObject<ReactEChartsCore | null>, index: number) => {
    setEchartRefs(currentValue => {
      currentValue[index] = ref
      return currentValue
    })
  }

  const clearEchartsRefOverIndex = useMemo(
    () => (index: number) => {
      setEchartRefs(currentValues => {
        return currentValues.slice(0, index)
      })
    },
    [],
  )

  const formattedPeriods = useMemo(() => {
    const periods = userSelection?.group
      ? [formatPeriodName(userSelection?.group)]
      : statistics?.resultForGroups?.map(item => formatPeriodName(item.group))
    return periods?.join(' + ')
  }, [userSelection, statistics])

  return (
    <DataInsightsModalContext.Provider
      value={{
        openDataInsightsModal,
        statistics,
        setStatistics,
        userSelection,
        setUserSelection,
        formattedPeriods,
        echartsRefs,
        setDataInsightsRefForIndex,
        clearEchartsRefOverIndex,
      }}
    >
      {children}
      {statistics && <DataInsightsModal isOpen={isOpen} closeModal={closeModal} />}
    </DataInsightsModalContext.Provider>
  )
}

export default DataInsightsModalContextProvider
