import { useDrawer } from '@app/src/components/Drawer/DrawerContext'
import DrawerView, { DrawerViewProps } from '@app/src/components/Drawer/DrawerView'
import Select, { Option } from '@app/src/components/Form/Select/ControlledSelect'
import ControlledTextFieldWithFormatting from '@app/src/components/TextFieldWithFormatting/ControlledTextFieldWithFormatting'
import { useAmplitude } from '@app/src/context/AmplitudeContext'
import useAccessorQuestionnaire from '@app/src/pages/Questionnaire/hooks/useAccessorQuestionnaire'
import { FacetItem } from '@app/src/pages/ResourceCollection'
import useSelectedAnswersData from '@app/src/pages/ResourceCollection/Collections/DataHub/AllAnswers/useSelectedAnswersData'
import { FilterGroup } from '@app/src/pages/ResourceCollection/Filters/useFilters'
import { InternalVerificationStatus } from '@app/src/types/resourceExplorer'
import { insertIf } from '@app/src/utils/helpersTs'
import { AmplitudeTrackingEvents } from '@app/src/wf-constants'
import { VisibilityOffOutlined } from '@mui/icons-material'
import { ListItem, ListItemAvatar, ListItemText, Stack } from '@mui/material'
import { useConfirm } from 'material-ui-confirm'
import React, { useEffect } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useIntl } from 'react-intl'

const MULTIPLE_STATUSES = 'MultipleStatuses'

type VerificationStatusOptions = InternalVerificationStatus | typeof MULTIPLE_STATUSES

type DrawerViewVerificationProps = {
  itemIds: number[]
  filters?: FilterGroup[]
} & Omit<DrawerViewProps, 'title'>

interface FormData {
  internalVerificationStatus: VerificationStatusOptions
  internalVerificationComment: string
}

const hasMultipleStatuses = (internalVerificationStatuses: FacetItem[], selectedItemsCount: number) => {
  return (
    Boolean(internalVerificationStatuses?.length) &&
    (internalVerificationStatuses?.length > 1 || internalVerificationStatuses?.[0]?.count !== selectedItemsCount)
  )
}

const getPreselectedVerificationStatus = (internalVerificationStatuses: FacetItem[], selectedItemsCount: number) => {
  if (hasMultipleStatuses(internalVerificationStatuses, selectedItemsCount)) return MULTIPLE_STATUSES
  return (
    (internalVerificationStatuses?.[0]?.value.toString() as InternalVerificationStatus) ??
    InternalVerificationStatus.NotSet
  )
}

const DrawerViewBulkResponseItemInternalVerification: React.FC<DrawerViewVerificationProps> = ({
  itemIds,
  filters = [],
  ...props
}) => {
  const { formatMessage } = useIntl()
  const { closeDrawer } = useDrawer()
  const confirm = useConfirm()
  const { trackEvent } = useAmplitude()
  const { internalReviewResponseItems, isLoadingCreateVerification } = useAccessorQuestionnaire()

  const formMethods = useForm<FormData>()

  const { reset, handleSubmit, register, watch, control } = formMethods

  const {
    internalVerificationStatuses,
    isLoadingInternalVerificationStatuses,
    internalVerificationComments,
    isLoadingInternalVerificationComments,
    selectedQuestionDescription,
    selectedQuestionIds,
  } = useSelectedAnswersData({
    filters,
    selectedRowsIds: itemIds,
  })

  const internalVerificationStatus: VerificationStatusOptions = watch('internalVerificationStatus')
  const defaultCommentValue =
    internalVerificationComments?.length === 1 ? internalVerificationComments[0].value.toString() : ''

  const getConfirmationText = (submittedStatus: VerificationStatusOptions) => {
    if (submittedStatus === InternalVerificationStatus.NotApproved) {
      return formatMessage(
        { id: 'reporting.verification.bulkInternalVerification.notApproved' },
        {
          answersCount: itemIds?.length,
          questionsCount: selectedQuestionIds?.length,
          hasMessage: Boolean(watch('internalVerificationComment')),
        },
      )
    }
    if (submittedStatus === InternalVerificationStatus.Approved) {
      return formatMessage(
        { id: 'reporting.verification.bulkInternalVerification.approved' },
        {
          answersCount: itemIds?.length,
          questionsCount: selectedQuestionIds?.length,
          hasMessage: Boolean(watch('internalVerificationComment')),
        },
      )
    }
    return formatMessage(
      { id: 'reporting.verification.bulkInternalVerification.notSet' },
      { answersCount: itemIds?.length, questionsCount: selectedQuestionIds?.length },
    )
  }

  const onSubmit = async (submittedVerification: FormData) => {
    await confirm({
      content: getConfirmationText(submittedVerification.internalVerificationStatus),
      confirmationText: formatMessage({ id: 'general.confirmAndSubmit' }),
      cancellationButtonProps: { variant: 'text' },
      confirmationButtonProps: { variant: 'text' },
    }).then(async () => {
      if (submittedVerification.internalVerificationStatus === MULTIPLE_STATUSES) return
      await internalReviewResponseItems({
        responseItemIds: itemIds,
        internalVerificationStatus: submittedVerification.internalVerificationStatus,
        internalVerificationComment: submittedVerification.internalVerificationComment,
      })
      trackEvent({
        name: AmplitudeTrackingEvents.Accessor.ResponseItemVerification.BulkInternalVerification.Submitted,
        eventProps: {
          responseItemsCount: itemIds.length,
          questionsCount: selectedQuestionIds?.length,
          verificationStatus: submittedVerification.internalVerificationStatus,
          hasComment: Boolean(submittedVerification.internalVerificationComment),
        },
      })
      closeDrawer()
    })
  }

  useEffect(() => {
    trackEvent({
      name: AmplitudeTrackingEvents.Accessor.ResponseItemVerification.BulkInternalVerification.Opened,
      eventProps: {
        responseItemsCount: itemIds.length,
        questionsCount: selectedQuestionIds?.length,
      },
    })
    reset({
      internalVerificationStatus: getPreselectedVerificationStatus(internalVerificationStatuses, itemIds.length),
      internalVerificationComment: defaultCommentValue,
    })
  }, [internalVerificationStatuses, internalVerificationComments])

  if (isLoadingInternalVerificationStatuses || isLoadingInternalVerificationComments) return null

  return (
    <FormProvider {...formMethods}>
      <DrawerView
        stackButtons
        title={formatMessage({
          id: 'questionnaire.actions.internalReview',
        })}
        subTitle={
          selectedQuestionDescription ??
          formatMessage(
            { id: 'reporting.verification.responseVerificationSubHeader' },
            { questionsCount: selectedQuestionIds?.length },
          )
        }
        buttons={[
          {
            label: formatMessage({ id: 'general.cancel' }),
            variant: 'outlined',
            disabled: isLoadingCreateVerification,
            onClick: closeDrawer,
          },
          {
            label: formatMessage({ id: 'general.submit' }),
            variant: 'contained',
            loading: isLoadingCreateVerification,
            disabled: internalVerificationStatus === MULTIPLE_STATUSES,
            type: 'submit',
          },
        ]}
        onFormSubmit={handleSubmit(onSubmit)}
        {...props}
      >
        <Stack px={3} flexGrow={1} spacing={2}>
          <input type="hidden" name="id" ref={register()} />

          <Select<VerificationStatusOptions>
            name="internalVerificationStatus"
            control={control}
            fieldLabel={formatMessage({ id: 'reporting.verification.internalVerification' })}
            variant="outlined"
            options={[
              ...insertIf<Option<VerificationStatusOptions>>(
                hasMultipleStatuses(internalVerificationStatuses, itemIds.length),
                {
                  value: MULTIPLE_STATUSES,
                  label: formatMessage({ id: 'reporting.reviewStatuses.multipleStatuses' }),
                  disabled: true,
                },
              ),
              {
                value: InternalVerificationStatus.NotSet,
                label: formatMessage({ id: 'reporting.statuses.ToBeVerified' }),
              },
              {
                value: InternalVerificationStatus.Approved,
                label: formatMessage({ id: 'reporting.statuses.Approved' }),
              },
              {
                value: InternalVerificationStatus.NotApproved,
                label: formatMessage({ id: 'reporting.statuses.NotApproved' }),
              },
            ]}
          />
          {internalVerificationStatus !== InternalVerificationStatus.NotSet &&
            internalVerificationStatus !== MULTIPLE_STATUSES && (
              <ControlledTextFieldWithFormatting
                name="internalVerificationComment"
                defaultValue={defaultCommentValue}
                control={control}
                placeholder={formatMessage({ id: 'reporting.verification.addOptionalComment' })}
                multiline
                size="medium"
                hideUndo
                fillHeight
                maxLength={1000}
              />
            )}
          <ListItem disablePadding alignItems="flex-start">
            <ListItemAvatar sx={{ minWidth: 30 }}>
              <VisibilityOffOutlined fontSize="small" sx={{ color: 'text.secondary' }} />
            </ListItemAvatar>
            <ListItemText
              primaryTypographyProps={{ variant: 'body2', mt: 0.5, color: 'text.secondary' }}
              primary={formatMessage({ id: 'reporting.verification.internalVerificationDescription' })}
              sx={{ m: 0 }}
            />
          </ListItem>
        </Stack>
      </DrawerView>
    </FormProvider>
  )
}

export default DrawerViewBulkResponseItemInternalVerification
