import endpoints from '@app/src/api/endpoints'
import { FetchKey, useFetchFacets } from '@app/src/api/fetchHooks'
import { FilterGroup, Operators } from '../../../Filters/useFilters'

interface UseSelectedAnswersDataArgs {
  filters: FilterGroup[]
  selectedRowsIds: number[]
}

const useSelectedAnswersData = ({ filters, selectedRowsIds }: UseSelectedAnswersDataArgs) => {
  const {
    facets: [externalVerificationStatuses],
    isLoading: isLoadingExternalVerificationStatuses,
    refetch: fetchExternalVerificationStatuses,
  } = useFetchFacets({
    key: [FetchKey.ResponseItemsFacets],
    endpoint: endpoints.responseItemsWithFacets,
    options: { enabled: false },
    facetsParam: [{ name: 'verifications.externalVerificationStatus' }],
    filter: [
      ...filters,
      {
        name: 'id',
        filters: [
          {
            operator: Operators.In,
            value: selectedRowsIds,
          },
        ],
      },
    ],
  })

  const {
    facets: [externalVerificationComments],
    isLoading: isLoadingExternalVerificationComments,
    refetch: fetchExternalVerificationComments,
  } = useFetchFacets({
    key: [FetchKey.ResponseItemsFacets],
    endpoint: endpoints.responseItemsWithFacets,
    options: { enabled: false },
    facetsParam: [{ name: 'verifications.externalVerificationComment', isEnum: true }],
    filter: [
      ...filters,
      {
        name: 'id',
        filters: [
          {
            operator: Operators.In,
            value: selectedRowsIds,
          },
        ],
      },
    ],
  })

  const {
    facets: [internalVerificationStatuses],
    isLoading: isLoadingInternalVerificationStatuses,
    refetch: fetchInternalVerificationStatuses,
  } = useFetchFacets({
    key: [FetchKey.ResponseItemsFacets],
    endpoint: endpoints.responseItemsWithFacets,
    options: { enabled: false },
    facetsParam: [{ name: 'verifications.internalVerificationStatus' }],
    filter: [
      ...filters,
      {
        name: 'id',
        filters: [
          {
            operator: Operators.In,
            value: selectedRowsIds,
          },
        ],
      },
    ],
  })

  const {
    facets: [internalVerificationComments],
    isLoading: isLoadingInternalVerificationComments,
    refetch: fetchInternalVerificationComments,
  } = useFetchFacets({
    key: [FetchKey.ResponseItemsFacets],
    endpoint: endpoints.responseItemsWithFacets,
    options: { enabled: false },
    facetsParam: [{ name: 'verifications.internalVerificationComment', isEnum: true }],
    filter: [
      ...filters,
      {
        name: 'id',
        filters: [
          {
            operator: Operators.In,
            value: selectedRowsIds,
          },
        ],
      },
    ],
  })

  const {
    facets: [selectedQuestionIds],
    isLoading: isLoadingSelectedQuestionIds,
    refetch: fetchSelectedQuestionIds,
  } = useFetchFacets({
    key: [FetchKey.ResponseItemsFacets],
    endpoint: endpoints.responseItemsWithFacets,
    options: { enabled: false },
    facetsParam: [{ name: 'requestItem.template.id' }],
    filter: [
      ...filters,
      {
        name: 'id',
        filters: [
          {
            operator: Operators.In,
            value: selectedRowsIds,
          },
        ],
      },
    ],
  })

  const {
    facets: [selectedQuestionDescription],
    isLoading: isLoadingSelectedQuestionDescriptions,
  } = useFetchFacets({
    key: [FetchKey.ResponseItemsFacets],
    endpoint: endpoints.responseItemsWithFacets,
    options: { enabled: Boolean(selectedQuestionIds?.length === 1) },
    facetsParam: [{ name: 'requestItem.template.questionText' }],
    filter: [
      ...filters,
      {
        name: 'id',
        filters: [
          {
            operator: Operators.In,
            value: selectedRowsIds,
          },
        ],
      },
    ],
  })

  const fetchExternalVerificationData = async () => {
    await fetchExternalVerificationStatuses()
    await fetchExternalVerificationComments()
    await fetchSelectedQuestionIds()
  }

  const fetchInternalVerificationData = async () => {
    await fetchInternalVerificationStatuses()
    await fetchInternalVerificationComments()
    await fetchSelectedQuestionIds()
  }

  const isLoadingExternalVerificationData =
    isLoadingExternalVerificationStatuses ||
    isLoadingExternalVerificationComments ||
    isLoadingSelectedQuestionIds ||
    isLoadingSelectedQuestionDescriptions

  const isLoadingInternalVerificationData =
    isLoadingInternalVerificationStatuses ||
    isLoadingInternalVerificationComments ||
    isLoadingSelectedQuestionIds ||
    isLoadingSelectedQuestionDescriptions

  return {
    externalVerificationStatuses,
    externalVerificationComments,
    isLoadingExternalVerificationStatuses,
    isLoadingExternalVerificationComments,
    fetchExternalVerificationStatuses,
    fetchExternalVerificationComments,
    internalVerificationStatuses,
    internalVerificationComments,
    isLoadingInternalVerificationStatuses,
    isLoadingInternalVerificationComments,
    fetchInternalVerificationStatuses,
    fetchInternalVerificationComments,
    selectedQuestionIds,
    isLoadingSelectedQuestionIds,
    fetchSelectedQuestionIds,
    fetchExternalVerificationData,
    isLoadingExternalVerificationData,
    isLoadingInternalVerificationData,
    fetchInternalVerificationData,

    selectedQuestionDescription: selectedQuestionDescription?.[0]?.label,
  }
}

export default useSelectedAnswersData
