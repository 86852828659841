import { Box, Typography } from '@mui/material'
import React from 'react'

import Markdown from '@app/src/components/Markdown'
import RequestItemTitle from '@app/src/pages/Questionnaire/RequestItemTitle'
import { RequestSection } from '@app/src/types/resourceExplorer'
import { useIntl } from 'react-intl'
import { useReport } from '../../ReportContext'
import RequestItem from './RequestItem'

type SectionProps = {
  section: RequestSection
  sectionNumber: number
}

const Section: React.FC<SectionProps> = ({ section, sectionNumber }) => {
  const { response } = useReport()
  const { formatMessage } = useIntl()

  return (
    <>
      <Box display="flex" flexDirection="column" mt={sectionNumber > 1 ? 7 : 0}>
        <Typography variant="overline" gutterBottom>
          {formatMessage({ id: 'questionnaire.section' }, { number: sectionNumber })}
        </Typography>
        <Typography variant="h2" gutterBottom>
          <Markdown>{section.title}</Markdown>
        </Typography>
        <Typography variant="body1">
          <Markdown>{section.description}</Markdown>
        </Typography>
      </Box>

      {section.items.map((item, index) => {
        const responseItem = response?.items.find(responseItem => responseItem.requestItemId === item.id)
        return (
          <Box
            key={item.id}
            mt={5}
            sx={({ palette }) => ({ border: `1px solid ${palette.grey[300]}`, borderRadius: 2 })}
          >
            <RequestItemTitle
              item={item}
              responseItem={responseItem}
              sectionNumber={sectionNumber}
              itemNumber={index + 1}
            />
            <RequestItem item={item} responseItem={responseItem} />
          </Box>
        )
      })}
    </>
  )
}

export default Section
