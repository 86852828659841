import Permissions, { usePermissions } from '@app/src/auth/permissions'
import { useDrawer } from '@app/src/components/Drawer/DrawerContext'
import DrawerViewResponseItemExternalVerification from '@app/src/components/Drawer/Views/Verification/DrawerViewResponseItemExternalVerification'
import DrawerViewResponseItemInternalVerification from '@app/src/components/Drawer/Views/Verification/DrawerViewResponseItemInternalVerification'
import { useReport } from '@app/src/pages/Questionnaire/ReportContext'
import { ResponseDraftStatus, ResponseItem } from '@app/src/types/resourceExplorer'
import { Button, Skeleton, Stack } from '@mui/material'
import { useFlags } from 'launchdarkly-react-client-sdk'
import React from 'react'
import { useIntl } from 'react-intl'

export interface VerificationButtonsProps {
  item: ResponseItem
  questionDescription: string
  isLoading?: boolean
}

const VerificationButtons = ({ item, questionDescription, isLoading }: VerificationButtonsProps) => {
  const { response, request, latestHistory } = useReport()
  const { hasPermission } = usePermissions()
  const { formatMessage } = useIntl()
  const { openDrawer } = useDrawer()
  const { stpResponseResponseItemVerificationFilterLogic } = useFlags()

  const isPrimarySubscriber = response?.request?.isPrimarySubscriber || request?.isPrimarySubscriber
  const isLatestResponse = latestHistory?.responseId === response?.id

  const isReviewEnabled =
    (!stpResponseResponseItemVerificationFilterLogic || isPrimarySubscriber) &&
    hasPermission(Permissions.WRITE_VERIFICATION) &&
    isLatestResponse &&
    response?.draftStatus === ResponseDraftStatus.Submitted

  if (!isReviewEnabled) {
    return null
  }

  if (isLoading) {
    return (
      <Stack direction="row" spacing={2}>
        <Skeleton width={110} height={30} variant="rounded" />
        <Skeleton width={75} height={30} variant="rounded" />
      </Stack>
    )
  }

  return (
    <Stack direction="row" spacing={2}>
      <Button
        fullWidth
        sx={{ minWidth: 145 }}
        variant="outlined"
        onClick={() =>
          openDrawer(
            <DrawerViewResponseItemInternalVerification
              responseItem={item}
              questionDescription={questionDescription}
            />,
          )
        }
      >
        {formatMessage({ id: 'questionnaire.actions.internalReview' })}
      </Button>
      <Button
        fullWidth
        variant="contained"
        onClick={() =>
          openDrawer(
            <DrawerViewResponseItemExternalVerification
              response={response}
              item={item}
              questionDescription={questionDescription}
            />,
          )
        }
      >
        {formatMessage({ id: 'questionnaire.actions.review' })}
      </Button>
    </Stack>
  )
}

export default VerificationButtons
