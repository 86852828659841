import useCurrentProviderType from '@app/src/hooks/currentProviderType'
import { plural, singular } from '@app/src/utils/translationMarkup'
import { capitalize } from 'lodash'
import { useIntl } from 'react-intl'

interface UseCurrentProviderNameParams {
  isPlural?: boolean
  shouldCapitalize?: boolean
}

const useCurrentProviderTypeName = ({
  isPlural = false,
  shouldCapitalize = false,
}: UseCurrentProviderNameParams = {}): string => {
  const currentProviderType = useCurrentProviderType()
  const { formatMessage } = useIntl()
  const baseName = formatMessage({ id: `general.${capitalize(currentProviderType)}` }, isPlural ? plural : singular)
  return shouldCapitalize ? capitalize(baseName) : baseName.toLowerCase()
}

export default useCurrentProviderTypeName
