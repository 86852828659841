import { Box, BoxProps } from '@mui/material'
import { useDrawer } from '@app/src/components/Drawer/DrawerContext'
import DrawerViewFlagRule from '@app/src/components/Drawer/Views/DrawerViewFlagRule'
import FlagSuggestedRiskAvatar from '@app/src/components/Flags/FlagSuggestedRiskAvatar'
import { AvatarSize } from '@app/src/components/Ui/Avatar'
import React from 'react'
import { ReportFlagRule } from '@app/src/types/flags'
import { WF_ORGANIZATION_ID } from '@app/src/wf-constants'
import FlagAvatar from './FlagAvatar'

interface Props {
  flagRules?: ReportFlagRule[]
  sx?: BoxProps['sx']
}

const FlagIndicator: React.FC<Props> = ({ flagRules, sx }: Props) => {
  const { openDrawer } = useDrawer()

  const isStandard = flagRules?.some(flagRule => flagRule.creatorOrganizationId === WF_ORGANIZATION_ID)

  return (
    <>
      <Box sx={sx}>
        {isStandard ? (
          <FlagSuggestedRiskAvatar
            size={AvatarSize.Small}
            onClick={() => openDrawer(<DrawerViewFlagRule flagRules={flagRules} />, { showAboveDialogs: true })}
          />
        ) : (
          <FlagAvatar
            size={AvatarSize.Small}
            onClick={() => openDrawer(<DrawerViewFlagRule flagRules={flagRules} />, { showAboveDialogs: true })}
          />
        )}
      </Box>
    </>
  )
}

export default FlagIndicator
