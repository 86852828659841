import EmptyState from '@app/src/components/EmptyState'
import { EmptyStateVariant } from '@app/src/components/EmptyState/EmptyState'
import { ChartType } from '@app/src/pages/ResourceCollection/Collections/Assessments/ChartTypeSelector'
import { NumberMultiPeriodChart } from '@app/src/pages/ResourceCollection/Collections/DataHub/DataInsights/NumberMultiPeriodChart'
import { NumberMultiPeriodLineChart } from '@app/src/pages/ResourceCollection/Collections/DataHub/DataInsights/NumberMultiPeriodLineChart'
import { OptionMultiPeriodChart } from '@app/src/pages/ResourceCollection/Collections/DataHub/DataInsights/OptionMultiPeriodChart'
import { OptionSinglePeriodPieChart } from '@app/src/pages/ResourceCollection/Collections/DataHub/DataInsights/OptionSinglePeriodPieChart'
import { TextAndFileMultiPeriodChart } from '@app/src/pages/ResourceCollection/Collections/DataHub/DataInsights/TextAndFileMultiPeriodChart'
import { QuestionTypes } from '@app/src/wf-constants'
import BarChartIcon from '@mui/icons-material/BarChart'
import ReactEChartsCore from 'echarts-for-react/lib/core'
import React from 'react'
import { useIntl } from 'react-intl'
import { StatisticsForQuestionPerPeriod } from '../../DataHubScene'

interface DataHubInsightsCardProps {
  statistics: StatisticsForQuestionPerPeriod
  eChartsRef: React.MutableRefObject<ReactEChartsCore | null>
  selectedChartType: ChartType
}

const DataInsightsChart: React.FC<DataHubInsightsCardProps> = ({ statistics, eChartsRef, selectedChartType }) => {
  const { formatMessage } = useIntl()

  const height = '384px'

  const noData = statistics.resultForGroups.every(r => r.questionMonitoring.companiesHaveAnswered.number === 0)
  if (noData)
    return (
      <EmptyState
        sx={{ height: height }}
        title={formatMessage({ id: 'statistics.dataInsights.noDataForPeriod' })}
        iconComponent={BarChartIcon}
        variant={EmptyStateVariant.Small}
      />
    )

  switch (statistics.questionType) {
    case QuestionTypes.Options:
      if (selectedChartType === ChartType.Pie)
        return <OptionSinglePeriodPieChart statistics={statistics} height={height} echartsRef={eChartsRef} />

      return (
        <OptionMultiPeriodChart
          statistics={statistics}
          height={height}
          echartsRef={eChartsRef}
          selectedChartType={selectedChartType}
        />
      )
    case QuestionTypes.Number:
      if (selectedChartType === ChartType.Line)
        return (
          <NumberMultiPeriodLineChart
            statistics={statistics}
            unit={statistics.unit}
            height={height}
            echartsRef={eChartsRef}
          />
        )

      return (
        <NumberMultiPeriodChart
          statistics={statistics}
          height={height}
          echartsRef={eChartsRef}
          unit={statistics.unit}
          selectedChartType={selectedChartType}
        />
      )
    default:
      return (
        <TextAndFileMultiPeriodChart
          statistics={statistics}
          height={height}
          echartsRef={eChartsRef}
          legendTitle={formatMessage({
            id:
              statistics.questionType === QuestionTypes.File
                ? 'statistics.dataInsights.uploadedAFile'
                : 'statistics.dataInsights.descriptionProvided',
          })}
          selectedChartType={selectedChartType}
        />
      )
  }
}

export default DataInsightsChart
