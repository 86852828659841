import ControlledLocationField, { SearchResult } from '@app/src/components/Form/ControlledLocationField'
import TextField from '@app/src/components/Ui/TextField'
import OnboardingForm from '@app/src/pages/SolutionSelector/OnboardingForm'
import { ArrowBack, CheckCircleOutline } from '@mui/icons-material'
import { Button, Stack, Typography } from '@mui/material'
import React from 'react'
import { useFormContext } from 'react-hook-form'
import { useIntl } from 'react-intl'
import { ClaimFormData, ClaimFormFields } from './ClaimDialog'

interface orgCreateStepProps {
  onPreviousStep: () => void
  onNextStep: () => void
}

const OrgCreateStep: React.FC<orgCreateStepProps> = ({ onPreviousStep, onNextStep }) => {
  const { formatMessage } = useIntl()
  const { register, errors, handleSubmit, setValue, setError, watch } = useFormContext<ClaimFormData>()

  const locationWatch = watch(ClaimFormFields.Location)

  const handleLocationValue = (searchResult: SearchResult) =>
    setValue(ClaimFormFields.Location, {
      address: searchResult.candidates[0].attributes.LongLabel,
      country: searchResult.candidates[0].attributes.Country,
      region: searchResult.candidates[0].attributes.Region,
      subRegion: searchResult.candidates[0].attributes.Subregion,
      city: searchResult.candidates[0].attributes.City,
      district: searchResult.candidates[0].attributes.District,
      lat: searchResult.candidates[0].location.y,
      lng: searchResult.candidates[0].location.x,
    })

  const handleNextStep = async (): Promise<void> => {
    if (!locationWatch?.country) {
      setError(ClaimFormFields.Address, {
        type: 'manual',
        message: formatMessage({ id: 'claim.createClaim.orgCreateStep.addressErrorMessage' }),
      })
      return
    }

    onNextStep()
  }

  return (
    <OnboardingForm
      preHeader={formatMessage({ id: 'claim.createClaim.orgCreateStep.preHeader' })}
      header={formatMessage({ id: 'claim.createClaim.orgCreateStep.header' })}
      subheader={formatMessage({ id: 'claim.createClaim.orgCreateStep.subHeader' })}
    >
      <TextField
        fullWidth
        hoveringLabel
        placeholder={formatMessage({ id: 'textFieldPlaceholders.organizationName' })}
        label={formatMessage({ id: 'claim.createClaim.orgCreateStep.name' })}
        type="text"
        name={ClaimFormFields.Name}
        inputRef={register({
          required: formatMessage({ id: 'form.validation.required' }),
          minLength: {
            value: 2,
            message: formatMessage({ id: 'errorMessages.general.minLength' }, { count: 2 }),
          },
        })}
        required
        error={Boolean(errors?.name)}
        helperText={errors?.name?.message}
        onClear={(): void => setValue(ClaimFormFields.Name, '')}
        data-testid="claim-form-field-name"
      />
      <ControlledLocationField
        label={formatMessage({ id: 'claim.createClaim.orgCreateStep.address' })}
        locationName={ClaimFormFields.Location}
        addressName={ClaimFormFields.Address}
        rules={{ required: formatMessage({ id: 'form.validation.required' }) }}
        valueSetter={handleLocationValue}
        fieldErrors={errors?.location?.address}
      />
      <Stack spacing={2}>
        <Typography variant="button">
          {formatMessage({ id: 'claim.createClaim.orgCreateStep.addressInfoHeader' })}
        </Typography>
        <Stack direction="row" alignItems="center" spacing={2}>
          <CheckCircleOutline />
          <Typography variant="body1">
            {formatMessage({ id: 'claim.createClaim.orgCreateStep.addressInfoStepOne' })}
          </Typography>
        </Stack>
        <Stack direction="row" alignItems="center" spacing={2}>
          <CheckCircleOutline />
          <Typography variant="body1">
            {formatMessage({ id: 'claim.createClaim.orgCreateStep.addressInfoStepTwo' })}
          </Typography>
        </Stack>
      </Stack>

      <Stack pt={2} direction="row" justifyContent="space-between">
        <Button startIcon={<ArrowBack />} onClick={onPreviousStep}>
          {formatMessage({ id: 'general.back' })}
        </Button>
        <Button variant="contained" onClick={handleSubmit(handleNextStep)}>
          {formatMessage({ id: 'general.continue' })}
        </Button>
      </Stack>
    </OnboardingForm>
  )
}

export default OrgCreateStep
