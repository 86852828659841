import { Organization } from '@app/src/types/organizations'
import { Stack, Typography } from '@mui/material'
import React from 'react'

type OrganizationInfoTextProps = {
  organization: Organization
}

const OrganizationInfoText: React.FC<OrganizationInfoTextProps> = ({ organization }) => {
  const concatenatedDetails = [
    organization.country?.name,
    organization.websiteAddress,
    organization.registrationNumber,
    organization.vatNumber,
  ]
    .filter(Boolean)
    .join('・')

  return (
    <Stack>
      <Typography variant="caption" sx={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
        {concatenatedDetails}
      </Typography>
    </Stack>
  )
}

export default OrganizationInfoText
