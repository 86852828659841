import { CheckboxProps } from '@mui/material'
import { FetchKey, useFetchCollectionWithPost } from '../../api/fetchHooks'
import { FilterGroup } from '../../pages/ResourceCollection/Filters/useFilters'
import { Sort } from '../../types/filter'
import { useSelectableRows } from './useSelectableRows'

interface BasePayload {
  filter: FilterGroup[]
  pagination?: {
    pageNumber: number
    itemsPerPage: number
  }
  sort: Sort
  include: string[]
}

interface UseSelectableRowsArgs<T, A> {
  rowsInPage: T[]
  initialState?: number[]
  fetchKey: FetchKey
  endpoint: string
  count: number
  basePayload?: BasePayload
  idSelector?: (row: A) => number
}

const EMPTY_PAYLOAD = { filter: [], include: [] }

export const useSelectableRowsWithPostSelectAll = <T extends { id: number }, A extends { id: number } = T>({
  rowsInPage,
  initialState = [],
  basePayload,
  fetchKey,
  endpoint,
  count,
  idSelector = (row: A) => row.id,
}: UseSelectableRowsArgs<T, A>) => {
  const { selectedRowsIds, setSelectedRowsIds, handleCheckboxChange } = useSelectableRows({ rowsInPage, initialState })

  const {
    items: allRows,
    isLoading: isLoadingAllRows,
    refetch: fetchAllRows,
  } = useFetchCollectionWithPost<A>({
    key: fetchKey,
    endpoint: endpoint,
    payload: {
      ...(basePayload ?? EMPTY_PAYLOAD),
      pagination: {
        itemsPerPage: count,
        pageNumber: 1,
      },
    },
    options: {
      enabled: false,
    },
  })

  const isAllSelected = () => {
    if (!allRows?.length) return false

    return allRows?.every(row => selectedRowsIds.includes(idSelector(row)))
  }

  const handleSelectAll = async () => {
    if (allRows?.length) {
      const allIds = allRows.map(row => idSelector(row))
      setSelectedRowsIds(prev => Array.from(new Set([...prev, ...allIds])))
      return
    }

    const { data } = await fetchAllRows()
    if (!data?.items) return

    const rowIds = data.items.map(item => idSelector(item))

    setSelectedRowsIds(prev => Array.from(new Set([...prev, ...rowIds])))
  }

  const isHeaderChecked = rowsInPage?.every(row => selectedRowsIds?.includes(row.id))

  const handleHeaderCheckboxChange: CheckboxProps['onChange'] = (_e, checked) => {
    if (checked) {
      setSelectedRowsIds(prev => Array.from(new Set([...prev, ...rowsInPage.map(row => row.id)])))
    } else {
      setSelectedRowsIds([])
    }
  }

  return {
    selectedRowsIds,
    setSelectedRowsIds,
    handleCheckboxChange,
    allRows,
    isLoadingAllRows: isLoadingAllRows,
    isAllSelected,
    handleSelectAll,
    isHeaderChecked,
    handleHeaderCheckboxChange,
  }
}
