import { FetchKey, useFetchFacets } from '@app/src/api/fetchHooks'
import FilterFacetSelect, {
  FilterFacetSelectProps,
  MultipleModeProps,
} from '@app/src/pages/ResourceCollection/Filters/FilterFacetSelect'
import { Sort } from '@app/src/types/filter'
import React, { useEffect, useMemo } from 'react'
import { FacetItem } from '..'
import { useFiltersContext } from './Filters'

type PreFetchedMultiFilterFacetProps = {
  endpoint: string
  onChange: MultipleModeProps['onChange']
  queueAfterFilters?: string[]
  sort?: Sort
  facetsName?: string
  isEnum?: boolean
  preSelectedValues?: Array<FacetItem['value']>
} & Omit<FilterFacetSelectProps, 'facetsParam' | 'multiple' | 'onChange'>

const PreFetchedMultiFilterFacet: React.FC<PreFetchedMultiFilterFacetProps> = ({
  endpoint,
  filterName,
  facetsName,
  implicitFilters = [],
  queueAfterFilters,
  value,
  fieldLabel,
  sort,
  isEnum,
  onChange,
  preSelectedValues,
  disabled,
  ...props
}) => {
  const { apiFilters } = useFiltersContext()
  const flatValue = [value].flat()
  const queuedAfterFilters = useMemo(
    () => queueAfterFilters?.every(filter => apiFilters.find(f => f.name === filter)),
    [apiFilters, queueAfterFilters],
  )

  const facetsNameToFetch = facetsName || filterName

  const {
    facets: [facetData],
    refetch,
  } = useFetchFacets({
    key: [FetchKey.FilterFacet, facetsNameToFetch],
    endpoint,
    facetsParam: [{ name: facetsNameToFetch, isEnum }],
    filter: [...implicitFilters, ...apiFilters.filter(filter => filter.name !== filterName)].flat(),
    sort,
    options: { enabled: !disabled && !queueAfterFilters?.length && !flatValue?.[0] },
  })

  useEffect(() => {
    // Only run this effect when facetData changes and we have preSelectedValues
    if (!facetData?.length || flatValue?.[0]) return
    onChange(
      preSelectedValues
        ? facetData
            .filter(
              facet =>
                preSelectedValues.includes(facet.label) ||
                preSelectedValues.map(i => i.toString()).includes(facet.value.toString()),
            )
            .map(facet => facet.value.toString())
        : [facetData[0].value.toString()],
    )
  }, [facetData, flatValue, preSelectedValues])

  useEffect(() => {
    if (!facetData?.length && flatValue?.[0] && !disabled) {
      refetch()
    }
  }, [facetData, flatValue, disabled])

  useEffect(() => {
    if (queuedAfterFilters) {
      refetch()
    }
  }, [queuedAfterFilters])

  return (
    <FilterFacetSelect
      implicitFilters={implicitFilters}
      multiple
      facetsParam={{
        key: [FetchKey.FilterFacet, facetsNameToFetch],
        endpoint,
        facetsParam: [{ name: facetsNameToFetch, isEnum }],
        sort,
        options: { enabled: false },
      }}
      filterName={filterName}
      onChange={onChange}
      fieldLabel={fieldLabel}
      value={flatValue}
      {...props}
    />
  )
}

export default PreFetchedMultiFilterFacet
