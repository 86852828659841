import endpoints from '@app/src/api/endpoints'
import { FetchKey, useFetchResource } from '@app/src/api/fetchHooks'
import { useUpdateResource } from '@app/src/api/updateHooks'
import { useSnackbar } from '@app/src/context/SnackbarContext'
import useErrorNotification from '@app/src/hooks/errorNotification'
import { NotificationSeverity, OrganizationSettings, SpendIntervalType } from '@app/src/wf-constants'
import { useIntl } from 'react-intl'
import { useQueryClient } from 'react-query'

const useOrganizationSettings = () => {
  const { formatMessage } = useIntl()
  const { showSnackbar } = useSnackbar()
  const { showErrorNotification } = useErrorNotification()
  const queryClient = useQueryClient()
  const { mutateAsync, isLoading: isSaving, isError: updateError } = useUpdateResource<Partial<OrganizationSettings>>()
  const {
    data: settings,
    isLoading,
    isFetching,
    error: fetchError,
  } = useFetchResource<OrganizationSettings>({
    key: FetchKey.OrganizationSettings,
    endpoint: endpoints.organizationSettings,
  })
  const updateOrganizationSettings = async (settings: Partial<OrganizationSettings>, customSuccessMessage?: string) => {
    await mutateAsync(
      {
        url: endpoints.organizationSettings,
        body: settings,
      },
      {
        onError: error => {
          showErrorNotification({ requestError: error })
        },
        onSuccess: () => {
          queryClient.invalidateQueries(FetchKey.OrganizationSettings)
          showSnackbar({
            message: customSuccessMessage
              ? customSuccessMessage
              : formatMessage({ id: 'general.organizationSettingsSaved' }),
            severity: NotificationSeverity.success,
          })
        },
      },
    )
  }

  const set = async (
    key: keyof OrganizationSettings,
    value: number | boolean | SpendIntervalType,
    customSuccessMessage?: string,
  ) => {
    await updateOrganizationSettings({ [key]: value }, customSuccessMessage)
  }

  const get = <T = number | boolean | SpendIntervalType>(key: keyof OrganizationSettings) => {
    return settings?.[key] as T
  }

  const getAll = () => {
    return settings ?? ({} as OrganizationSettings)
  }

  return {
    set,
    get,
    setMultiple: updateOrganizationSettings,
    getAll,
    isSaving,
    updateError,
    fetchError,
    isLoading,
    isFetching,
  }
}

export default useOrganizationSettings
