import endpoints from '@app/src/api/endpoints'
import { FetchKey, useFetchCollectionWithPost } from '@app/src/api/fetchHooks'
import Table from '@app/src/components/Table'
import usePagination from '@app/src/hooks/pagination'
import useSort from '@app/src/hooks/sorting'
import ResponseItemCompanyHeader from '@app/src/pages/ResourceCollection/Collections/DataHub/AllAnswers/ResponseItemCompanyHeader'
import ResponseItemCompanyRow from '@app/src/pages/ResourceCollection/Collections/DataHub/AllAnswers/ResponseItemCompanyRow'
import { FilterGroup, Operators } from '@app/src/pages/ResourceCollection/Filters/useFilters'
import {
  AssessmentTemplate,
  Question,
  QuestionAnswerClassificationRule,
  ResponseItem,
} from '@app/src/types/resourceExplorer'
import { Close } from '@mui/icons-material'
import { Box, Dialog, IconButton, Stack, Typography } from '@mui/material'
import { maxBy } from 'lodash'
import React, { useMemo } from 'react'
import { useIntl } from 'react-intl'

type AssessmentInsightsChartsDialogProps = {
  isOpen: boolean
  onClose: () => void
  responseItemsFilters?: FilterGroup[]
  assessmentTemplate: AssessmentTemplate
  question: Question
  period: string
  classificationRule?: QuestionAnswerClassificationRule
  classificationRuleName?: string
}

const AssessmentInsightsChartsDialog: React.FC<AssessmentInsightsChartsDialogProps> = ({
  isOpen,
  onClose,
  responseItemsFilters,
  assessmentTemplate,
  question,
  period,
  classificationRule,
  classificationRuleName,
}) => {
  const { formatMessage } = useIntl()
  const { sorting, toggleSorting } = useSort()
  const [page, pageSize, setPage, setPageSize] = usePagination()

  const {
    items: responseItems,
    isFetched: isResponseItemsFetched,
    count,
    isError,
  } = useFetchCollectionWithPost<ResponseItem>({
    endpoint: endpoints.responseItemsCollection,
    key: FetchKey.ResponseItemsCollection,
    payload: {
      sort: sorting,
      pagination: { pageNumber: page, itemsPerPage: pageSize },
      filter: [
        ...(responseItemsFilters ?? []),
        {
          name: 'questionAnswerClassificationResults.questionAnswerClassificationRuleId',
          filters: [{ operator: Operators.EqualTo, value: classificationRule?.id }],
        },
      ],
      include: [
        'requestItem',
        'requestItem.unit',
        'response.request',
        'response.request.subscriptions.target',
        'response.request.target.organization',
        'files',
        'verifications',
        'questionAnswerClassificationResults',
      ],
    },
    options: {
      enabled: Boolean(isOpen && responseItemsFilters && classificationRule),
      staleTime: 60000,
    },
  })

  const latestResponseItems = useMemo(() => {
    if (!isResponseItemsFetched) return undefined

    // Group responseItems by organization and question
    const responseItemsGroupedByOrgAndQuestion = Object.groupBy(
      responseItems,
      ri => `${ri.creatorOrganizationId}|${ri.requestItem.questionId}`,
    )

    // Get only the latest submitted responseItems per group
    const latestResponseItemGroupEntries = Object.entries(responseItemsGroupedByOrgAndQuestion).map(
      ([key, responseItems]) => {
        return {
          key,
          responseItem: maxBy(responseItems ?? [], ri => new Date(ri.response.submittedAt).getTime()),
        }
      },
    )

    return latestResponseItemGroupEntries.map(g => g.responseItem) as ResponseItem[]
  }, [responseItems])

  const breadcrumbs = useMemo(
    () =>
      [
        assessmentTemplate.name,
        question?.kpiName ||
          (question.questionText.length > 60 ? `${question.questionText.slice(0, 55)}...` : question.questionText),
        period,
        classificationRule?.rank,
        classificationRuleName,
      ]
        .filter(Boolean)
        .join(' / '),
    [assessmentTemplate, question, period, classificationRule, classificationRuleName],
  )

  return (
    <Dialog open={isOpen} onClose={onClose} fullScreen sx={{ m: 10 }} PaperProps={{ sx: { borderRadius: 2 } }}>
      <Stack height="100%">
        <Stack direction="row" justifyContent="space-between" alignItems="center" width="100%" py={2} px={4}>
          <Typography variant="h6">
            {formatMessage(
              { id: 'assessments.insights.chartsDialog.header' },
              { assessmentName: assessmentTemplate.name },
            )}
          </Typography>
          <IconButton onClick={onClose}>
            <Close />
          </IconButton>
        </Stack>

        <Box px={4} py={1} bgcolor="grey.300">
          <Typography color="textSecondary">{breadcrumbs}</Typography>
        </Box>

        <Stack flexGrow={1} height={10} pl={4} bgcolor="grey.100">
          <Table<ResponseItem>
            data={latestResponseItems ?? []}
            isLoading={!latestResponseItems || !isResponseItemsFetched}
            isError={isError}
            count={count}
            page={page}
            pageSize={pageSize}
            setPage={setPage}
            setPageSize={setPageSize}
            HeaderComponent={() => (
              <ResponseItemCompanyHeader toggleSorting={toggleSorting} activeSorting={sorting} disableRowSelection />
            )}
            RowComponent={props => (
              <ResponseItemCompanyRow {...props} showAnswerClassificationResults drawer={false} disableRowSelection />
            )}
          />
        </Stack>
      </Stack>
    </Dialog>
  )
}

export default AssessmentInsightsChartsDialog
