import { CheckboxProps } from '@mui/material'
import { useEffect, useState } from 'react'
interface UseSelectableRowsArgs<T> {
  rowsInPage: T[]
  initialState?: number[]
}

export const useSelectableRows = <T extends { id: number }>({
  rowsInPage,
  initialState = [],
}: UseSelectableRowsArgs<T>) => {
  const [selectedRowsIds, setSelectedRowsIds] = useState<Array<number>>(initialState)

  const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>, row: T) => {
    if (e.target.checked) {
      setSelectedRowsIds(prev => [...prev, row.id])
    } else {
      setSelectedRowsIds(prev => prev.filter(rowId => rowId !== row.id))
    }
  }

  const isHeaderChecked = rowsInPage?.every(row => selectedRowsIds?.includes(row.id))

  const handleHeaderCheckboxChange: CheckboxProps['onChange'] = (_e, checked) => {
    if (checked) {
      setSelectedRowsIds(prev => Array.from(new Set([...prev, ...rowsInPage.map(row => row.id)])))
    } else {
      setSelectedRowsIds([])
    }
  }

  useEffect(() => {
    if (rowsInPage.length === 0) return
    setSelectedRowsIds(prev => prev.filter(rowId => rowsInPage.map(row => row.id).includes(rowId)))
  }, [rowsInPage])

  return {
    selectedRowsIds,
    setSelectedRowsIds,
    handleCheckboxChange,
    isHeaderChecked,
    handleHeaderCheckboxChange,
  }
}
