import React, { useMemo, useState } from 'react'
import { AnswersGroupingType } from '../pages/ResourceCollection/Filters/StatisticsConstants'
import GroupBySelectorContext from './GroupBySelectorContext'

interface Props {
  children: JSX.Element
}

const GroupBySelectorContextProvider = ({ children }: Props): JSX.Element => {
  const [groupBy, setGroupBy] = useState<AnswersGroupingType>(AnswersGroupingType.PeriodName)

  const contextValue = useMemo(
    () => ({
      groupBy,
      setGroupBy,
    }),
    [groupBy, setGroupBy],
  )

  return <GroupBySelectorContext.Provider value={contextValue}>{children}</GroupBySelectorContext.Provider>
}

export default GroupBySelectorContextProvider
