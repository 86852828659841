import { useDrawer } from '@app/src/components/Drawer/DrawerContext'
import DrawerViewInternalVerification, {
  InternalVerificationFormData,
  InternalVerificationObjectType,
} from '@app/src/components/Drawer/Views/Verification/DrawerViewInternalVerification'
import { useAmplitude } from '@app/src/context/AmplitudeContext'
import useAccessorQuestionnaire from '@app/src/pages/Questionnaire/hooks/useAccessorQuestionnaire'
import { Response } from '@app/src/types/resourceExplorer'
import { AmplitudeTrackingEvents } from '@app/src/wf-constants'
import React from 'react'
import { useIntl } from 'react-intl'

type DrawerViewResponseInternalVerificationProps = {
  response: Response
  onVerificationSubmitted: () => void
}

const DrawerViewResponseInternalVerification: React.FC<DrawerViewResponseInternalVerificationProps> = ({
  response,
  onVerificationSubmitted,
}) => {
  const { closeDrawer } = useDrawer()
  const { internalReviewResponse, isLoadingCreateVerification } = useAccessorQuestionnaire()
  const { formatMessage } = useIntl()
  const { trackEvent } = useAmplitude()

  const onClickSubmit = async (formData: InternalVerificationFormData) => {
    await internalReviewResponse({
      internalVerificationStatus: formData.internalVerificationStatus,
      internalVerificationComment: formData.internalVerificationComment,
      responseId: response.id,
    })
    trackEvent({
      name: AmplitudeTrackingEvents.Accessor.ResponseVerification.InternalVerification.Submitted,
      eventProps: {
        verificationStatus: formData.internalVerificationStatus,
        hasComment: Boolean(formData.internalVerificationComment),
      },
    })
    onVerificationSubmitted()
    closeDrawer()
  }

  return (
    <DrawerViewInternalVerification
      onFormSubmit={onClickSubmit}
      verification={response.verifications?.[0]}
      isLoading={isLoadingCreateVerification}
      objectType={InternalVerificationObjectType.Response}
      subtitle={formatMessage(
        { id: 'reporting.verification.responseVerificationSubHeader' },
        { questionsCount: response.items.length },
      )}
    />
  )
}

export default DrawerViewResponseInternalVerification
