import { useDrawer } from '@app/src/components/Drawer/DrawerContext'
import DrawerView, { DrawerViewProps } from '@app/src/components/Drawer/DrawerView'
import Select, { Option } from '@app/src/components/Form/Select/ControlledSelect'
import ControlledTextFieldWithFormatting from '@app/src/components/TextFieldWithFormatting/ControlledTextFieldWithFormatting'
import { useAmplitude } from '@app/src/context/AmplitudeContext'
import useAccessorQuestionnaire from '@app/src/pages/Questionnaire/hooks/useAccessorQuestionnaire'
import { FacetItem } from '@app/src/pages/ResourceCollection'
import useSelectedAnswersData from '@app/src/pages/ResourceCollection/Collections/DataHub/AllAnswers/useSelectedAnswersData'
import { FilterGroup } from '@app/src/pages/ResourceCollection/Filters/useFilters'
import { ExternalVerificationStatus } from '@app/src/types/resourceExplorer'
import { insertIf } from '@app/src/utils/helpersTs'
import { AmplitudeTrackingEvents } from '@app/src/wf-constants'
import { EditOutlined } from '@mui/icons-material'
import { Avatar, List, ListItem, ListItemAvatar, ListItemText, Stack } from '@mui/material'
import { useConfirm } from 'material-ui-confirm'
import React, { useEffect } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useIntl } from 'react-intl'

const MULTIPLE_STATUSES = 'MultipleStatuses'

type VerificationStatusOptions = ExternalVerificationStatus | typeof MULTIPLE_STATUSES

type DrawerViewVerificationProps = {
  itemIds: number[]
  filters?: FilterGroup[]
} & Omit<DrawerViewProps, 'title'>

interface FormData {
  externalVerificationStatus: VerificationStatusOptions
  externalVerificationComment: string
}

const hasMultipleStatuses = (externalVerificationStatuses: FacetItem[], selectedItemsCount: number) => {
  return (
    Boolean(externalVerificationStatuses?.length) &&
    (externalVerificationStatuses?.length > 1 || externalVerificationStatuses?.[0]?.count !== selectedItemsCount)
  )
}

const getPreselectedVerificationStatus = (externalVerificationStatuses: FacetItem[], selectedItemsCount: number) => {
  if (hasMultipleStatuses(externalVerificationStatuses, selectedItemsCount)) return MULTIPLE_STATUSES
  return (
    (externalVerificationStatuses?.[0]?.value.toString() as ExternalVerificationStatus) ??
    ExternalVerificationStatus.NotSet
  )
}

const DrawerViewBulkResponseItemExternalVerification: React.FC<DrawerViewVerificationProps> = ({
  itemIds,
  filters = [],
  ...props
}) => {
  const { formatMessage } = useIntl()
  const { closeDrawer } = useDrawer()
  const { trackEvent } = useAmplitude()
  const confirm = useConfirm()
  const { externalReviewAllItems, isLoadingCreateVerification } = useAccessorQuestionnaire()

  const formMethods = useForm<FormData>()

  const { reset, handleSubmit, register, watch, control } = formMethods

  const {
    externalVerificationStatuses,
    isLoadingExternalVerificationStatuses,
    externalVerificationComments,
    isLoadingExternalVerificationComments,
    selectedQuestionDescription,
    selectedQuestionIds,
  } = useSelectedAnswersData({
    filters,
    selectedRowsIds: itemIds,
  })

  const externalVerificationStatus: VerificationStatusOptions = watch('externalVerificationStatus')
  const defaultCommentValue =
    externalVerificationComments?.length === 1 ? externalVerificationComments[0].value.toString() : ''

  const getConfirmationText = (submittedStatus: VerificationStatusOptions) => {
    if (submittedStatus === ExternalVerificationStatus.CorrectionNeeded) {
      return formatMessage(
        { id: 'reporting.verification.bulkExternalVerification.correctionNeeded' },
        {
          answersCount: itemIds?.length,
          questionsCount: selectedQuestionIds?.length,
          hasMessage: Boolean(watch('externalVerificationComment')),
        },
      )
    }
    if (submittedStatus === ExternalVerificationStatus.Approved) {
      return formatMessage(
        { id: 'reporting.verification.bulkExternalVerification.approved' },
        {
          answersCount: itemIds?.length,
          questionsCount: selectedQuestionIds?.length,
          hasMessage: Boolean(watch('externalVerificationComment')),
        },
      )
    }
    return formatMessage(
      { id: 'reporting.verification.bulkExternalVerification.notSet' },
      { answersCount: itemIds?.length, questionsCount: selectedQuestionIds?.length },
    )
  }

  const onSubmit = async (submittedVerification: FormData) => {
    await confirm({
      content: getConfirmationText(submittedVerification.externalVerificationStatus),
      confirmationText: formatMessage({ id: 'general.confirmAndSubmit' }),
      cancellationButtonProps: { variant: 'text' },
      confirmationButtonProps: { variant: 'text' },
    }).then(async () => {
      if (submittedVerification.externalVerificationStatus === MULTIPLE_STATUSES) return
      await externalReviewAllItems({
        responseItemIds: itemIds,
        externalVerificationStatus: submittedVerification.externalVerificationStatus,
        responseItemsExternalVerificationComment: submittedVerification.externalVerificationComment,
      })
      trackEvent({
        name: AmplitudeTrackingEvents.Accessor.ResponseItemVerification.BulkExternalVerification.Submitted,
        eventProps: {
          responseItemsCount: itemIds.length,
          questionsCount: selectedQuestionIds?.length,
          verificationStatus: submittedVerification.externalVerificationStatus,
          hasComment: Boolean(submittedVerification.externalVerificationComment),
        },
      })
      closeDrawer()
    })
  }

  useEffect(() => {
    trackEvent({
      name: AmplitudeTrackingEvents.Accessor.ResponseItemVerification.BulkExternalVerification.Opened,
      eventProps: {
        responseItemsCount: itemIds.length,
        questionsCount: selectedQuestionIds?.length,
      },
    })
    reset({
      externalVerificationStatus: getPreselectedVerificationStatus(externalVerificationStatuses, itemIds.length),
      externalVerificationComment: defaultCommentValue,
    })
  }, [externalVerificationStatuses, externalVerificationComments])

  if (isLoadingExternalVerificationStatuses || isLoadingExternalVerificationComments) return null

  return (
    <FormProvider {...formMethods}>
      <DrawerView
        stackButtons
        title={formatMessage({
          id: 'questionnaire.actions.review',
        })}
        subTitle={
          selectedQuestionDescription ??
          formatMessage(
            { id: 'reporting.verification.responseVerificationSubHeader' },
            { questionsCount: selectedQuestionIds?.length },
          )
        }
        buttons={[
          {
            label: formatMessage({ id: 'general.cancel' }),
            variant: 'outlined',
            disabled: isLoadingCreateVerification,
            onClick: closeDrawer,
          },
          {
            label: formatMessage({ id: 'general.submit' }),
            variant: 'contained',
            loading: isLoadingCreateVerification,
            disabled: externalVerificationStatus === MULTIPLE_STATUSES,
            type: 'submit',
          },
        ]}
        onFormSubmit={handleSubmit(onSubmit)}
        {...props}
      >
        <Stack px={3} flexGrow={1} spacing={2}>
          <input type="hidden" name="id" ref={register()} />

          <Select<VerificationStatusOptions>
            name="externalVerificationStatus"
            control={control}
            fieldLabel={formatMessage({ id: 'reporting.verification.verification' })}
            variant="outlined"
            options={[
              ...insertIf<Option<VerificationStatusOptions>>(
                hasMultipleStatuses(externalVerificationStatuses, itemIds.length),
                {
                  value: MULTIPLE_STATUSES,
                  label: formatMessage({ id: 'reporting.reviewStatuses.multipleStatuses' }),
                  disabled: true,
                },
              ),
              {
                value: ExternalVerificationStatus.NotSet,
                label: formatMessage({ id: 'reporting.statuses.ToBeVerified' }),
              },
              {
                value: ExternalVerificationStatus.Approved,
                label: formatMessage({ id: 'reporting.statuses.Approved' }),
              },
              {
                value: ExternalVerificationStatus.CorrectionNeeded,
                label: formatMessage({ id: 'reporting.statuses.CorrectionNeeded' }),
              },
            ]}
          />
          {externalVerificationStatus !== ExternalVerificationStatus.NotSet &&
            externalVerificationStatus !== MULTIPLE_STATUSES && (
              <ControlledTextFieldWithFormatting
                name="externalVerificationComment"
                control={control}
                defaultValue={defaultCommentValue}
                placeholder={
                  externalVerificationStatus === ExternalVerificationStatus.CorrectionNeeded
                    ? formatMessage({ id: 'reporting.verification.addGuidanceForCorrection' })
                    : formatMessage({ id: 'reporting.verification.addOptionalMessage' })
                }
                multiline
                size="medium"
                hideUndo
                fillHeight
                maxLength={1000}
              />
            )}
          {externalVerificationStatus === ExternalVerificationStatus.CorrectionNeeded && (
            <List sx={{ mt: 2 }}>
              <ListItem disablePadding alignItems="flex-start">
                <ListItemAvatar>
                  <Avatar
                    sx={({ palette }) => ({
                      color: palette.warning.contrastText,
                      bgcolor: palette.warning.light,
                    })}
                  >
                    <EditOutlined />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText
                  primaryTypographyProps={{ variant: 'subtitle1' }}
                  primary={formatMessage({
                    id: 'reporting.verification.correctionNeededChangedExplanation.nowOnQuestion.header',
                  })}
                  secondary={formatMessage({
                    id: 'reporting.verification.correctionNeededChangedExplanation.nowOnQuestion.textOnQuestionVerification',
                  })}
                  sx={{ m: 0 }}
                />
              </ListItem>
            </List>
          )}
        </Stack>
      </DrawerView>
    </FormProvider>
  )
}

export default DrawerViewBulkResponseItemExternalVerification
