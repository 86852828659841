import { useDrawer } from '@app/src/components/Drawer/DrawerContext'
import DrawerViewInternalVerification, {
  InternalVerificationFormData,
  InternalVerificationObjectType,
} from '@app/src/components/Drawer/Views/Verification/DrawerViewInternalVerification'
import { useAmplitude } from '@app/src/context/AmplitudeContext'
import useAccessorQuestionnaire from '@app/src/pages/Questionnaire/hooks/useAccessorQuestionnaire'
import { ResponseItem } from '@app/src/types/resourceExplorer'
import { AmplitudeTrackingEvents } from '@app/src/wf-constants'
import React from 'react'

type DrawerViewResponseItemInternalVerificationProps = {
  responseItem: ResponseItem
  questionDescription: string
}

const DrawerViewResponseItemInternalVerification: React.FC<DrawerViewResponseItemInternalVerificationProps> = ({
  responseItem,
  questionDescription,
}) => {
  const { closeDrawer } = useDrawer()
  const { trackEvent } = useAmplitude()
  const { internalReviewResponseItems, isLoadingCreateVerification } = useAccessorQuestionnaire()

  const onClickSubmit = async (formData: InternalVerificationFormData) => {
    await internalReviewResponseItems({
      internalVerificationStatus: formData.internalVerificationStatus,
      internalVerificationComment: formData.internalVerificationComment,
      responseItemIds: [responseItem.id],
    })
    trackEvent({
      name: AmplitudeTrackingEvents.Accessor.ResponseItemVerification.InternalVerification.Submitted,
      eventProps: {
        verificationStatus: formData.internalVerificationStatus,
        hasComment: Boolean(formData.internalVerificationComment),
      },
    })
    closeDrawer()
  }

  return (
    <DrawerViewInternalVerification
      onFormSubmit={onClickSubmit}
      verification={responseItem.verifications?.[0]}
      isLoading={isLoadingCreateVerification}
      subtitle={questionDescription}
      objectType={InternalVerificationObjectType.ResponseItem}
    />
  )
}

export default DrawerViewResponseItemInternalVerification
