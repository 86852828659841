import endpoints from '@app/src/api/endpoints'
import { useUpdateResourceWithoutBody } from '@app/src/api/updateHooks'
import LoadingButton from '@app/src/components/LoadingButton'
import TextField from '@app/src/components/Ui/TextField'
import { useAccessibleOrganizations } from '@app/src/context/AccessibleOrganizationsContext'
import { useAmplitude } from '@app/src/context/AmplitudeContext'
import OnboardingForm from '@app/src/pages/SolutionSelector/OnboardingForm'
import { OrganizationDuplicateMatch } from '@app/src/types/organizations'
import { isValidVatOrRegistrationNumber, validURL } from '@app/src/utils/helpers'
import { AmplitudeTrackingEvents } from '@app/src/wf-constants'
import { ArrowBack } from '@mui/icons-material'
import { Button, Stack } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { ErrorOption, useFormContext } from 'react-hook-form'
import { useIntl } from 'react-intl'
import { ClaimFormData, ClaimFormFields, ExistingOrganizationsData } from './ClaimDialog'

interface OrgDetailsStepProps {
  onExistingOrganizations: (data: ExistingOrganizationsData) => void
  onPreviousStep: () => void
}

const fetchLogo = async (values: ClaimFormData) => {
  if (!values.websiteAddress) return undefined
  const res = await fetch(`https://company.clearbit.com/v2/companies/find?domain=${values?.websiteAddress}`, {
    headers: new Headers({
      Authorization: `Bearer sk_6dfbd8ebfd74b3dfa3f628085c564a84`, //TODO: This should probably not be here 😬
    }),
  })
  const clearbitResponse = await res.json()
  return clearbitResponse.logo
}

const OrgDetailsStep: React.FC<OrgDetailsStepProps> = ({ onExistingOrganizations, onPreviousStep }) => {
  const { formatMessage } = useIntl()
  const { register, setValue, errors, handleSubmit, setError, clearErrors } = useFormContext<ClaimFormData>()
  const { trackEvent } = useAmplitude()
  const { accessibleOrganizations } = useAccessibleOrganizations()
  const [isLoading, setIsLoading] = useState(false)
  const { mutateAsync: checkExistingOrganization, isLoading: isLoadingExistingOrgs } = useUpdateResourceWithoutBody<
    OrganizationDuplicateMatch[]
  >({
    method: 'get',
  })

  const setLogo = async (values: ClaimFormData) => {
    setIsLoading(true)

    try {
      const logo = await fetchLogo(values)
      setValue(ClaimFormFields.Logo, logo)
    } catch (e) {
      console.warn('could not fetch info from clearbit')
    } finally {
      setIsLoading(false)
    }
  }

  const onNextClicked = async (values: ClaimFormData) => {
    if (!values.websiteAddress && !values.vatNumber && !values.registrationNumber) {
      const errorDetails: ErrorOption = {
        message: formatMessage({ id: 'claim.createClaim.orgDetailsStep.oneIsRequired' }),
        type: 'validate',
      }
      setError(ClaimFormFields.WebsiteAddress, errorDetails)
      setError(ClaimFormFields.VatNumber, errorDetails)
      setError(ClaimFormFields.RegistrationNumber, errorDetails)
      return
    }

    const logoPromise = setLogo(values)

    const existingOrganizationPromise = checkExistingOrganization({
      url: endpoints.potentialDuplicates(
        values.name,
        values.websiteAddress,
        values.vatNumber,
        values.location?.address,
        values.registrationNumber,
      ),
    })

    const [, existingOrganizationResult] = await Promise.allSettled([logoPromise, existingOrganizationPromise])

    if (existingOrganizationResult.status === 'rejected') return
    const existingOrganizationData = existingOrganizationResult.value

    const exactMatch = existingOrganizationData.find(d => d.matchType === 'ExactMatch')
    const approximateMatches = exactMatch ? [] : existingOrganizationData.map(o => o.organization)
    onExistingOrganizations({
      existingOrganizationData: exactMatch?.organization,
      similarOrganizationsData: approximateMatches,
    })
  }

  useEffect(() => {
    trackEvent({
      name: AmplitudeTrackingEvents.Onboarding.AddOrganization.OpenedOrgDetails,
      eventProps: {
        is_first_org: !accessibleOrganizations.length,
      },
    })
  }, [])

  return (
    <OnboardingForm
      preHeader={formatMessage({ id: 'claim.createClaim.orgDetailsStep.preHeader' })}
      header={formatMessage({ id: 'claim.createClaim.orgDetailsStep.header' })}
      subheader={formatMessage({ id: 'claim.createClaim.orgDetailsStep.subheader' })}
    >
      <TextField
        fullWidth
        hoveringLabel
        placeholder={formatMessage({ id: 'textFieldPlaceholders.website' })}
        label={formatMessage({ id: 'claim.createClaim.orgDetailsStep.website' })}
        type="text"
        name={ClaimFormFields.WebsiteAddress}
        inputRef={register({
          validate: (value: string): string | true =>
            value === '' || validURL(value) || formatMessage({ id: 'notifications.errorInvalidWebsite' }),
        })}
        error={Boolean(errors?.websiteAddress)}
        helperText={errors?.websiteAddress?.message}
        onClear={(): void => {
          clearErrors(ClaimFormFields.WebsiteAddress)
          setValue(ClaimFormFields.WebsiteAddress, '')
        }}
        data-testid="claim-form-field-website"
        sx={{ mb: 1 }}
      />
      <TextField
        fullWidth
        hoveringLabel
        placeholder={formatMessage({ id: 'textFieldPlaceholders.vatNumber' })}
        label={formatMessage({ id: 'claim.createClaim.orgDetailsStep.vatNumber' })}
        name={ClaimFormFields.VatNumber}
        inputRef={register({
          validate: (value: string) =>
            value === '' ||
            isValidVatOrRegistrationNumber(value) ||
            formatMessage({ id: 'notifications.errorInvalidVatNumber' }),
        })}
        error={Boolean(errors?.vatNumber)}
        helperText={errors?.vatNumber?.message}
        onClear={(): void => {
          clearErrors(ClaimFormFields.VatNumber)
          setValue(ClaimFormFields.VatNumber, '')
        }}
        sx={{ mb: 1 }}
      />
      <TextField
        fullWidth
        hoveringLabel
        placeholder={formatMessage({ id: 'textFieldPlaceholders.registrationNumber' })}
        label={formatMessage({ id: 'schemas.organization.registrationNumber' })}
        name={ClaimFormFields.RegistrationNumber}
        inputRef={register({
          validate: (value: string) =>
            value === '' ||
            isValidVatOrRegistrationNumber(value) ||
            formatMessage({ id: 'notifications.errorInvalidRegistrationNumber' }),
        })}
        error={Boolean(errors?.registrationNumber)}
        helperText={errors?.registrationNumber?.message}
        onClear={(): void => {
          clearErrors(ClaimFormFields.RegistrationNumber)
          setValue(ClaimFormFields.RegistrationNumber, '')
        }}
      />
      <Stack pt={2} direction="row" justifyContent="space-between">
        <Button startIcon={<ArrowBack />} onClick={onPreviousStep}>
          {formatMessage({ id: 'general.back' })}
        </Button>
        <LoadingButton
          type="submit"
          variant="contained"
          loading={isLoading || isLoadingExistingOrgs}
          onClick={handleSubmit(onNextClicked)}
        >
          {formatMessage({ id: 'general.continue' })}
        </LoadingButton>
      </Stack>
    </OnboardingForm>
  )
}

export default OrgDetailsStep
