import endpoints from '@app/src/api/endpoints'
import { FetchKey } from '@app/src/api/fetchHooks'
import { useCreateResource } from '@app/src/api/updateHooks'
import LoadingButton from '@app/src/components/LoadingButton'
import TextField from '@app/src/components/Ui/TextField'
import { useAccessibleOrganizations } from '@app/src/context/AccessibleOrganizationsContext'
import { useAccount } from '@app/src/context/AccountContext'
import { useAmplitude } from '@app/src/context/AmplitudeContext'
import { useSnackbar } from '@app/src/context/SnackbarContext'
import RequestError from '@app/src/errors/RequestError'
import useErrorNotification from '@app/src/hooks/errorNotification'
import { useUnsetReferral } from '@app/src/hooks/referral'
import OnboardingForm from '@app/src/pages/SolutionSelector/OnboardingForm'
import { Claim } from '@app/src/types/organizations'
import { AmplitudeTrackingEvents } from '@app/src/wf-constants'
import { PHONE_NUMBER_REGEX } from '@app/src/wf-constants/validationRegexes'
import { ArrowBack } from '@mui/icons-material'
import { Button, Checkbox, FormControlLabel, Stack, Typography } from '@mui/material'
import * as Sentry from '@sentry/react'
import React, { useEffect, useState } from 'react'
import { useFormContext } from 'react-hook-form'
import { useIntl } from 'react-intl'
import { useQueryClient } from 'react-query'
import { ClaimFormData, ClaimFormFields } from './ClaimDialog'

interface UserDetailsStepProps {
  onPreviousStep: () => void
  moveToReviewExplanationStep: () => void
  closeDialog: () => void
}

enum ClaimCreationResult {
  RequiresReview = 'RequiresReview',
  AutomaticallyApproved = 'AutomaticallyApproved',
}

const UserDetailsStep: React.FC<UserDetailsStepProps> = ({
  onPreviousStep,
  moveToReviewExplanationStep,
  closeDialog,
}) => {
  const { formatMessage } = useIntl()
  const { register, setValue, errors, handleSubmit } = useFormContext()
  const { mutateAsync, isLoading } = useCreateResource<ClaimCreationResult, Omit<Claim, 'id'>>()
  const [consent, setConsent] = useState(false)
  const { trackEvent } = useAmplitude()
  const { accessibleOrganizations } = useAccessibleOrganizations()
  const { account } = useAccount()
  const { showErrorNotification } = useErrorNotification()
  const unsetReferral = useUnsetReferral()
  const queryClient = useQueryClient()
  const { showSnackbar } = useSnackbar()

  const referralCode = account?.referralCode

  useEffect(() => {
    trackEvent({
      name: AmplitudeTrackingEvents.Onboarding.AddOrganization.OpenedPersonalDetails,
      eventProps: {
        is_first_org: !accessibleOrganizations.length,
      },
    })
  }, [])

  const saveClaim = async (claim: Omit<Claim, 'id'>): Promise<void> => {
    await mutateAsync(
      { url: endpoints.claims, body: claim },
      {
        onError: (error: RequestError) => {
          Sentry.captureException(error, {
            tags: { event: 'Error creating claim' },
            user: { id: String(account?.user?.id) },
            extra: { errorDetails: error.message, payload: claim },
          })
          showErrorNotification({ requestError: error })
        },
        onSuccess: async claimCreationResult => {
          trackEvent({
            name: AmplitudeTrackingEvents.Onboarding.AddOrganization.SubmittedForm,
            eventProps: {
              has_referral: Boolean(claim.details.referralCode),
              is_first_org: !accessibleOrganizations.length,
            },
          })
          if (claim.details.referralCode) {
            await unsetReferral()
          }
          if (claimCreationResult === ClaimCreationResult.RequiresReview) {
            await queryClient.invalidateQueries(FetchKey.Claim)
            moveToReviewExplanationStep()
          } else {
            await queryClient.invalidateQueries(FetchKey.AccessibleOrganizations)
            showSnackbar({
              message: formatMessage({ id: 'notifications.organizationAutomaticallyCreated' }),
              severity: 'success',
            })
            closeDialog()
          }
        },
      },
    )
  }

  const onSubmit = (values: ClaimFormData) => {
    const cleanFormValues = { ...values, ...values.industry, industry: undefined }
    delete cleanFormValues.industry

    const payload: Omit<Claim, 'id'> = {
      details: { ...cleanFormValues },
      objectType: 'organization',
    }

    if (referralCode) {
      const cleanedReferral = referralCode?.split(' ')[0]
      payload.details.referralCode = cleanedReferral
    }
    saveClaim(payload)
  }

  return (
    <OnboardingForm
      preHeader={formatMessage({ id: 'claim.createClaim.userDetailsStep.preHeader' })}
      header={formatMessage({ id: 'claim.createClaim.userDetailsStep.header' })}
    >
      <TextField
        fullWidth
        hoveringLabel
        placeholder={formatMessage({ id: 'textFieldPlaceholders.userJobTitle' })}
        label={formatMessage({ id: 'claim.createClaim.userDetailsStep.jobTitle' })}
        type="text"
        name={ClaimFormFields.JobTitle}
        inputRef={register()}
        error={Boolean(errors?.jobTitle)}
        helperText={errors?.jobTitle?.message}
        onClear={(): void => setValue(ClaimFormFields.JobTitle, '')}
      />
      <TextField
        fullWidth
        hoveringLabel
        placeholder={formatMessage({ id: 'textFieldPlaceholders.userPhoneNumber' })}
        label={formatMessage({ id: 'claim.createClaim.userDetailsStep.phoneNumber' })}
        type="text"
        name={ClaimFormFields.PhoneNumber}
        inputRef={register({
          pattern: {
            value: PHONE_NUMBER_REGEX,
            message: formatMessage({ id: 'notifications.errorInvalidPhoneNumber' }),
          },
        })}
        error={Boolean(errors?.phoneNumber)}
        helperText={errors?.phoneNumber?.message}
        onClear={(): void => setValue(ClaimFormFields.PhoneNumber, '')}
      />
      <FormControlLabel
        sx={{ pt: 2 }}
        control={
          <Checkbox
            checked={consent}
            onChange={(): void => setConsent(!consent)}
            name={ClaimFormFields.InternalUsageConsent}
          />
        }
        labelPlacement="end"
        label={
          <Typography pl={2} variant="caption">
            {formatMessage({ id: 'claim.claimConsent' })}
          </Typography>
        }
      />
      <Stack pt={2} direction="row" justifyContent="space-between">
        <Button type="submit" variant="text" startIcon={<ArrowBack />} onClick={onPreviousStep}>
          {formatMessage({ id: 'general.back' })}
        </Button>
        <LoadingButton
          type="submit"
          variant="contained"
          onClick={handleSubmit(onSubmit)}
          disabled={!consent}
          loading={isLoading}
        >
          {formatMessage({ id: 'general.submit' })}
        </LoadingButton>
      </Stack>
    </OnboardingForm>
  )
}

export default UserDetailsStep
