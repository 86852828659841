import PeriodSelector, { Periods } from '@app/src/components/CreateNewRequestModal/PeriodSelector/PeriodSelector'
import { numberIsSet } from '@app/src/utils/helpers'
import { makeStyles } from '@mui/styles'
import React, { useEffect, useMemo } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useIntl } from 'react-intl'

import endpoints from '@app/src/api/endpoints'
import ProviderSelectorWithFilters from '@app/src/components/CreateNewRequestModal/ProviderSelectorWithFilters'
import CreationModalContainer from '@app/src/components/CreationModal/CreationModalContainer'
import ControlledDateField from '@app/src/components/Form/ControlledDateField'
import { Option } from '@app/src/components/Form/Select'
import Select from '@app/src/components/Form/Select/ControlledSelect'
import { useCreationModalProgress } from '@app/src/context/CreationModalProgressContext'
import { SortOrder } from '@app/src/types/filter'
import { Request } from '@app/src/types/resourceExplorer'
import { Alert } from '@mui/material'
import { BaseFormData } from '..'
import RequestQuestionStep from '../RequestQuestionStep'
import ReviewTable from '../ReviewTable'

const useStyles = makeStyles(({ spacing }) => ({
  infoAlert: {
    marginTop: spacing(2),
  },
}))

type CompanyReportProps = {
  onClose: () => void
  onBack: () => void
}

export type FormData = BaseFormData

export enum COMPANY_REPORT_QUESTION_KEYS {
  TEMPLATE_IDS = 'templateIds',
  RESPONDERS = 'responders',
  DEADLINE = 'deadline',
  REVIEW = 'review',
  DATE_TYPE = 'dateType',
  DATE_YEAR = 'dateYear',
  DATE_PERIOD = 'datePeriod',
  ACTIVATE_AT = 'activateAt',
}

const CompanyReport: React.FC<CompanyReportProps> = ({ onClose, onBack }) => {
  const { formatMessage } = useIntl()
  const { activeStep, setTotalSteps } = useCreationModalProgress()
  const classes = useStyles()

  const formMethods = useForm<FormData>({
    shouldUnregister: false,
    defaultValues: { templateIds: [], responders: [], dateType: Periods.Yearly, deadline: '' },
  })
  const { control, getValues, setValue } = formMethods

  const WF_REPORT_QUESTIONS = [
    {
      title: formatMessage({ id: 'form.createRequest.selectTemplateTitle' }),
      description: formatMessage({ id: 'form.createRequest.selectTemplateBody' }),
      fieldnames: [COMPANY_REPORT_QUESTION_KEYS.TEMPLATE_IDS],
      children: (
        <Select<{ title: string; id: number }, Request>
          name={COMPANY_REPORT_QUESTION_KEYS.TEMPLATE_IDS}
          fieldLabel={formatMessage({ id: 'form.createRequest.selectTemplateLabel' })}
          control={control}
          required
          enableAutoSelect
          multiple
          forceFetch
          navigation={{
            url: endpoints.requestTemplates,
            type: 'post',
            postObject: {
              include: [],
              filter: [],
              sort: { target: 'title', order: SortOrder.ASCENDING },
            },
          }}
          findSelectedValue={(value, option): boolean => value?.id === option?.value?.id}
          objectToOption={({ title, id }: Request): Option<{ title: string; id: number }> => ({
            label: title,
            value: { title, id },
          })}
        />
      ),
    },
    {
      title: formatMessage({ id: 'form.createRequest.selectPeriodTitle' }),
      description: formatMessage({ id: 'form.createRequest.selectPeriodBodyCompany' }),
      fieldnames: [
        COMPANY_REPORT_QUESTION_KEYS.DATE_TYPE,
        COMPANY_REPORT_QUESTION_KEYS.DATE_YEAR,
        COMPANY_REPORT_QUESTION_KEYS.DATE_PERIOD,
      ],
      children: <PeriodSelector />,
      canGoNext: (fieldValues: unknown) => {
        const formData = fieldValues as FormData
        if (formData.dateType === Periods.NoPeriod) return true
        if (formData.dateType === Periods.Yearly) return numberIsSet(formData.dateYear)
        return numberIsSet(formData.dateYear) && numberIsSet(formData.datePeriod)
      },
    },
    {
      title: formatMessage({ id: 'form.createRequest.selectCompaniesTitle' }),
      description: formatMessage({ id: 'form.createRequest.selectCompaniesBody' }),
      fieldnames: [COMPANY_REPORT_QUESTION_KEYS.RESPONDERS],
      children: <ProviderSelectorWithFilters control={control} setValue={setValue} />,
    },
    {
      title: formatMessage({ id: 'form.createRequest.selectDeadlineTitle' }),
      description: formatMessage({ id: 'form.createRequest.selectDeadlineBody' }),
      fieldnames: [COMPANY_REPORT_QUESTION_KEYS.DEADLINE],
      hasSkipButton: true,
      children: (
        <ControlledDateField
          disablePast
          fieldLabel={formatMessage({ id: 'form.createRequest.selectDeadlineLabel' })}
          control={control}
          name="deadline"
          slotProps={{ textField: { fullWidth: true } }}
        />
      ),
    },
    {
      title: formatMessage({ id: 'form.createRequest.reviewTitle' }),
      description: (
        <Alert severity="info" className={classes.infoAlert}>
          {formatMessage({ id: 'form.createRequest.reviewInfo' })}
        </Alert>
      ),
      fieldnames: [],
      children: <ReviewTable data={getValues()} />,
    },
  ]

  const activeQuestion = useMemo(() => WF_REPORT_QUESTIONS[activeStep - 1], [activeStep])

  useEffect(() => {
    setTotalSteps(WF_REPORT_QUESTIONS.length)
  }, [])

  return (
    <CreationModalContainer title={formatMessage({ id: 'form.createRequest.companyReportTitle' })} onClose={onClose}>
      <FormProvider {...formMethods}>
        {activeQuestion && (
          <RequestQuestionStep
            fieldnames={activeQuestion.fieldnames}
            title={activeQuestion.title}
            description={activeQuestion.description}
            hasSkipButton={activeQuestion.hasSkipButton}
            key={activeQuestion.title}
            onClose={onClose}
            onBack={onBack}
            canGoNext={activeQuestion.canGoNext}
          >
            {React.cloneElement(activeQuestion.children, { fieldnames: activeQuestion.fieldnames })}
          </RequestQuestionStep>
        )}
      </FormProvider>
    </CreationModalContainer>
  )
}

export default CompanyReport
