import { ChartType } from '@app/src/pages/ResourceCollection/Collections/Assessments/ChartTypeSelector'
import {
  NumberMetricSelector,
  SelectedMetric,
} from '@app/src/pages/ResourceCollection/Collections/DataHub/DataInsights/NumberMetricSelector'
import { Unit } from '@app/src/types/resourceExplorer'
import { shouldShowSum } from '@app/src/utils/statisticsUtils'
import { Box } from '@mui/material'
import ReactEChartsCore from 'echarts-for-react/lib/core'
import * as echarts from 'echarts/core'
import React, { useMemo, useState } from 'react'
import { StatisticsForQuestionPerPeriod } from '../../DataHubScene'
import { useDataInsightsModal } from './DataInsightsModalContext'
import { EchartClickItemData } from './OptionMultiPeriodChart'
import { useNumberMultiPeriodChartConfigs } from './useNumberMultiPeriodChartConfigs'

interface NumberMultiPeriodChartProps {
  statistics: StatisticsForQuestionPerPeriod
  height: string
  echartsRef: React.MutableRefObject<ReactEChartsCore | null>
  unit: Unit
  selectedChartType: ChartType
}

export const NumberMultiPeriodChart: React.FC<NumberMultiPeriodChartProps> = ({
  statistics,
  height,
  echartsRef,
  unit,
  selectedChartType,
}) => {
  const showSum = shouldShowSum(unit)

  const [selectedMetric, setSelectedMetric] = useState<SelectedMetric>(
    showSum ? SelectedMetric.Sum : SelectedMetric.Average,
  )
  const { openDataInsightsModal } = useDataInsightsModal()

  const events = useMemo(
    () => ({
      click: ({ data }: { data: EchartClickItemData }) => {
        const userSelection = {
          group: data.group,
        }
        openDataInsightsModal(statistics, userSelection)
      },
    }),
    [openDataInsightsModal, statistics],
  )

  const options = useNumberMultiPeriodChartConfigs({
    statistics,
    selectedChartType,
    unit,
    selectedMetric,
  })

  return (
    <Box height={height} display="flex" flexDirection="column">
      <NumberMetricSelector selectedMetric={selectedMetric} setSelectedMetric={setSelectedMetric} showSum={showSum} />
      <ReactEChartsCore
        onEvents={events}
        option={options}
        echarts={echarts}
        style={{ minWidth: '100%', flexGrow: 1 }}
        ref={echartsRef}
      />
    </Box>
  )
}
