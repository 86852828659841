import endpoints from '@app/src/api/endpoints'
import { FetchKey } from '@app/src/api/fetchHooks'
import { useUpdateResource } from '@app/src/api/updateHooks'
import LoadingButton from '@app/src/components/LoadingButton'
import TextField from '@app/src/components/Ui/TextField'
import { AccountContextProps, NavbarUser } from '@app/src/context/AccountContext'
import { useAmplitude } from '@app/src/context/AmplitudeContext'
import { useSnackbar } from '@app/src/context/SnackbarContext'
import useErrorNotification from '@app/src/hooks/errorNotification'
import { br } from '@app/src/utils/translationMarkup'
import {
  AmplitudeTrackingEvents,
  ExternalLinks,
  NotificationSeverity,
  ResourceTypes,
  Solutions,
} from '@app/src/wf-constants'
import { PHONE_NUMBER_REGEX } from '@app/src/wf-constants/validationRegexes'
import { Box, FormControlLabel, Stack, Typography } from '@mui/material'
import Checkbox from '@mui/material/Checkbox'
import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useIntl } from 'react-intl'
import { useQueryClient } from 'react-query'

interface Props {
  account: AccountContextProps['account']
}

enum FormFields {
  givenName = 'givenName',
  familyName = 'familyName',
  position = 'position',
  phoneNumber = 'phoneNumber',
}

const NameAndTAndCDialog: React.FC<Props> = ({ account }) => {
  const { formatMessage } = useIntl()
  const { register, errors, setValue, handleSubmit, setError } = useForm<{
    [FormFields.givenName]: string
    [FormFields.familyName]: string
    [FormFields.position]: string
    [FormFields.phoneNumber]: string
  }>({
    defaultValues: account?.user,
  })
  const { showSnackbar } = useSnackbar()
  const { showErrorNotification } = useErrorNotification()
  const { mutate, isLoading: isSubmitting } = useUpdateResource<Partial<NavbarUser>>()
  const queryClient = useQueryClient()
  const user = account?.user
  const [hasAcceptedTAndC, setHasAcceptedTAndC] = useState(false)
  const { trackEvent } = useAmplitude()

  const handleUserSettingsSubmit = async (values: {
    [key: string]: Partial<NavbarUser>
  }): Promise<void | JSX.Element> => {
    const editedUser = { ...(user || {}), ...values, hasAcceptedTAndC } //the backend needs the whole user object along with the edited values

    mutate(
      { url: endpoints.saveResource(Solutions.Resources, ResourceTypes.User), body: editedUser },
      {
        onSuccess: () => {
          queryClient.invalidateQueries([FetchKey.Account])
          showSnackbar({
            message: formatMessage({ id: 'userSettings.settingsUpdated' }),
            severity: NotificationSeverity.success,
          })
          trackEvent({
            name: AmplitudeTrackingEvents.Onboarding.UserInfo.AddUserInfoAndAcceptTandCSubmitted,
            eventProps: {
              addedPosition: Boolean(editedUser?.position),
              addedPhoneNumber: Boolean(editedUser?.phoneNumber),
            },
          })
        },
        onError: error => {
          showErrorNotification({ requestError: error })
          if (error.isValidationError) {
            error.setFormValidationErrors(setError)
          }
        },
      },
    )
  }

  useEffect(() => {
    trackEvent({
      name: AmplitudeTrackingEvents.Onboarding.UserInfo.AddUserInfoAndAcceptTandCOpened,
    })
  }, [])

  return (
    <Stack component="form" noValidate onSubmit={handleSubmit(handleUserSettingsSubmit)} spacing={3}>
      <TextField
        fullWidth
        inputRef={register({ required: formatMessage({ id: 'form.validation.required' }) })}
        required
        name={FormFields.givenName}
        label={formatMessage({ id: `schemas.user.${FormFields.givenName}` })}
        error={Boolean(errors?.givenName)}
        type="text"
        onClear={(): void => setValue(FormFields.givenName, '')}
        helperText={errors?.givenName?.message}
      />

      <TextField
        fullWidth
        inputRef={register({ required: formatMessage({ id: 'form.validation.required' }) })}
        required
        name={FormFields.familyName}
        label={formatMessage({ id: `schemas.user.${FormFields.familyName}` })}
        error={Boolean(errors?.familyName)}
        type="text"
        onClear={(): void => setValue(FormFields.familyName, '')}
        helperText={errors?.familyName?.message}
      />
      <TextField
        fullWidth
        inputRef={register}
        name={FormFields.position}
        label={
          formatMessage({ id: `schemas.user.${FormFields.position}` }) +
          ' ' +
          `(${formatMessage({ id: 'general.optional' })})`
        }
        error={Boolean(errors?.position)}
        type="text"
        onClear={(): void => setValue(FormFields.position, '')}
        helperText={errors?.position?.message}
      />
      <TextField
        fullWidth
        inputRef={register({
          pattern: {
            value: PHONE_NUMBER_REGEX,
            message: formatMessage({ id: 'notifications.errorInvalidPhoneNumber' }),
          },
        })}
        name={FormFields.phoneNumber}
        label={
          formatMessage({ id: `schemas.user.${FormFields.phoneNumber}` }) +
          ' ' +
          `(${formatMessage({ id: 'general.optional' })})`
        }
        error={Boolean(errors?.phoneNumber)}
        type="text"
        onClear={(): void => setValue(FormFields.phoneNumber, '')}
        helperText={errors?.phoneNumber?.message}
      />
      <Box py={2}>
        <FormControlLabel
          control={
            <Checkbox
              color="primary"
              name="tAndCAccepted"
              checked={hasAcceptedTAndC}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setHasAcceptedTAndC(event.target.checked)
              }}
            />
          }
          label={
            <Typography>
              {formatMessage(
                { id: 'TAndCDialog.checkboxDescription' },
                {
                  br,
                  tAndCLink: (
                    <a rel="noreferrer noopener" href={ExternalLinks.TermsAndConditions} target="_blank">
                      {formatMessage({ id: 'TAndCDialog.tAndC' })}
                    </a>
                  ),
                  privacyPolicyLink: (
                    <a rel="noreferrer noopener" href={ExternalLinks.PrivacyPolicy} target="_blank">
                      {formatMessage({ id: 'TAndCDialog.privacyPolicy' })}
                    </a>
                  ),
                },
              )}
            </Typography>
          }
        />
      </Box>

      <Stack direction="row-reverse">
        <Box>
          <LoadingButton
            fullWidth
            variant="contained"
            disabled={!hasAcceptedTAndC}
            type="submit"
            loading={isSubmitting}
            size="large"
          >
            {formatMessage({ id: 'general.confirm' })}
          </LoadingButton>
        </Box>
      </Stack>
    </Stack>
  )
}
export default NameAndTAndCDialog
