import { CircularProgress, Grid, InputAdornment } from '@mui/material'
import ControlledNumberField from '@app/src/components/Form/ControlledNumberField'
import Select from '@app/src/components/Form/Select/ControlledSelect'
import TextField from '@app/src/components/Ui/TextField'
import React, { useMemo } from 'react'
import { useFormContext } from 'react-hook-form'
import { useIntl } from 'react-intl'
import { useYearsFromCurrentReportingPeriod } from '@app/src/utils'

type ConfigDialogContentProps = {
  isLoading: boolean
}

enum EDCI_CONFIG_QUESTION_KEYS {
  COMPANY_ID = 'companyId',
  FUND_ID = 'fundId',
  GP_OWNERSHIP = 'percentageGpOwnership',
  FUND_OWNERSHIP = 'percentageFundOwnership',
  INVESTMENT_YEAR = 'initialInvestmentYear',
  COMPANY_STRUCTURE = 'companyStructure',
  GROWTH_STAGE = 'growthStage',
}

const ConfigDialogContent: React.FC<ConfigDialogContentProps> = ({ isLoading }) => {
  const { formatMessage } = useIntl()
  const { register, control, setValue } = useFormContext()
  const { years, currentReportingPeriod } = useYearsFromCurrentReportingPeriod()

  const getYearOptionLabel = (year: number) => {
    const isLastYear = year === currentReportingPeriod
    return `${String(year)}${isLastYear ? ` (${formatMessage({ id: `form.createRequest.lastReportingPeriod` })})` : ''}`
  }

  const DATE_YEAR_OPTIONS = useMemo(
    () =>
      years.map(y => ({
        value: y,
        label: getYearOptionLabel(y),
      })),
    [],
  )

  if (isLoading) {
    return <CircularProgress />
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <TextField
          inputRef={register}
          fullWidth
          name={EDCI_CONFIG_QUESTION_KEYS.COMPANY_ID}
          label={formatMessage({ id: 'form.createRequest.edci.companyIdLabel' })}
          variant="outlined"
          type="text"
          onClear={(): void => setValue(EDCI_CONFIG_QUESTION_KEYS.COMPANY_ID, '')}
          required
        />
      </Grid>

      <Grid item xs={12}>
        <TextField
          inputRef={register}
          fullWidth
          name={EDCI_CONFIG_QUESTION_KEYS.FUND_ID}
          label={formatMessage({ id: 'form.createRequest.edci.fundIdLabel' })}
          variant="outlined"
          type="text"
          onClear={(): void => setValue(EDCI_CONFIG_QUESTION_KEYS.FUND_ID, '')}
          required
        />
      </Grid>
      <Grid item xs={12}>
        <ControlledNumberField
          control={control}
          name={EDCI_CONFIG_QUESTION_KEYS.GP_OWNERSHIP}
          required
          label={formatMessage({ id: 'form.createRequest.edci.gpOwnershipLabel' })}
          onClear={(): void => setValue(EDCI_CONFIG_QUESTION_KEYS.GP_OWNERSHIP, '')}
          format={v => {
            const value = Number(v)
            return Number.isInteger(value) ? `${value}` : `${value * 100}`
          }}
          isAllowed={({ floatValue }) => (floatValue ?? 0) >= 0 && (floatValue ?? 0) <= 100}
          InputProps={{
            endAdornment: <InputAdornment position="end">%</InputAdornment>,
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <ControlledNumberField
          control={control}
          name={EDCI_CONFIG_QUESTION_KEYS.FUND_OWNERSHIP}
          required
          label={formatMessage({ id: 'form.createRequest.edci.fundOwnershipLabel' })}
          onClear={(): void => setValue(EDCI_CONFIG_QUESTION_KEYS.FUND_OWNERSHIP, '')}
          format={v => {
            const value = Number(v)
            return Number.isInteger(value) ? `${value}` : `${value * 100}`
          }}
          isAllowed={({ floatValue }) => (floatValue ?? 0) >= 0 && (floatValue ?? 0) <= 100}
          InputProps={{
            endAdornment: <InputAdornment position="end">%</InputAdornment>,
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <Select
          options={DATE_YEAR_OPTIONS}
          fieldLabel={formatMessage({ id: 'form.createRequest.edci.investmentYearLabel' })}
          onInputChange={(e, value) => {
            if (!value) setValue(EDCI_CONFIG_QUESTION_KEYS.INVESTMENT_YEAR, undefined)
          }}
          forceFetch
          required
          enableAutoSelect
          name={EDCI_CONFIG_QUESTION_KEYS.INVESTMENT_YEAR}
          control={control}
          findSelectedValue={(value, option): boolean => Number(value) === option?.value}
        />
      </Grid>
      <Grid item xs={12}>
        <Select
          options={[
            {
              label: formatMessage({ id: 'form.createRequest.edci.private' }),
              value: formatMessage({ id: 'form.createRequest.edci.private' }),
            },
            {
              label: formatMessage({ id: 'form.createRequest.edci.public' }),
              value: formatMessage({ id: 'form.createRequest.edci.public' }),
            },
          ]}
          fieldLabel={formatMessage({ id: 'form.createRequest.edci.companyStructureLabel' })}
          onInputChange={(e, value) => {
            if (!value) setValue(EDCI_CONFIG_QUESTION_KEYS.COMPANY_STRUCTURE, undefined)
          }}
          findSelectedValue={(value, option) => value === option.value}
          required
          enableAutoSelect
          name={EDCI_CONFIG_QUESTION_KEYS.COMPANY_STRUCTURE}
          control={control}
        />
      </Grid>
      <Grid item xs={12}>
        <Select
          options={[
            {
              label: formatMessage({ id: 'form.createRequest.edci.venture' }),
              value: formatMessage({ id: 'form.createRequest.edci.venture' }),
            },
            {
              label: formatMessage({ id: 'form.createRequest.edci.growth' }),
              value: formatMessage({ id: 'form.createRequest.edci.growth' }),
            },
            {
              label: formatMessage({ id: 'form.createRequest.edci.buyout' }),
              value: formatMessage({ id: 'form.createRequest.edci.buyout' }),
            },
          ]}
          fieldLabel={formatMessage({ id: 'form.createRequest.edci.growthStageLabel' })}
          onInputChange={(e, value) => {
            if (!value) setValue(EDCI_CONFIG_QUESTION_KEYS.GROWTH_STAGE, undefined)
          }}
          findSelectedValue={(value, option) => value === option.value}
          required
          enableAutoSelect
          name={EDCI_CONFIG_QUESTION_KEYS.GROWTH_STAGE}
          control={control}
        />
      </Grid>
    </Grid>
  )
}

export default ConfigDialogContent
