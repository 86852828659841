import endpoints from '@app/src/api/endpoints'
import { FetchKey, useFetchCollectionWithPost, useFetchFacets } from '@app/src/api/fetchHooks'
import Permissions, { usePermissions } from '@app/src/auth/permissions'
import { useDrawer } from '@app/src/components/Drawer/DrawerContext'
import DrawerViewExport from '@app/src/components/Drawer/Views/DrawerViewExport'
import HoverDialog from '@app/src/components/HoverDialog'
import Table from '@app/src/components/Table'
import LevelCell from '@app/src/components/Table/Cells/LevelCell'
import { useAmplitude } from '@app/src/context/AmplitudeContext'
import { BaseAssessmentsExportColumnsAccessor } from '@app/src/export-columns/baseAssessments'
import useCurrentProviderTypeName from '@app/src/hooks/currentProviderTypeName'
import { useStringifyQueryFilters } from '@app/src/hooks/queryState'
import useSort from '@app/src/hooks/sorting'
import useAssessmentQuestionnaireLink from '@app/src/hooks/useAssessmentQuestionnaireLink'
import AssessmentOverviewRow, {
  AssessmentOverviewRowProps,
} from '@app/src/pages/ResourceCollection/Collections/BaseAssessment/AssessmentOverviewRow'
import useBaseAssessmentOverviewGraph, {
  AssessmentOverViewGraphDatapoint,
} from '@app/src/pages/ResourceCollection/Collections/BaseAssessment/useBaseAssessmentOverviewGraph'
import BaseAssessmentFilters from '@app/src/pages/ResourceCollection/Filters/BaseAssessmentFilters'
import { FilterGroup, Operators } from '@app/src/pages/ResourceCollection/Filters/useFilters'
import ResourceCollectionScene, { FacetItem } from '@app/src/pages/ResourceCollection/index'
import { ResourceCollectionSceneProps } from '@app/src/pages/ResourceCollection/ResourceCollectionScene'
import Assessment from '@app/src/types/assessment'
import { SortOrder } from '@app/src/types/filter'
import { AssessmentType, InquiryStatus } from '@app/src/types/resourceExplorer'
import { br } from '@app/src/utils/translationMarkup'
import { AmplitudeTrackingEvents, Assessments, ResourceTypes } from '@app/src/wf-constants'
import paths from '@app/src/wf-constants/paths'
import { Box, Button, Paper, Stack, Typography } from '@mui/material'
import ReactEcharts from 'echarts-for-react'
import React, { useMemo } from 'react'
import { useIntl } from 'react-intl'
import { generatePath, Link } from 'react-router-dom'
import AssessmentOverviewHeader from './AssessmentOverviewHeader'
import { BaseAssessmentViews } from './BaseAssessmentScene'
import ResponseRateSummary from './ResponseRateSummary'

type AssessmentOverviewSceneProps = {
  allowedFilters: string[]
  userFilters: FilterGroup[]
} & Pick<ResourceCollectionSceneProps, 'actionButtons' | 'tabs'>

const AssessmentOverviewScene: React.FC<AssessmentOverviewSceneProps> = ({
  allowedFilters,
  userFilters,
  actionButtons,
  ...props
}) => {
  const { formatMessage } = useIntl()
  const { sorting, toggleSorting } = useSort()
  const { stringifyQueryFilters } = useStringifyQueryFilters()

  const periodFilterValue = useMemo(
    () =>
      userFilters
        .find(group => group.name === 'periodName')
        ?.filters.find(filter => filter.value)
        ?.value?.toString(),
    [userFilters],
  )

  const { trackEvent } = useAmplitude()
  const { openDrawer } = useDrawer()

  const graphFacetsFilters = [
    ...userFilters,
    {
      name: 'assessmentTemplate.name',
      filters: [
        {
          operator: Operators.EqualTo,
          value: Assessments.BaselineAssessment,
        },
      ],
    },
  ]

  const {
    facets: [graphFacets = []],
  } = useFetchFacets({
    key: FetchKey.AssessmentFacets,
    endpoint: endpoints.assessmentFacet,
    facetsParam: [{ name: 'totalLevel', isEnum: true }],
    filter: graphFacetsFilters,
  })

  const { assessmentQuestionnaireLink, isLoadingAssessmentQuestionnaireLink } = useAssessmentQuestionnaireLink({
    assessmentType: AssessmentType.BaselineAssessment,
  })

  const {
    facets: [inquiryStatusFacets],
    count: totalInquriesSent,
  } = useFetchFacets({
    key: FetchKey.InquiryStatus,
    endpoint: endpoints.inquiryWithFacets,
    facetsParam: [{ name: 'status', isEnum: true }],
    filter: [
      {
        name: 'questionnaireTemplateId',
        filters: [
          {
            value: assessmentQuestionnaireLink?.questionnaireTemplateId,
            operator: Operators.EqualTo,
          },
        ],
      },
      {
        name: 'periodName',
        filters: [
          {
            value: periodFilterValue,
            operator: Operators.EqualTo,
          },
        ],
      },
      {
        name: 'provider.deletedAt',
        filters: [
          {
            operator: Operators.IsNull,
          },
        ],
      },
    ],
    options: { enabled: Boolean(periodFilterValue) && Boolean(assessmentQuestionnaireLink?.questionnaireTemplateId) },
  })

  const { count: totalresponsesReceived, isLoading } = useFetchFacets({
    key: FetchKey.ResponseFacets,
    endpoint: endpoints.responsesWithFacets,
    facetsParam: [{ name: 'request.requestStatus', isEnum: true }],
    filter: [
      {
        name: 'request.questionnaireTemplateId',
        filters: [
          {
            value: assessmentQuestionnaireLink?.questionnaireTemplateId,
            operator: Operators.EqualTo,
          },
        ],
      },
      {
        name: 'IsLatestSubmitted',
        filters: [
          {
            value: true,
            operator: Operators.EqualTo,
          },
        ],
      },
      {
        name: 'request.periodName',
        filters: [
          {
            value: periodFilterValue,
            operator: Operators.EqualTo,
          },
        ],
      },
      {
        name: 'request.subscriptions.target.deletedAt',
        filters: [
          {
            operator: Operators.IsNull,
          },
        ],
      },
    ],
    options: { enabled: Boolean(periodFilterValue) && Boolean(assessmentQuestionnaireLink?.questionnaireTemplateId) },
  })

  const submittedInquiryCount = inquiryStatusFacets
    ?.filter(status => status.label !== InquiryStatus.Requested)
    ?.reduce((total, current) => total + (current.count ?? 0), 0)

  const sharedResponses = totalresponsesReceived - submittedInquiryCount

  const { items: tableFacets } = useFetchFacets({
    key: FetchKey.AssessmentFacets,
    endpoint: endpoints.assessmentFacet,
    sort: sorting,
    facetsParam: [
      {
        name: 'section1Level',
        isEnum: true,
      },
      {
        name: 'section2Level',
        isEnum: true,
      },
      {
        name: 'section3Level',
        isEnum: true,
      },
      {
        name: 'section4Level',
        isEnum: true,
      },
      {
        name: 'section5Level',
        isEnum: true,
      },
    ],
    filter: userFilters,
  })

  const levels = useMemo<Record<number, FacetItem | undefined>>(
    () => ({
      1: graphFacets.find(l => l.value === 1),
      2: graphFacets.find(l => l.value === 2),
      3: graphFacets.find(l => l.value === 3),
      4: graphFacets.find(l => l.value === 4),
      5: graphFacets.find(l => l.value === 5),
    }),
    [graphFacets],
  )

  const options = useBaseAssessmentOverviewGraph(levels)
  const { hasPermission } = usePermissions()
  const canAccessAssessmentTemplate = hasPermission(Permissions.ASSESSMENT_TEMPLATE_ACCESS)

  const rows: AssessmentOverviewRowProps['row'][] = tableFacets
    .map(facet => ({
      section: facet.propertyName.replace('Level', ''),
      section1Level: facet.items.find(l => l.value === 1)?.count ?? 0,
      section2Level: facet.items.find(l => l.value === 2)?.count ?? 0,
      section3Level: facet.items.find(l => l.value === 3)?.count ?? 0,
      section4Level: facet.items.find(l => l.value === 4)?.count ?? 0,
      section5Level: facet.items.find(l => l.value === 5)?.count ?? 0,
    }))
    .sort((a, b) => {
      if (sorting.target === 'id') return 0
      const target = sorting.target as keyof Omit<AssessmentOverviewRowProps['row'], 'section'>
      switch (sorting.order) {
        case SortOrder.ASCENDING:
          return a[target] - b[target]
        case SortOrder.DESCENDING:
          return b[target] - a[target]
        default:
          return 0
      }
    })

  const [highlightedLevel, setHighlightedLevel] = React.useState<AssessmentOverViewGraphDatapoint>()
  const [offset, setOffset] = React.useState<number>(0)

  const totalSuppliersThatResponded = useMemo(
    () => Object.values(levels)?.reduce((acc, level) => acc + (level?.count ?? 0), 0) ?? 0,
    [levels],
  )

  const basePayload = {
    filter: [
      {
        name: 'assessmentTemplateId',
        filters: [{ value: assessmentQuestionnaireLink?.assessmentTemplateId, operator: Operators.EqualTo }],
      },
    ],
    sort: sorting,
    include: ['provider.categoryOptions', 'provider.country.risks.riskType', 'provider.organization.contacts.user'],
  }

  const filterPayload = {
    filter: [
      {
        name: 'assessmentTemplateId',
        filters: [{ value: assessmentQuestionnaireLink?.assessmentTemplateId, operator: Operators.EqualTo }],
      },
    ],
    sort: sorting,
    include: ['provider.categoryOptions', 'provider.country.risks.riskType', 'provider.organization.contacts.user'],
  }

  const payload = {
    ...basePayload,
    include: [...basePayload.include],
    filter: [...basePayload.filter, ...userFilters],
  }

  const { count } = useFetchCollectionWithPost<Assessment>({
    key: FetchKey.AssessmentCollection,
    endpoint: endpoints.assessmentCollection,
    payload,
    options: { enabled: canAccessAssessmentTemplate && Boolean(assessmentQuestionnaireLink?.assessmentTemplateId) },
  })

  const events = useMemo(
    () => ({
      mouseover: ({
        data,
        event,
      }: {
        data: AssessmentOverViewGraphDatapoint
        event: { target: { shape: { x: number } } }
      }) => {
        setOffset(event.target.shape.x)
        setHighlightedLevel(data)
      },
    }),
    [],
  )
  const providerTypeName = useCurrentProviderTypeName({ isPlural: true, shouldCapitalize: true })
  return (
    <ResourceCollectionScene
      enableScroll
      title={formatMessage({ id: 'navbar.baseAssessment' })}
      filter={<BaseAssessmentFilters allowedFilters={allowedFilters} />}
      actionButtons={[
        ...(actionButtons ?? []),
        {
          label: formatMessage({ id: 'resourceCollections.general.export' }),
          variant: 'outlined',
          onClick: () => {
            trackEvent({
              name: AmplitudeTrackingEvents.Analyze.Assessment.ExportedExcel,
            })

            openDrawer(
              <DrawerViewExport
                resourceType={ResourceTypes.Assessment}
                count={count}
                userFilter={userFilters}
                exportColumns={BaseAssessmentsExportColumnsAccessor}
                rawExportPayload={filterPayload}
              />,
            )
          },
          disabled: isLoading || count === 0,
        },
      ]}
      {...props}
    >
      {(totalInquriesSent > 0 || totalresponsesReceived > 0) && (
        <Box pr={4} mt={3} mb={2}>
          <ResponseRateSummary
            providersRespondedCount={submittedInquiryCount}
            providersWithInquiriesCount={totalInquriesSent}
            questionnaireTemplateIds={
              Array.isArray(assessmentQuestionnaireLink?.questionnaireTemplateId)
                ? assessmentQuestionnaireLink?.questionnaireTemplateId
                : [assessmentQuestionnaireLink?.questionnaireTemplateId]
            }
            sharedResponsesCount={sharedResponses}
            period={periodFilterValue}
            isLoading={isLoading || isLoadingAssessmentQuestionnaireLink}
          />
        </Box>
      )}
      <Stack spacing={1} pr={4} mt={1}>
        <Paper elevation={0}>
          <Stack spacing={2} p={4} pb={5}>
            <Typography variant="overline" color="textSecondary">
              {formatMessage({ id: 'baseAssessment.baselineAssessmentOverview' })}
            </Typography>
            <HoverDialog
              placement="bottom-start"
              positionOffset={[offset, 0]}
              width={300}
              content={
                highlightedLevel && (
                  <Stack spacing={1} alignItems="start">
                    <Typography color="primary" variant="subtitle2">
                      {highlightedLevel.name}
                    </Typography>
                    <Stack direction="row" spacing={4} alignItems="center" justifyContent="space-between">
                      <Typography variant="body2">
                        {formatMessage(
                          { id: 'baseAssessment.percentOfAllSuppliers' },
                          { value: Math.round((highlightedLevel.value / totalSuppliersThatResponded) * 100) },
                        )}
                      </Typography>
                      <Button
                        component={Link}
                        to={stringifyQueryFilters({
                          url: generatePath(paths.baseAssessment, { view: BaseAssessmentViews.Compare }),
                          queryParams: {
                            filters: [
                              {
                                name: 'totalLevel',
                                value: [highlightedLevel.level],
                                operator: Operators.In,
                              },
                            ],
                          },
                          includeCurrentFilters: true,
                        })}
                      >
                        {formatMessage({ id: 'baseAssessment.seeDetails' })}
                      </Button>
                    </Stack>
                  </Stack>
                )
              }
            >
              <Box width="100%" py={1}>
                <ReactEcharts option={options} style={{ height: 56 }} onEvents={events} />
              </Box>
            </HoverDialog>
            <Stack direction="row" justifyContent="space-between" mt={2}>
              {Array.from({ length: 5 }).map((_, i) => (
                <Stack key={i}>
                  <LevelCell value={i + 1} disabled={!levels[i + 1]?.count} disableCell />
                  <Typography
                    variant="kpi"
                    mt={2}
                    color={!levels[i + 1]?.count ? 'text.disabled' : 'textPrimary'}
                    gutterBottom
                  >
                    {levels[i + 1]?.count ?? 0}
                  </Typography>
                  <Typography variant="body1" color="textSecondary">
                    {formatMessage(
                      { id: `baseAssessment.levelsDescription.level${i + 1}` },
                      {
                        providerType: providerTypeName,
                        br: br,
                      },
                    )}
                  </Typography>
                </Stack>
              ))}
            </Stack>
          </Stack>
        </Paper>
        <Paper elevation={0}>
          <Stack spacing={2} p={4} pb={5}>
            <Typography variant="overline" color="textSecondary">
              {formatMessage({ id: 'baseAssessment.assessmentBySection' })}
            </Typography>
            <Table<AssessmentOverviewRowProps['row']>
              noPagination
              data={rows}
              count={rows.length}
              isLoading={false}
              isError={false}
              HeaderComponent={() => <AssessmentOverviewHeader toggleSorting={toggleSorting} activeSorting={sorting} />}
              RowComponent={AssessmentOverviewRow}
              page={1}
              pageSize={rows.length}
            />
          </Stack>
        </Paper>
      </Stack>
    </ResourceCollectionScene>
  )
}

export default AssessmentOverviewScene
