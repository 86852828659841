import endpoints from '@app/src/api/endpoints'
import { FetchKey, useFetchResource } from '@app/src/api/fetchHooks'
import ActionButtons, { ActionButtonsProps } from '@app/src/components/ActionButtons'
import SimpleBreadcrumbs from '@app/src/components/SimpleBreadcrumbs'
import { useGetApiQueryFilters } from '@app/src/hooks/queryFilters'
import AssessmentInsightsCharts from '@app/src/pages/Assessments/AssessmentInsight/AssessmentInsightsCharts'
import ResourceCollectionScene from '@app/src/pages/ResourceCollection'
import StandardAssessmentFilters from '@app/src/pages/ResourceCollection/Filters/StandardAssessmentFilters'
import { ResourceCollectionSceneProps } from '@app/src/pages/ResourceCollection/ResourceCollectionScene'
import { AssessmentTemplateWithClassificationRules } from '@app/src/types/resourceExplorer'
import paths from '@app/src/wf-constants/paths'
import { BarChartOutlined, ListAltOutlined } from '@mui/icons-material'
import { Box, Button, ButtonGroup, Stack } from '@mui/material'
import React, { useEffect, useMemo } from 'react'
import { Helmet } from 'react-helmet'
import { useIntl } from 'react-intl'
import { generatePath } from 'react-router'
import AssessmentInsightsDetails from './AssessmentInsightsDetails'

type AssessmentInsightsSceneProps = {
  assessmentTemplateId: string
  activePeriod?: string
  tabs?: ResourceCollectionSceneProps['tabs']
}

const staleTime = 60000

const allowedFilters: string[] = [
  'provider.id',
  'provider.name',
  'provider.country.id',
  'provider.createdAt',
  'provider.ownerUserId',
  'provider.websiteAddress',
  'provider.vatNumber',
  'provider.registrationNumber',
  'provider.linkStatus',
  'provider.categoryOptions.id',
  'provider.activityStatus',
  'provider.finalRiskRating',
  'provider.priority',
  'provider.providerApprovalStatus',
  'provider.supplierUsage',
  'provider.tier',
  'provider.organization.id',
  'provider.mappingNodes.actorTypeModel.id',
  'provider.mappingNodes.tier',
  'periodName',
  'totalLevel',
]

export enum InsightsSubTabs {
  CHARTS,
  DETAILS,
}

const AssessmentInsightsScene: React.FC<AssessmentInsightsSceneProps> = ({
  assessmentTemplateId,
  activePeriod,
  tabs,
}) => {
  const userFilters = useGetApiQueryFilters(allowedFilters)
  const [activeTab, setActiveTab] = React.useState<InsightsSubTabs>(InsightsSubTabs.DETAILS)
  const [buttonRow, setButtonRow] = React.useState<ActionButtonsProps['buttons']>()

  const { formatMessage } = useIntl()
  const periodFilterValue = useMemo(() => {
    const periodFromFilters = userFilters
      .find(group => group.name === 'periodName')
      ?.filters.find(filter => filter.value)
      ?.value?.toString()

    return periodFromFilters || activePeriod
  }, [userFilters, activePeriod])

  const handlePeriodChange = (newPeriod: string) => {
    activePeriod = newPeriod
  }

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [assessmentTemplateId])

  const { data: assessmentTemplateWithClassificationRules } =
    useFetchResource<AssessmentTemplateWithClassificationRules>({
      key: [FetchKey.AssessmentTemplateWithClassificationRules, assessmentTemplateId],
      endpoint: endpoints.assessmentTemplateWithClassificationRules(assessmentTemplateId),
      options: {
        staleTime: staleTime,
      },
    })

  const assessmentTemplate = assessmentTemplateWithClassificationRules?.assessmentTemplate

  const questions = useMemo(
    () => assessmentTemplateWithClassificationRules?.questions?.map(question => question) ?? [],
    [assessmentTemplateWithClassificationRules],
  )

  if (!assessmentTemplate) return null

  return (
    <>
      <Helmet>
        <title>{formatMessage({ id: 'resourceTypes.assessments' })}</title>
      </Helmet>
      <Stack px={4} pt={2}>
        <SimpleBreadcrumbs
          crumbs={[
            {
              label: formatMessage({
                id: 'resourceTypes.assessments',
              }),
              to: generatePath(paths.assessments),
            },
            {
              label: assessmentTemplate.name,
            },
          ]}
        />
      </Stack>
      <ResourceCollectionScene
        enableScroll
        title={assessmentTemplate.name}
        filter={
          <StandardAssessmentFilters
            allowedFilters={allowedFilters}
            assessmentTemplate={assessmentTemplate}
            selectedPeriod={periodFilterValue}
            onPeriodChange={handlePeriodChange}
          />
        }
        disableLeftPadding
        tabs={tabs}
      >
        <Box display="flex" py={3} px={4} flexDirection="row">
          {activeTab === InsightsSubTabs.DETAILS && buttonRow && (
            <Stack direction="row" gap={1}>
              <ActionButtons sizes="small" variants="outlined" buttons={buttonRow} />
            </Stack>
          )}
          <Stack alignItems="center" direction="row" ml="auto">
            <ButtonGroup>
              <Box>
                <Button
                  startIcon={<BarChartOutlined />}
                  onClick={() => setActiveTab(InsightsSubTabs.CHARTS)}
                  variant={activeTab === InsightsSubTabs.CHARTS ? 'contained' : 'outlined'}
                >
                  {formatMessage({ id: 'assessments.insights.charts' })}
                </Button>
              </Box>
              <Button
                startIcon={<ListAltOutlined />}
                onClick={() => setActiveTab(InsightsSubTabs.DETAILS)}
                variant={activeTab === InsightsSubTabs.DETAILS ? 'contained' : 'outlined'}
              >
                {formatMessage({ id: 'general.details' })}
              </Button>
            </ButtonGroup>
          </Stack>
        </Box>
        {activeTab === InsightsSubTabs.CHARTS && (
          <AssessmentInsightsCharts
            assessmentTemplate={assessmentTemplate}
            periodName={periodFilterValue}
            userFilters={userFilters}
            questions={questions}
          />
        )}
        {activeTab === InsightsSubTabs.DETAILS && (
          <AssessmentInsightsDetails
            assessmentTemplateId={assessmentTemplateId}
            periodName={periodFilterValue}
            userFilters={userFilters}
            questions={questions}
            onButtonRowChange={setButtonRow}
          />
        )}
      </ResourceCollectionScene>
    </>
  )
}

export default AssessmentInsightsScene
