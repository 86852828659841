import endpoints from '@app/src/api/endpoints'
import { FetchKey, useFileDownload } from '@app/src/api/fetchHooks'
import { useCreateResource } from '@app/src/api/updateHooks'
import { useDrawer } from '@app/src/components/Drawer/DrawerContext'
import FileUploadField from '@app/src/components/FileUploadField'
import { useSnackbar } from '@app/src/context/SnackbarContext'
import RequestError from '@app/src/errors/RequestError'
import useErrorNotification from '@app/src/hooks/errorNotification'
import { useGetApiQueryFilters } from '@app/src/hooks/queryFilters'
import { NotificationSeverity } from '@app/src/wf-constants'
import { DownloadOutlined } from '@mui/icons-material'
import { Button, Stack, Typography } from '@mui/material'
import React, { useState } from 'react'
import { useIntl } from 'react-intl'
import { useQueryClient } from 'react-query'
import DrawerView, { DrawerViewProps } from '../DrawerView'

type DrawerViewImportSpendProps = {
  solution?: string
  providerType: string
  allowedFilters: string[]
  onImportSuccess?: () => void
} & Omit<DrawerViewProps, 'title'>

const DrawerViewImportSpend: React.FC<DrawerViewImportSpendProps> = ({
  solution,
  providerType,
  allowedFilters,
  onImportSuccess,
  ...props
}) => {
  const { formatMessage } = useIntl()
  const userFilters = useGetApiQueryFilters(allowedFilters)
  const { handleFileDownloadWithFilters } = useFileDownload()
  const queryClient = useQueryClient()
  const [importFile, setImportFile] = useState<string | Blob>('')
  const { mutate: uploadImportFile, isLoading } = useCreateResource<FormData>()
  const { showSnackbar } = useSnackbar()
  const { showErrorNotification } = useErrorNotification()
  const { closeDrawer } = useDrawer()

  const handleFileDownload = async () => {
    await handleFileDownloadWithFilters(endpoints.providerSpendHistoryImportFileDownload, {
      filter: userFilters,
      include: [],
    })
  }

  const handleImport = async (): Promise<void> => {
    const data = new FormData()
    data.append('file', importFile)

    uploadImportFile(
      { url: endpoints.providerSpendHistoryImport, body: data },
      {
        onSuccess: () => {
          showSnackbar({
            message: formatMessage({ id: 'schemas.notification.importSpend' }, { providerType }),
            severity: NotificationSeverity.info,
          })

          queryClient.invalidateQueries(FetchKey.ProviderCollection)
          onImportSuccess?.()
          closeDrawer()
        },
        onError: e => {
          showErrorNotification({ requestError: e as RequestError })
        },
      },
    )
  }

  return (
    <DrawerView
      buttons={[
        {
          label: formatMessage({ id: 'import.import' }),
          variant: 'contained',
          onClick: handleImport,
          disabled: !importFile,
          loading: isLoading,
        },
      ]}
      title={formatMessage({ id: 'import.importSpend' })}
      {...props}
    >
      <Stack p={2} spacing={4}>
        <Stack spacing={2}>
          <Typography variant="subtitle1">{formatMessage({ id: 'import.importSpendStepOne' })}</Typography>
          <Typography variant="body1" color="textSecondary">
            {formatMessage({ id: 'import.stepOneDescription' })}
          </Typography>
          <Button
            disabled={isLoading}
            fullWidth
            variant="contained"
            startIcon={<DownloadOutlined />}
            onClick={handleFileDownload}
          >
            {formatMessage({ id: 'import.templateDownload' })}
          </Button>
        </Stack>
        <Stack spacing={2}>
          <Typography variant="subtitle1">{formatMessage({ id: 'import.importSpendStepTwo' })}</Typography>
          <Typography variant="body1" color="textSecondary">
            {formatMessage({ id: 'import.importSpendStepTwoDescription' })}
          </Typography>
        </Stack>
        <Stack spacing={2}>
          <Typography variant="subtitle1">{formatMessage({ id: 'import.importSpendStepThree' })}</Typography>
          <Typography variant="body1" color="textSecondary">
            {formatMessage({ id: 'import.importSpendStepThreeDescription' })}
          </Typography>
          <FileUploadField setFile={setImportFile} fullWidth />
        </Stack>
        <Stack spacing={2}>
          <Typography variant="subtitle1">{formatMessage({ id: 'import.importSpendStepFour' })}</Typography>
          <Typography variant="body1" color="textSecondary">
            {formatMessage({ id: 'import.importSpendStepFourDescription' })}
          </Typography>
        </Stack>
      </Stack>
    </DrawerView>
  )
}

export default DrawerViewImportSpend
